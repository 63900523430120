
// REACT STANDARD LIBRARY CALLS
import React from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';


/* IMPORT LIBRARY  */
import { EXPLODE, COUNT } from '../../../Library/Library.js';





export default function IMAGE_TEXT(props){
        
        
    /**/
    const image_links = props.image_links;
    const image_description = props.image_description;
    //const lesson_count = props.lesson_count;
    const module_owner_name = props.module_owner_name;
    const module_owner_icon = props.module_owner_icon;
        
        
    const style0 = { marginRight: "3%" }
        
    const CARD_IMAGES = () => {
        /**/
        const images = EXPLODE("#@#", image_links);
        /**/
        const all_images_count = COUNT(images);
        
        /* create our controller loop to be used to decide whick kind of dropdown should be loaded.  */
        if(all_images_count === 1){
            return (
                <div className="d-block">
                    <a className="w-100 d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]}>
                        <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]} alt="..." width="600" height="370" className="card-img-top rounded" />
                    </a>
                    <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                        <span className="badge badge-pill badge-light"><span className="h5">Click to enlarge</span></span>
                    </div>
                </div>
            );
        }else if(all_images_count === 2){
            return (
                <div className="form-row">
                    <div className="col-6">
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]} alt="..." height="230" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">1</span></span>
                            </div>
                        </a>
                    </div>
                    <div className="col-6">
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]} alt="..." height="230" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">2</span></span>
                            </div>
                        </a>
                    </div>
                </div>
            );
        }else if(all_images_count === 3){
            return (
                <div className="form-row">
                    <div className="col-6 mt-11">
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]} alt="..." height="200" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">1</span></span>
                            </div>
                        </a>
                    </div>
                    <div className="col-6">
                        <a className="d-block mb-2 lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">2</span></span>
                            </div>
                        </a>
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[2]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[2]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">3</span></span>
                            </div>
                        </a>
                    </div>
                </div>
            );
        }else if(all_images_count === 4){
            return (
                <div className="form-row">
                    <div className="col-6">
                        <a className="d-block mb-2 lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[0]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">1</span></span>
                            </div>
                        </a>
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[2]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[2]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">3</span></span>
                            </div>
                        </a>
                    </div>
                    <div className="col-6">
                        <a className="d-block mb-2 lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[1]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">2</span></span>
                            </div>
                        </a>
                        <a className="d-block lift lift-lg" data-fancybox="" data-width="900" data-height="600" href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[3]}>
                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+images[3]} alt="..." height="183" className="card-img-top rounded" />
                            <div className="position-relative text-right mt-n8 mr-n0 mb-6" style={style0}>
                                <span className="badge badge-pill badge-light"><span className="h5">4</span></span>
                            </div>
                        </a>
                    </div>
                </div>
            );
        }
    }
        
        
        
        
        
        
        
    /* A FUNCTION TO HELP US ALTERNATE THROUGH THE POSSIBLE CONDITIONS OF THE DESCRIPTION LAYER OF THIS COMPONENT. */
    const DESCRIPTION = (description) => {
        /* Here we quickly check if the content to be displayed has description information attached to it or not. */
        if(description){
            /* If the control gets in here it mean that the content to be displayed has description information attached to it, Therefore we create the description layer. */
            return(
                <div className="card-body"><p className="card-text">{description}</p></div>
            );
        }else{
            /* If the control gets in here it mean that the content to be displayed has no description information attached to it, Therefore we do otherchecks to affirm if the screen
             * requesting for this contents is a mobile screen or desktop. This is so we can better serve view.  */
            if(window.innerWidth < 480){
                /* Gontrol geting in here mean that the screen requesting is a mobile phone screeen. */
                return(     <div></div>    );
            }else{
                /* Gontrol geting in here mean that the screen requesting is a desktop screeen. */
                return(     <div className="card-body"></div>    );
            }
        }
    }
        
        
        
        
    
    // on-page css hacks
    const style1 = { textDecoration: "none", color: "#161C2D" };
    
    return (

        <div className="d-block shadow-lg lift lift-lg mb-8" style={style1}>
            <div className="img-fluid rounded">
                <div className="card mb-7 mb-md-0">
                    {CARD_IMAGES()}
                    
                    {DESCRIPTION(image_description)}
                    
                    <div className="card-meta">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2"><img src={configuration.RESOURCE+"OUTCAST/RESOURCE/uploads/"+module_owner_icon} alt="..." 
                        className="avatar-img rounded-circle" /></div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">{module_owner_name}</h6>
                    </div>
                </div>
            </div>
        </div>
        
    );

}