
// Preventing Component from Rendering 
// In rare cases you might want a component to hide itself even though it was rendered by another component. To do this return null instead of its render output.
// In the example below, the <WarningBanner /> is rendered depending on the value of the prop called warn. If the value of the prop is false, then the component does not render:
/*
function WarningBanner(props) {
  if (!props.warn) {    return null;  }
  return (
    <div className="warning">
      Warning!
    </div>
  );
}
*/







/* JAVASCRIPT FUNCTION TO HELP REMOVE US REMOVE AND DESTROY ALL ARRAY ELEMENTS, AS A MEANS OF EMPTYING AN ARRAY.  */
export function CAPITALIZE(DATA){
    return DATA.toUpperCase();
};




/* JAVASCRIPT FUNCTION TO HELP REMOVE US REMOVE AND DESTROY ALL ARRAY ELEMENTS, AS A MEANS OF EMPTYING AN ARRAY.  */
export function ARRAY_DUMP(ARR){
    ARR.splice(0, ARR.length);
};





/* JAVASCRIPT FUNCTION TO HELP REMOVE WHITE SPACES FROM THE TWO SIDES OF A STRING.  */
export function TRIM(STR){
    return STR.trim();
};




/* JAVASCRIPT FUNCTION TO HELP US GET THE TOTAL NUMBER OF ELEMENTS INSIDE AN ARRAY.  */
export function COUNT(ARRAY_DATA){
    return ARRAY_DATA.length;
};





/* JAVASCRIPT FUNCTION TO HELP US CONVERT AN OBJECT TO ARRAY. EXAMPLE OF OBJECT DATA => var obj = {"1":5,"2":7,"3":0,"4":0,"5":0,"6":0,"7":0,"8":0,"9":0,"10":0,"11":0,"12":0} 
 * THE FIRST PARAMETER IS THE OBJECT DATA TYPE WHILE THE SECOND PARAMETER IS THE CONTROLLER, IF IT IS DOPED WITH "BOTH" THEN THIS FUNCTION WILL RETURN THE ARRAY EQUIVALENT IN THIS
 * FORM => "[key, value]", BUT WHEN DOPED WITH "VALUES", THEN THIS FUNCTION WILL RETURN ONLY THE VALUES OF THE RESULTANT ARRAY.   */
export function OBJECT_TO_ARRAY(OBJECT, RETURN_STYLE = "VALUES"){
    /* check the functions controller to see what kind of operation user wants from this function.   */
    if(RETURN_STYLE === "VALUES"){
        return Object.values(OBJECT);
    }else if(RETURN_STYLE === "BOTH"){
        return Object.entries(OBJECT);
    }
};





/* JAVASCRIPT FUNCTION TO HELP REMOVE A STRING FROM INSIDE ANOTHER STRING.  */
export function STRING_REPLACE(STRING_TO_LOOK_OUT_FOR, REPLACEMENT, MAIN_STRING){
    return MAIN_STRING.replace(STRING_TO_LOOK_OUT_FOR, REPLACEMENT);
};





/* JAVASCRIPT EQUIVALENT OF PHP EXPLODE FUNCTION.  */
export function EXPLODE(DELIMITER, STR){
    return STR.split(DELIMITER);
};




/* JAVASCRIPT FUNCTION TO HELP US GET THE LAST DATA AT THE END OF A URL.  */
export function LINK_LAST_DATA(LINK, DELIMITER){
    /* explode into an array the url using the given delimiter. */
    const data_box = EXPLODE(DELIMITER, LINK);
    /* guess the index of the last data inside our array, and then use that guessed index to purge out the last data and returns it. `*/
    return data_box[(COUNT(data_box) - 1)];
};




/* FUNCTION TO HELP US TRUNCATE A STRING.  */
export function TRUNCATE_STRING(str, num, end_characters = '...') {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + end_characters;
}





/* JAVASCRIPT FUNCTION TO HELP US VALIDATE IF AN EMAIL IS IN IT CORRECT FORMATE. */
export function validateEmail(email){
    // eslint-disable-next-line
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};



/* JAVASCRIPT FUNCTION TO HELP US COUNT THE NUMBER OF WORDS IN A SENTENCE. */
export function countWords(s){
    s = s.replace(/\n/g,' '); // newlines to space
    s = s.replace(/(^\s*)|(\s*$)/gi,''); // remove spaces from start + end
    s = s.replace(/[ ]{2,}/gi,' '); // 2 or more spaces to 1
    return s.split(' ').length; 
};



/* JAVASCRIPT FUNCTION TO HELP NOTIFY IF A SENTENCE CONTAINS ONLY ALPHABETS. THIS FUNCTION RETURNS TRUE IF IT CONTAINS ONLY ALPHABETS BUT RETURNS FALSE OTHERWISE.  */
export function onlyAlphabets(e){
    try {
        var charCode = null;
        if (window.event) {
            charCode = window.event.keyCode;
        }
        else if (e) {
            charCode = e.which;
        }
        else { return true; }
        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 32){
            return true;
        }else{
            return false;
        }
    }
    catch (err) {
        alert(err.Description);
    }
};







/* 
    const menu_style_pad = {  width: "auto", height: "auto", padding: ".325rem .5rem", color: "#666", backgroundColor: "hsla(0,0%,100%,.98)", lineHeight: "1.625rem", 
        visibility: "visible", opacity: "1", boxShadow: "0 0 0 1px rgba(20,20,31,.05),0 2px 7px 1px rgba(20,20,31,.16)", borderTopRightRadius: ".25rem", 
        borderBottomRightRadius: ".25rem"  };
*/

/* REDIRECTS WITH A FULL PAGE REFRESH. TAKES IN A PARAMETER, WHICH IS THE ROUTE THE FUNCTION WILL BE REDIRECTING TO, YOY CAN EITHER USE AN ABSOLUTE LINK OR RELATIVE IN IN HERE.  */
export function REDIRECT(ROUT){
    window.location.href = ROUT;
};





/* FULLY DELETES A SPECIFIED COOKIE. TAKES IN A PARAMETER, WHICH IS THE NAME OF THE COOKIE TO BE DELETED. A COOKIE IS DELETED BY SETTING IT EXPIRY TO AN OLD DATE.  */
export function Delete_cookie(cookie_name){
    document.cookie = cookie_name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
};





/* CREATES A COOKIE. THE "cname" PARAMETER IS THE NAME OF THE COOKIE TO BE CREATED, WHILE THE "cvalue" PARAMETER IS THE DATA TO BE ASSIGNED INTO THIS NEWLY CREATED COOKIE. ALSO 
 * THE "exdays" PARAMETER IS THE NUMBER OF DAYS BEFORE THE COOKIE EXPIRES. .*/
export function setCookie(cname, cvalue, exdays){
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
};




/* FUNCTION TO GET A SPECIFIED COOKIE. TAKES IN A PARAMETER, WHICH IS THE NAME OF THE COOKIE ITS DATA NEEDS TO BE FETCHED.  */
export function getCookie(cname){
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1);
        if (c.indexOf(name) !== -1) return c.substring(name.length,c.length);
    }
    return "";
};




export function LOGIN_STATUS_CHECKER(COOKIE_NAME){
    var session_cookie = getCookie(COOKIE_NAME);
    if(session_cookie)
        return true;
    else
        return false;  
};









/*


function FETCH(URL) {
    
    return fetch('https://api.example.com/user', {
      mode: 'cors',
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ firstName: 'Fred', lastName: 'Flintstone' }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-XSRF-TOKEN': getCookieValue('XSRF-TOKEN')
      }
    }).then(response => {
      return response.json().then(data => {
        if (response.ok) {
          return data;
        } else {
          return Promise.reject({status: response.status, data});
        }
      });
    });
    
}



function axios_FETCH(details) {
  return axios.post('https://api.example.com/user', details);
}


*/