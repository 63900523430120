

/* IMPORT LIBRARY  */
import { validateEmail } from './Library.js';






/* JAVASCRIPT FUNCTION TO HELP US AFFIRM IF USER HAVE NOT SELECTED THE DEFAULT DATA FROM A DROPDOWN LIST.  */
export function DROPDOWN_SELECTION_CHECK(DATA, DEFAULT_TEXT){   
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    /* we check if this form fields default value is what was selected. added "default" because course registration page takes course code-data from url or takes "default" text, 
     * if no course is selected before entering the course registration page, therefore in order to keep tab with null value selection we have to add "default" to this clause.  */
    if((DATA.SELECTED_COURSES === DEFAULT_TEXT) || (DATA.SELECTED_COURSES === "default") || (DATA.SELECTED_COURSES === "")){
        /* if control gets here it means user did not select anything from this forms dropdown. */
        return "You have not selected any course. Please select a course from the course dropdown.";
    }else{
        return true;
    }
};






/* JAVASCRIPT FUNCTION TO HELP US VALIDATE USER DATA, AND TO ENSURE ALL REQUIREMENT CONCERNING HOW DATAS SHOULD BE ARE MET.  */
export function REGISTRATION_DATA_AUTHENTICATOR(DATA){
    
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    if(DATA.FIRST_NAME.length < 2){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "Your first name can't be less than 2 characters.";
    }
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    else if(DATA.LAST_NAME.length < 2){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "Your last name can't be less than 2 characters.";
    }
    /* we check if this data field is in the right formate or not. */
    else if(!validateEmail(DATA.EMAIL)){
        /* if control gets here it means the email entered has failed to meet the proper standards. */
        return "Please ensure that a proper email address is entered in the email text field.";
    }
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    else if(DATA.PHONE.length < 7){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "Your phone number should be greater than 6 characters, and your input should be numbers only.";
    }
    /* we check if this form fields default value is what was selected. */
    else if((DATA.SEX === "Gender") || (DATA.SEX === "")){
        /* if control gets here it means user did not select anything from this forms dropdown. */
        return "Please select your gender from the gender dropdown.";
    }
    /* we check if this form fields default value is what was selected. */
    else if((DATA.AGE_RANGE === "Age-range") || (DATA.AGE_RANGE === "")){
        /* if control gets here it means user did not select anything from this forms dropdown. */
        return "Please select from the age-range dropdown list.";
    }
    /* we check if this form fields default value is what was selected. added "default" because registration page takes course code-data from url or takes "default" text, if no 
     * course is selected before entering the registration page, therefore in order to keep tab with null value selection we have to add "default" to this clause.  */
    else if((DATA.SELECTED_COURSES === "Select course") || (DATA.SELECTED_COURSES === "default") || (DATA.SELECTED_COURSES === "")){
        /* if control gets here it means user did not select anything from this forms dropdown. */
        return "You have not selected any course. Please select a course from the course dropdown.";
    }
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    else if(DATA.PASSWORD.length < 6){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "The password fields shouldn't be less than 6 characters.";
    }
    /* we check if the normal allowed length of characters for this form field is not defaulted. */
    else if(DATA.RETYPE_PASSWORD.length < 6){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "The re-type password fields shouldn't be less than 6 characters.";
    }
    /* we check if the two password fields are thesame. */
    else if(DATA.PASSWORD !== DATA.RETYPE_PASSWORD){
        /* if control gets here it means the data entered has suppased the normal length of characters allowed for this field. */
        return "Error the two password fields are not thesame.";
    }
    /* this is the default operation that is if none of the clauses of this construct interupts, then this function would give it seal of "furher operations" approval. */
    else{
        /* Now we affirm if the supplied phone number is entirely a number. */
        if(!isNaN(DATA.PHONE - parseFloat(DATA.PHONE))){
            /* clear the part way for other functions to work, by returning true indicating data's supplied by user are authentic and the rules are foolowed. */
            return true;
        }else{
            /* if control gets here it means the data entered has a mix of other characters. */
            return "Error, please ensure you entered a correct phone number, your input should be numbers only.";
        }
    }
};