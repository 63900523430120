
// Load our video.js css library.
//import '../../src/Css/Third_party_css/video-js.min.css';


// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
//import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import FOOTER from './WEB_PARTS/FOOTER';



// IMPORT COMPONENTS
import WELCOME_VIDEO_MODAL from '../Components/DASHBOARD/WELCOME_VIDEO_MODAL';



// IMPORT DASHBOARD COMPONENTS
import SIDE_BAR from '../Components/DASHBOARD/SIDE_BAR';
import SNOW_BALL_CONTAINER from '../Components/DASHBOARD/SNOW_BALL_CONTAINER';
import SNOW_BALL_CRASH_CONTAINER from '../Components/DASHBOARD/SNOW_BALL_CRASH_CONTAINER';
import CERTIFICATE_CONTAINER from '../Components/DASHBOARD/CERTIFICATE_CONTAINER';



/* IMPORT LIBRARY  */
import { getCookie } from '../Library/Library.js';





/* SINGLE PAGE APPLICATION USER DASHBOARD, THESE COMPONENT IS THE MAIN HOUSE FOR EVERYTHING USER WOULD NEED TO DO ON THEIR WEBINAR DASHBOARD. THIS IS THE PARENT CONTAINER TO EVERY
 * OTHER CONTAINERS THAT WILL BE CALLED TO ACTION FROM INSIDE HERE.  */
export default function Dashboard() {
    
    /* lets set the page title. */
    document.getElementById("title").innerHTML = "Dashboard | "+configuration.COMPANY_NAME; 
    
    
    
    
    
    /*     D I S P L A Y   C O N T R O L L E R    S T A T E S    F O R    D A S H B O A R D    R I G H T    P A N E L    C O N T A I N E R S    O R   C O M P O N E N T S      */
    /* state to be used to control the display of the CRASH WEBINAR CONTAINER OR COMPONENT. */
    const [CRASH_CONTROLLER, setCRASH_CONTROLLER] = useState(false);
    /* state to be used to control the display of the NORMAL WEBINAR CONTAINER OR COMPONENT. */
    const [NORMAL_LECTURES_CONTROLLER, setNORMAL_LECTURES_CONTROLLER] = useState(true);
    /* state to be used to control the display of the CERTIFICATE VIEW CONTAINER OR COMPONENT. */
    const [CERTIFICATION_CONTROLLER, setCERTIFICATION_CONTROLLER] = useState(false);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    /*     A L E R T    M O D A L    S T A T E S       */
    /* create state to be used to control the pop up display of the alert modal window from parent component. */
    const [showAlert, setAlertShowStatus] = useState(false);
    /* create state to be used to control if the modal window should redirect after its close button is clicked or not. */
    const [allow_redirect, setAllow_redirect] = useState("false");
    /* create function to be used to collect "window close request" from the child modal component, through the "closer" props of the child modal component.  */
    const alertCloserFunction = (alert_CloseData) => {       setAlertShowStatus(alert_CloseData);         }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    

    
    
    /*      C E R T I F I C A T E    C O M P O N E N T     S T A T E S        */
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store "course name" and "certificate id" which also known as the "record id"
     * from the record table inside the database, these state would be used to store these datas for every course that has been completed by user. This state data would be useful in 
     * the "CERTIFICATE_CONTAINER" component. */
    const [CERTIFICATION_DATAS, setCERTIFICATION_DATAS] = useState([]);
    /* create function to be used to collect user completed course "certificate" related datas from the child modal component, through the "certificate_info_exporter" props of the 
     * child component.  */
    const certificate_imported_datas = (data) => {       setCERTIFICATION_DATAS(data);         }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    
    
    /*                           G E N E R A L     S T A T E S      F O R      A L L     D A S H B O A R D       C H I L D        C O M P O N E N T S                              */
    /* Here we create an empty string state, right here in the DASHBOARD COMPONENT which would be used to store the name of the currently logged in user. This state data would be 
     * useful for both the "side bar" and "welcome modal" components.  */
    const [SIDEBAR_DATAS, setSIDEBAR_DATAS] = useState("");
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    
    
    
    
    /////////  W E B I N A R     C O N T A I N E R     S T A T E S     A N D      E X P O R T E R      F U N C T I O N S      A N D       L O A D E R      F U N C T I O N    /////////
    
    /*                                        M A I N      S N O W B A L L     W E B I N A R    C O M P O N E N T     S T A T E S                                                   */
    /* Here we create an empty string state, right here in the DASHBOARD COMPONENT which would be used to store (CONCATIVELY) the name and icon link data of the owner of the currently
     * viewed module and course right inside the "NORMAL WEBINAR" component. This state data would be useful in the "main snow ball container" components. */
    const [ACTING_MODULE_OWNERSHIP_DATAS, setACTING_MODULE_OWNERSHIP_DATAS] = useState("");
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store the "course name" AND "course code" of every active course user is 
     * currently undergoing on the platform. This state data would be useful in the "main snow ball container" components.   */
    const [COURSE_SWITCHER_DATAS, setCOURSE_SWITCHER_DATAS] = useState([]);
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store the "course CONTENTS" of the scheduled lectures for that date which 
     * would be displayed inside the "main snow ball container" components. This state data would be useful in the "main snow ball container" components.   */
    const [COURSE_CONTENT, setCOURSE_CONTENT] = useState([]);
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store SOME BASIC INFORMATION SUCH AS CURRENTLY VIEWING COURSE CODE AND COURSE 
     * NAME, NEXT AND CURRENT LECTURE DATE, PAUSE INDICATORS ETC... This state data would be useful in the "main snow ball container" components.   */
    const [SNOW_WEBINAR_INFO, setSNOW_WEBINAR_INFO] = useState([]);
    /* Here we create an empty string state, right here in the DASHBOARD COMPONENT which would be used to store the "interractive session data" for the currently viewed course which 
     * would be displayed inside the "main snow ball container" info message panel. This state data would be useful in the "main snow ball container" components.   */
    const [INTERRACTIVE_SESSION_STATE, setINTERRACTIVE_SESSION_STATE] = useState("");
    /* Here we create a (FILLED WITH DEFAULT VALUES) array state, right here in the DASHBOARD COMPONENT which would be used to store MODULE CODE, CURRENT DISPLAYED LECTURE DAY NUMBERE
     * END BACKWARDING AND END FORWARDING DATAS, all of this information are very vital in the main snowball webinar when it comes to the point were user would need to back date
     * lectures or jump back to latest lectures. This state data would be useful in the "main snow ball container" components.    */
    const [REVERSE_LECTURES_SESSIONS, setREVERSE_LECTURES_SESSIONS] = useState({module:false, day_no:false, backwards_stopper:false, forwards_stopper:false});
    
    /*          M A I N      S N O W B A L L      W E B I N A R     C O M P O N E N T     C H I L D      C O M P O N E N T S      E X P O R T E R       F U N C T I O N S             */
    /* create function to be used to collect the new contents from the child component (SNOW_BALL_CONTAINER), through the "switched_content" props of the child component.  */
    const switchedContentCollector_2 = (new_course_contents) => {       setCOURSE_CONTENT(new_course_contents);         }
    /* create function to be used to collect webinar info from the child component (SNOW_BALL_CONTAINER), through the "snowballWEBINAR_info_messanger" props of the child component.  */
    const snowballWEBINAR_infoCollector_2 = (info) => {       setSNOW_WEBINAR_INFO(info);         }
    /* create function to be used to collect previouse REVERSE SESSION DATA from the child component (SNOW_BALL_CONTAINER), through the "reverserSessionRegistra" props of the 
     * child component.  */
    const reverserSessionRegistra_2 = (session_data) => {       setREVERSE_LECTURES_SESSIONS(session_data);         }
    /* create function to be used to collect interractive session link datas from the child component (SNOW_BALL_CONTAINER), through the "interractive_session_info_exporter" props of 
     * the child component.  */
    const interractive_session_data_carrier_2 = (session_data) => {       setINTERRACTIVE_SESSION_STATE(session_data);         }
    /* create function to be used to collect New switcher data from the child component (SNOW_BALL_CONTAINER), through the "switcher_data_Collector" props of the child component.  */
    const switcher_data_Collector_2 = (switcher_new_data) => {       setCOURSE_SWITCHER_DATAS(switcher_new_data);         }
    /* create function to be used by the pause button inside the (SNOW_BALL_CONTAINER) component, this function would be called right from inside the pause button component any time
     * a paused course is played, so that a specific information (which is the "pause_indicator") inside the "SNOW_WEBINAR_INFO" state array can be change any time a paused course
     * is played agian.  */
    const pauseActionSender_2 = (new_data) => {
        /* dynamically update the "pause_indicator" section of the array inside the "SNOW_WEBINAR_INFO" state with a new "pause_indicator" status. */
        SNOW_WEBINAR_INFO.pause_indicator = new_data;
        /* repackage the "SNOW_WEBINAR_INFO" state datas inside a new variable container.  */
        let current_info = SNOW_WEBINAR_INFO;
        /* update the whole "SNOW_WEBINAR_INFO" state so that it can reflect the new "pause_indicator" status. */
        setSNOW_WEBINAR_INFO(current_info);         
    }
    
    /*     M A I N      S N O W B A L L      W E B I N A R     C O M P O N E N T     F I R S T       T I M E       L O A D       F U N C T I O N S        */
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION. This function
     * was created to work around all the states that are useful to the (SNOW_BALL_CONTAINER) component.  */
    const LOADER_DATA = () => {
        /* collate data (which is the course code of the selected course) to be send to the server through API. NOTE this API is a multifunctional one therefore its only necessary
         * that we use the "SENDER" object to tell our API that the (SNOW_BALL_CONTAINER) component is the one requesting for dashboard full datas.  */
        const data = { TOKEN: getCookie("member_hash"), SENDER: "LIVE_COURSE" }
        /* call the api. */
        const url = configuration.DASHBOARD_LOADER_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
             * array at this point. Now we harnessing the response data received from the server, we extract the name of the currently logged in user and we use the "setSIDEBAR_DATAS" 
             * state function to set this data inside the "SIDEBAR_DATAS" state.  */
            setSIDEBAR_DATAS(data.name);
            /* Here we extract fom the response data, the currently viewing course and module, module-owner full name and icon image link and then we use the 
             * "setACTING_MODULE_OWNERSHIP_DATAS" state function to set this data inside the "ACTING_MODULE_OWNERSHIP_DATAS" state.  */
            setACTING_MODULE_OWNERSHIP_DATAS(data.owner_name_and_icon);
            /* Here we extract fom the response data, all the active course names and their course codes, and then we use the "setCOURSE_SWITCHER_DATAS" state function to set this 
             * data inside the "COURSE_SWITCHER_DATAS" state.  */
            setCOURSE_SWITCHER_DATAS(data.courses);
            /* Here we extract fom the response data, the currently viewing course, todays-lecture-contents and then we use the "setCOURSE_CONTENT" state function to set this data 
             * inside the "COURSE_CONTENT" state.  */
            setCOURSE_CONTENT(data.contents);
            /* Here we extract fom the response data, the currently viewing course, COURSE CODE AND COURSE NAME, NEXT AND CURRENT LECTURE DATE, PAUSE INDICATORS ETC. and then we use 
             * the "setSNOW_WEBINAR_INFO" state function to set this data inside the "SNOW_WEBINAR_INFO" state.  */
            setSNOW_WEBINAR_INFO(data.current_and_next_lecture_date);
            /* Here we extract fom the response data, the currently viewing course interractive session informations here.  */
            setINTERRACTIVE_SESSION_STATE(data.interactive_session_info);
            //////////////////////////////////////////E X T E R N A L   C O M P O N E N T   S T A T E   E D I T I N G///////////////////////////////////////////////////////////
            /* Here we extract fom the response data, an update of all the completed course, course name and certificate id, and then we use the "setCERTIFICATION_DATAS" state 
             * function to set this data inside the "CERTIFICATION_DATAS" state. ONLY IN THIS POINT DO WE HAVE TO EDIT A STATE OUTSIDE THE SCOPE OF THE STATES THAT THIS ONLOAD 
             * FUNCTION IS SUPPOSE TO EDIT. */
            setCERTIFICATION_DATAS(data.completed);
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            /* Here we extract fom the response data, first_time_login indicator, if this is positive, then we run the operations inside this clause, else we just skip every
             * operation inside this clause.  */
            if(data.first_time_login === "FALSE"){
                /* Control getting into this clause signifies that user is logging into this dashboard for the first time, therefore we load the welcome and know-to-use-dashboard 
                 * video modal. */
                WELCOME_VIDEO_MODAL_TRIGGER(true);
            }
        });
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. AS WE KNOW THAT MOST OF THE STATES EFFECTED BY THE "LOADER_DATA" FUNCTION BELONGS TO THE (SNOW_BALL_CONTAINER) COMPONENT EXCEPT FOR ONLY ONE, THEREFORE
     * ONCE THIS DASHBOARD IS LOADED FOR THE FIRST TIME IT LOADS MAIN DATAS FOR THE (SNOW_BALL_CONTAINER) COMPONENT PLEASE NOTE. ALSO THIS "useEffect" FUNCTION HAS AN EMPTY ARRAY AS 
     * IT, MEANING THAT APART FROM THE FIRST TIME THIS DASHBOARD COMPONENT WILL BE LOADING, THERE WONT BE ANOTHER LOADING OF THE "LOADER_DATA" FUNCTION, FROM WITHIN THIS "useEffect" 
     * FUNCTION AGAIN.  */
    useEffect(LOADER_DATA, []);
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    
    
    
    
    
    
    //////////  C R A S H     C O N T A I N E R     S T A T E S     A N D      E X P O R T E R      F U N C T I O N S      A N D       L O A D E R      F U N C T I O N    ////////////
    
    /*                                        C R A S H      S N O W B A L L     W E B I N A R    C O M P O N E N T     S T A T E S                                                 */
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store the "CRASH COURSE CONTENTS" of ALL lectures for the viewing course 
     * which would be displayed inside the "crash snowball container" components. This state data would be useful in the "crash snowball container" components. NOTE THAT this state
     * would remain empty and wont hold data on the first time loading of the "DASHBOARD COMPONENT" until user clicks the "ARCHIVE" BUTTON ON THE SIDEBAR TO VIEW CRASH OR COMPLETED
     * COURSES.   */
    const [CRASH_CONTENT, setCRASH_CONTENT] = useState([]);
    /* Here we create an empty string state, right here in the DASHBOARD COMPONENT which should have been used to store (CONCATIVELY) the name and icon link data of the owner of modules
     * of viewing course to be shown in the crash container, but since this is logically imposible, that is we cant store all the name and icon links of all modules to be shown for a
     * selected course on the crash container, here and still extract them from this state through children components that would require loads of work, so as long as crash container is
     * concerned we used other means to achieve this task, but all thesame its important to create this state here as a make weight so we dont have errors. This state data would be 
     * useful in the "crash snowball container" components. */
    const [CRASH_ACTING_MODULE_OWNERSHIP_DATAS, setCRASH_ACTING_MODULE_OWNERSHIP_DATAS] = useState("");
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store the "course name" AND "course code" of every crash or completed courses. 
     * This state data would be useful in the "crash snowball container" components.   */
    const [CRASH_COURSE_SWITCHER_DATAS, setCRASH_COURSE_SWITCHER_DATAS] = useState([]);
    /* Here we create an empty array state, right here in the DASHBOARD COMPONENT which would be used to store SOME BASIC INFORMATION SUCH AS CURRENTLY VIEWING COURSE CODE AND COURSE 
     * NAME, NEXT AND CURRENT LECTURE DATE, PAUSE INDICATORS ETC... This state data would be useful in the "crash snowball container" components.   */
    const [CRASH_SNOW_WEBINAR_INFO, setCRASH_SNOW_WEBINAR_INFO] = useState([]);
    /* Here we create a state right inside this "DASHBOARD COMPONENT" and doped with the boolean "true" default data, the main purpose of this state is so that we can use it to control
     * the component loading system of the crash container, that is to moderate the API calling in the crash container, ensuring its only called once, and that once would not be the
     * first time loading of the "DASHBOARD" container, but first time loading of the crash component, therefore setting it to boolean "true" by default tells the crash componet loader
     * to load its data fetching API. */
    const [CRASH_CONTENT_LOADER_REPEAT_CONTROLLER, setCRASH_CONTENT_LOADER_REPEAT_CONTROLLER] = useState(true);
    
    /*          C R A S H      S N O W B A L L      W E B I N A R     C O M P O N E N T     C H I L D      C O M P O N E N T S      E X P O R T E R       F U N C T I O N S           */
    /* create function to be used to collect the new contents from the child component (SNOW_BALL_CRASH_CONTAINER), through the "switched_content" props of the child component.  */
    const CRASH_switchedContentCollector_2 = (new_course_contents) => {       setCRASH_CONTENT(new_course_contents);         }
    /* create function to be used to collect webinar info data from the child component (SNOW_BALL_CRASH_CONTAINER), through the "snowballWEBINAR_info" props of the child component. */
    const CRASH_snowballWEBINAR_infoCollector_2 = (info) => {       setCRASH_SNOW_WEBINAR_INFO(info);         }
    /* create function to be used to collect New switcher data from the child component (SNOW_BALL_CRASH_CONTAINER), through the "switcher_data_Collector" props of the child component.  */
    const CRASH_switcher_data_Collector_2 = (switcher_new_data) => {       setCRASH_COURSE_SWITCHER_DATAS(switcher_new_data);         }
    
    /*                 C R A S H      S N O W B A L L      W E B I N A R     C O M P O N E N T     F I R S T       T I M E       L O A D       F U N C T I O N S                      */
    /* PLEASE NOTE THAT THIS FUNCTION IS WHAT WOULD BE CALLED TO ACTION WHEN USER CLICKS THE "ACHIVE" BUTTON ON THE SIDEBAR, MEANING WHEN USER CLICKS THIS BUTTON USER HAS ACTIVATED 
     * THIS FUNCTION. SINCE THE DASHBOARD COMPONT AND ALL OF ITS ASSOCIATE COMPONENTS ARE ALL A "SINGLE PAGE APPLICATION", WE HAVE DESIGNED THIS IN SUCH A WAY THAT WHEN YSER CLICKS 
     * TO ACTIVATE A COMPONENT OTHER CORRESPONDING COMPONENTS SHOULD DISAPPEAR SO USER CAN BE ABBLE TO INTERRACT WITH THE COMPONENT THEY WANT TO INTERRACT WITHA T A PARTICULAR TIME. 
     * UPON THE ACTIVATION OF TJHIS FUNCTION FOR THE FIRST TIME IT WILL CALL AN API TO HELP FETCH ALL DATAS NEEDED TO DISPLAY CRASH COURSES AND THEN SAVE THESE DATAS INSIDE STATES
     * IN THIS DASHBOARD CONTAINER, UPON THE SECOND TIME ACTIVATION OF THIS FUNCTION IT WOULD NOT LOAD ANY DATA, INSTEAD IT WOULD JUST ENSURE THE CRASH CONTAINER IS VISIBLE WHILE 
     * OTHER OPPOSING CONTAINERS ARE INVISIBLE, THIS IS SO THAT WE DONT BORE SERVER WITH UNNECESSARY OPERATIONS, WE JUST USE THE LAST LOADED DATA INSTEAD. ALSO NOTE THAT THIS FUNCTION
     * IS ONLY CALLED OR ACTIVATED FROM INSIDE THE (SIDE_BAR) COMPONENT NOT (SNOW_BALL_CRASH_CONTAINER) COMPONENT.   */
    const CRASH = () => {
        /* When this function is called from the side bar component to help show crash course contents, we firstly run a test on the "CRASH_CONTENT_LOADER_REPEAT_CONTROLLER" state to 
         * tell us if this function has been called before or not. if this function has been called before it only indicate that all datas required to successfully display crash course
         * contents has been loaded and stored in all corresponding states inside this "dashboard" component perculiar to the crash course container, but if this function have not 
         * been loaded before then we have to load these datas.  */
        if(CRASH_CONTENT_LOADER_REPEAT_CONTROLLER === true){
            /* Control in here signifies that this function is being loaded for the first time, therefore we begin the process pinging our API for crash course related datas. We 
             * collate data (which is the course code of the selected course) to be send to the server through API. NOTE this API is a multifunctional one therefore its only necessary
             * that we use the "SENDER" object to tell our API that the (SNOW_BALL_CRASH_CONTAINER) component is the one we would be needing this datas for. */
            const data = { TOKEN: getCookie("member_hash"), SENDER: "CRASH" }
            /* call the api. */
            const url = configuration.DASHBOARD_LOADER_API;
            /* setting the api property to using the "FORM_POST" method, and also set the headers. */
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => response.json()).then((data) => {
                /* Right in here, we are certain to have a result, so we start first by running the "ENABLE_CRASH_WEBINA" function which sole purpose is to ensure that all other 
                 * (SNOW_BALL_CRASH_CONTAINER) like components stays off while only the (SNOW_BALL_CRASH_CONTAINER) stays on, in other words we show the crash webinar container ONLY. */
                ENABLE_CRASH_WEBINA();
                /* Also we tell our "crash container data-loading-controller state" not to allow anymore calling of this API for this same reason again, in other words we set our
                 * "CRASH_CONTENT_LOADER_REPEAT_CONTROLLER" state to "false" which will ensure that when next this function is called control wont come down here again hence ensuring
                 * that this API only get called once. */
                setCRASH_CONTENT_LOADER_REPEAT_CONTROLLER(false);
                /* Here we extract fom the response data, the content-datas of the currently viewing crash course and then we use the "setCRASH_CONTENT" state function to set this 
                 * data inside the "CRASH_CONTENT" state.  */
                setCRASH_CONTENT(data.contents);
                /* this is just a place holder. check documentation on the state declaration for "CRASH_ACTING_MODULE_OWNERSHIP_DATAS" to understand more.  */
                setCRASH_ACTING_MODULE_OWNERSHIP_DATAS(data.owner_name_and_icon);
                /* Here we extract fom the response data, all the crash course names and their course codes, and then we use the "setCRASH_COURSE_SWITCHER_DATAS" state function to 
                 * set this data inside the "CRASH_COURSE_SWITCHER_DATAS" state.  */
                setCRASH_COURSE_SWITCHER_DATAS(data.courses);
                /* Here we extract fom the response data, extracting only the currently viewing crash course, course name and course code and then we use the 
                 * "setCRASH_SNOW_WEBINAR_INFO" state function to set this data inside the "CRASH_SNOW_WEBINAR_INFO" state. check documentation on the state declaration for 
                 * "CRASH_SNOW_WEBINAR_INFO" to understand more.  */
                setCRASH_SNOW_WEBINAR_INFO(data.current_and_next_lecture_date);
            });
        }else{
            /* control here mean user is trying to see crash menu the second time so we just show first loaded data and not fetch new one. we run the "ENABLE_CRASH_WEBINA" function 
             * which sole purpose is to ensure that all other (SNOW_BALL_CRASH_CONTAINER) like components stays off while only the (SNOW_BALL_CRASH_CONTAINER) stays on, in other 
             * words we show the crash webinar container ONLY. */
            ENABLE_CRASH_WEBINA();
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    
    
    /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON CLICKING ITS CLOSE
     * BUTTON OR NOT. THIS WELCOME_VIDEO_MODAL WAS CREATED FROM OUR NORMAL ALERT MESSAGE MODAL ONLY THAT SOME FEW THINGS WAS CHANGED. THIS MODAL WILL BE USED TO TRIGGER A WELCOME
     * VIDEO AND "KNOW HOW TO USE DASHBOARD" VIDEO UPON FIRST TIME LOGIN TO THIS PLATFORM. THE FIRST PARAMETER IS USED TO CARRY "WINDOW CLOSE" COMMAND AWAY FROM THIS MODAL 
     * COMPONENT INTO ITS PARENT COMPONENT (DASHBOARD) STATE, WHICH THEM CLOSES THE MODAL WINDOW, WHILE THE SECOND PARAMETER WHICH IS SETTED BY DEFAULT TO FALSE IS SUPPOSE TO
     * HANDLE REDIRECT UPON MODAL WINDOW CLOSING, IS DISABLED FROM DOING THAT.  */
    const WELCOME_VIDEO_MODAL_TRIGGER = (MODAL_TOGGLER, REDIRECT_DEFAULT = "false") => {
        /* Here we use the "alertShowStatus" state, SET function to collect the toggle data, which can either be "true" or "false" from the parameter and update the "alertShowStatus"
         * state, which inturns update our modals components pop up controller through its "welcome_video_modal_toggler" props. check the "WELCOME_VIDEO_MODAL" component to understand more. */
        setAlertShowStatus(MODAL_TOGGLER);
        /* Here we use the "allow_redirect" state, SET function to collect the redirect signal data from the parameter and update the "allow_redirect" state, which inturns update 
         * our modals "redirect controller" through its "redirect_request" props. check the "ALERT_MODAL" component to understand more. */
        setAllow_redirect(REDIRECT_DEFAULT);
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    //////////////////////    D A S H B O A R D     S I N G L E     P A G E    A P P L I C A T I O N       V I S I B I L I T Y      F U N C T I O N S     ///////////////////////////
    /* HERE WE HAVE A VISIBILITY FUNCTION CALLED "ENABLE_NORMAL_WEBINA", WHOSE SOLE PURPOSE IS TO TURN OFF EVERY OTHER COMPONENT CREATED TO BE DISPLAYED ON THE RIGHT SIDE OF THE 
     * DASHBOARD COMPONENT IN FAVOUR OF TURNING ON ONLY ITSELF, WHICH IS IN THE PERSON OF THE MAIN LECTURES SNOWBALL WEBINAR.  */
    const ENABLE_NORMAL_WEBINA = () => {
        setNORMAL_LECTURES_CONTROLLER(true);
        setCERTIFICATION_CONTROLLER(false);
        setCRASH_CONTROLLER(false);
    }
    /* HERE WE HAVE A VISIBILITY FUNCTION CALLED "ENABLE_CRASH_WEBINA", WHOSE SOLE PURPOSE IS TO TURN OFF EVERY OTHER COMPONENT CREATED TO BE DISPLAYED ON THE RIGHT SIDE OF THE 
     * DASHBOARD COMPONENT IN FAVOUR OF TURNING ON ONLY ITSELF, WHICH IS IN THE PERSON OF THE CRASH SNOWBALL WEBINAR FOR SHOWING CONTENTS OF CRASH OR COMPLETED COURSES.  */
    const ENABLE_CRASH_WEBINA = () => {
        setCRASH_CONTROLLER(true);
        setNORMAL_LECTURES_CONTROLLER(false);
        setCERTIFICATION_CONTROLLER(false);
    }
    /* HERE WE HAVE A VISIBILITY FUNCTION CALLED "ENABLE_CERTIFICATION_CONTAINER", WHOSE SOLE PURPOSE IS TO TURN OFF EVERY OTHER COMPONENT CREATED TO BE DISPLAYED ON THE RIGHT SIDE 
     * OF THE DASHBOARD COMPONENT IN FAVOUR OF TURNING ON ONLY ITSELF, WHICH IS IN THE PERSON OF THE CERTIFICATE DOWNLOAD COMPONENT.  */
    const ENABLE_CERTIFICATION_CONTAINER = () => {
        setCERTIFICATION_CONTROLLER(true);
        setCRASH_CONTROLLER(false);
        setNORMAL_LECTURES_CONTROLLER(false);
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    
    
    
    
    return (
        
        <div>       

            <WELCOME_VIDEO_MODAL user_fullName={SIDEBAR_DATAS} welcome_video_modal_toggler={showAlert} welcome_video_modal_closer={alertCloserFunction} redirect_request={allow_redirect} />

            <NAVIGATION_BAR />
            
            
                <div className="container">
                    <div className="row justify-content-between">
                        
                        <SIDE_BAR component_datas={SIDEBAR_DATAS} crash_action={CRASH} webinar_action={ENABLE_NORMAL_WEBINA} enable_certs_container={ENABLE_CERTIFICATION_CONTAINER} />
                        
                        <SNOW_BALL_CONTAINER switcher_datas={COURSE_SWITCHER_DATAS} first_content={COURSE_CONTENT} switched_content={switchedContentCollector_2} 
                        disclaimer={SNOW_WEBINAR_INFO} snowballWEBINAR_info={snowballWEBINAR_infoCollector_2} pauseActionSender={pauseActionSender_2} 
                        previousContentSender={switchedContentCollector_2} reverserSessionRegistra={reverserSessionRegistra_2} reverserSessionData={REVERSE_LECTURES_SESSIONS}
                        switcher_data_Collector={switcher_data_Collector_2} ACTING_MODULE_OWNERSHIP_DATAS={ACTING_MODULE_OWNERSHIP_DATAS}
                        controller={NORMAL_LECTURES_CONTROLLER} certificate_info_exporter={certificate_imported_datas} interractive_session_link={INTERRACTIVE_SESSION_STATE} 
                        interractive_session_info_exporter={interractive_session_data_carrier_2} />
                                
                        <SNOW_BALL_CRASH_CONTAINER switcher_datas={CRASH_COURSE_SWITCHER_DATAS} first_content={CRASH_CONTENT} switched_content={CRASH_switchedContentCollector_2} 
                        disclaimer={CRASH_SNOW_WEBINAR_INFO} snowballWEBINAR_info={CRASH_snowballWEBINAR_infoCollector_2} switcher_data_Collector={CRASH_switcher_data_Collector_2} 
                        ACTING_MODULE_OWNERSHIP_DATAS={CRASH_ACTING_MODULE_OWNERSHIP_DATAS} controller={CRASH_CONTROLLER} />
                                
                        <CERTIFICATE_CONTAINER content={CERTIFICATION_DATAS} controller={CERTIFICATION_CONTROLLER} />
                        
                    </div>
                </div>
            

            <FOOTER />
        
        </div>

    );

}