
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import FOOTER from './WEB_PARTS/FOOTER';








export default function Consultancy() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "IT Services | "+configuration.COMPANY_NAME;
    

    // how to do on script css
    const style1 = { marginTop: "2%" };
    const style2 = { paddingBottom: "2%" };
    const style3 = { marginTop: "0" };
    const style4 = { marginRight: "2%" };
    
    return (
        
        <div>        

            <NAVIGATION_BAR />
            
            
                <section className="pt-8 pt-md-11" style={style1}>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                              <div className="row">
                                <div className="col-6 mr-n5">
                                    <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-23.jpg"} alt="..." 
                                    className="img-fluid mb-4 rounded shadow" data-aos="fade-right" data-aos-delay="100" />
                                    <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-25.jpg"} alt="..." 
                                    className="img-fluid rounded shadow" data-aos="fade-right" data-aos-delay="150" />
                                </div>
                                <div className="col-6 mt-8">
                                    <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-22.jpg"} alt="..." 
                                    className="img-fluid mb-4 rounded shadow" data-aos="fade-right" />
                                    <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-24.jpg"} alt="..." 
                                    className="img-fluid rounded shadow"  data-aos="fade-right" data-aos-delay="50" />
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-5" data-aos="fade-left">
                            <h2 className="font-weight-bold">IT Service Management and Consulting <span className="text-primary">(ITSM)</span></h2>
                                <p className="font-size-lg text-muted mb-4">
                                    With {configuration.EXPERIENCE} years in IT Service Management, {configuration.COMPANY_NAME} offers a wide range of 
                                    <span className="text-primary"> ITSM</span> consulting and implementation services for businesses of any size and industry. We help 
                                    companies tune their IT processes in line with their business’ wants and needs. We provide IT Infrastructure Library 
                                    <span className="text-primary"> (ITIL)</span> consulting services, focusing on aligning IT services with the needs of your business. 
                                </p>
                                <Link data-toggle="smooth-scroll" to="#details" className="btn btn-primary-soft shadow" style={style4}>How we work</Link>
                                <Link to={configuration.CONTACT} className="btn btn-primary shadow lift">Get in touch</Link>
                            </div>
                        </div>
                    </div>
                </section>
                
                
                
                
                
                
                <section className="pt-8 pt-md-11 pb-8 pb-md-14" id="details">
                    <div className="container">
                        <div className="row"><div className="col-12"><hr className="my-6 my-md-8" /></div></div>

                        <div className="row">
                            <div className="col-12 col-md-8">
                                <h2 className="mb-5 font-weight-bold">HOW OUR <span className="text-primary">ITSM</span> CONSULTING WORKS</h2>
                                <p className="text-gray-800">
                                    There are 4 major <span className="text-primary">ITSM</span> consulting models at {configuration.COMPANY_NAME}: one-time consultation, 
                                    platform selection, service implementation and ongoing improvements.
                                </p>
                                <div className="d-flex">
                                    <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-check"></i></span>
                                    <p className="text-gray-800">
                                        <span className="text-primary font-weight-bold">One-time consultation:</span> You let us understand your IT service management related 
                                        problems and we provide you with a corresponding, strong and scalable solution.
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-check"></i></span>
                                    <p className="text-gray-800">
                                    <span className="text-primary font-weight-bold">Platform selection:</span> Once you agree to introduce IT Infrastructure Library practices 
                                    and IT service management approach into your company’s processes, we’ll help you determine the right platform to achieve your setted goals with.
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-check"></i></span>
                                    <p className="text-gray-800" style={style2}>
                                        <p><span className="text-primary font-weight-bold">Service implementation:</span> Starting from the point of clarifying your requirements 
                                        to the point we assess a number of platforms and identify the product that will best meet your needs and requirements, our process of 
                                        Service implementation is as sectioned below; </p>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Firstly, we clarify your requirements/needs and aims</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">We jointly spell out your requirment just to make sure we are on the same page</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">We Implement all your functionality</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Finally, we support your system</p>
                                        </div>
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-check"></i></span>
                                    <p className="text-gray-800" style={style2}>
                                        <p><span className="text-primary font-weight-bold">Ongoing improvements:</span> The fine-tuning of IT services is a continuous process, 
                                        which is why it’s common to work in cycles just to identify and eliminate weak spots in the system. Adopting this four techniques, 
                                        we can constantly help you improve your IT services:</p>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Identifying weak spots in the system</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Sketching up and implementing a solution</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Staying connected, incase any problem comes up</p>
                                        </div>
                                        <div className="d-flex" style={style3}>
                                        <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4"><i className="fe fe-arrow-right ml-2"></i></span>
                                            <p className="text-gray-800">Comparing results with the actual expectation</p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-4">
                                <div className="card shadow-light-lg shadow lift">
                                    <div className="card-body">
                                        <h4>Connect with us</h4>
                                        <p className="font-size-sm text-gray-800 mb-5">
                                            Not sure exactly what you need or just want clarification? We’d be happy to chat with you and clear things up for you. Anytime!
                                        </p>
                                        <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">Call anytime</h6>
                                        <p className="font-size-sm mb-5"><a href={"tel:"+configuration.HOTLINE} className="text-reset">{configuration.HOTLINE}</a></p>
                                        <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">Email us</h6>
                                        <p className="font-size-sm mb-0">
                                            <a href={"mailto:"+configuration.COMPANY_EMAIL} className="text-reset">{configuration.COMPANY_EMAIL}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            

            <FOOTER />
        
        </div>

    );

}