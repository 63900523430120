
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { Link } from "react-router-dom";



/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';
// IMPORT COMPONENTS
import COURSES_CARD from '../../Components/COURSES_CARD';






export default function COURSES(props){
        
    // Here we declare the "props" that would be used by the parent function topopulate this component with data each time its called.
    const card_data_prop = props.card_data_prop;
    // Here we declare the "props" that we shall be using to pass data to alter the state of the parent page
    const card_data_setter_prop = props.card_data_setter_prop;
        
        
    /* states used to control the form inputes in this page. */
    const [COURSE_NAME, setCOURSE_NAME] = useState("");
    
    /* onchange functions called anytime the form states needs to be altered. */
    const COURSE_NAME_onChange = (e) => {       setCOURSE_NAME(e.target.value);       };
    
    
    
    
    
    
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        
        /* collate data to be send to the server through API.  */
        const data = { course_names: COURSE_NAME, calling_page: "HOME" }
        /* call the api function responsible for handling the filtering job. */
        const url = configuration.HOMEPAGE_COURSE_SEARCH_API;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "DATAS" state variable using its set method.  */
            card_data_setter_prop(data);
        })
    }
    

    
    
    
    // on-page css hacks
    const style1 = { marginTop: "1%" };
    const style2 = { marginBottom: "3%" };
    const style3 = { textDecoration: "none" };
    
    return (

        <section id="select_course" className="pt-7 pt-md-10" style={style1}>
            <div className="container">

                <div className="row" style={style2}>
                    <div className="col-12">
                        <form onSubmit={onSubmit} className="rounded shadow">
                            <div className="input-group input-group-lg">

                                <div className="input-group-prepend">
                                    <span className="input-group-text border-0 pr-1">
                                      <i className="fe fe-search"></i>
                                    </span>
                                </div>

                                <input onChange={COURSE_NAME_onChange} type="text" className="form-control border-0 px-1" aria-label="Search our blog..." 
                                placeholder="What do you want to learn?"></input>

                                <div className="input-group-append">
                                    <span className="input-group-text border-0 py-0 pl-1 pr-3">
                                        <span className="h6 text-uppercase text-muted d-none d-md-block mb-0 mr-5">
                                            <Link to={configuration.COURSES} style={style3}>
                                                <span className="mx-auto">View all</span> <i className="fe fe-arrow-right"></i>
                                            </Link>
                                        </span>
                                        <button type="submit" className="btn btn-sm btn-primary-soft">Search</button>
                                    </span>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>


                <div className="row">
                    {card_data_prop.map((infos, index) => {
                        return (
                            <COURSES_CARD card_datas={infos} key={index.toString()} />
                        )
                    })} 
                </div>

            </div>
        </section>
                
                
    );

}