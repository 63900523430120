
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';








export default function CONSULTING(){
    
    
    const style1 = { paddingTop: "0", marginTop: "0", marginBottom: "8%" };
    
    return (

        <section className="" style={style1}>
            <div className="container">
                <div className="row align-items-center justify-content-center justify-content-md-between">
                    <div className="col-10 col-sm-8 col-md-6 order-md-2">
                        <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                            <div className="device device-iphonex" data-aos="fade-left">
                                <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/screenshots/mobile/home.jpg"} className="device-screen" alt="..." />
                                <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/devices/iphonex.svg"} className="img-fluid" alt="..." />
                            </div>
                            <div className="device device-iphonex" data-aos="fade-left" data-aos-delay="150">
                                <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/screenshots/mobile/rental.jpg"} className="device-screen" alt="..." />
                                <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/devices/iphonex.svg"} className="img-fluid" alt="..." />
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-12 col-md-6 col-lg-5" data-aos="fade-right">
                        <h1 className="font-weight-bold">
                            Let us <span className="text-primary">Manage your project.</span> Consult with us for your <span className="text-primary">Next IT Project.</span>
                        </h1>
                        <p className="font-size-lg text-muted mb-6">
                            The Smart-tech Team have the capacity to execute your goals and deliver your project at a specified time, be it a Mobile or Web App 
                            we deliver with a touch of class. Consult us on how best to use information technology to achieve your business objectives.
                        </p>
                        <div className="mb-6 mb-md-8 mb-md-0 order-md-1">
                            <div className="form-row">
                                <Link to={configuration.CONSULTANCY} className="btn btn-primary">Learn more<i className="fe fe-arrow-right ml-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
        
    );

}