
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";

/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';






export default function CERTIFICATE_CONTAINER({content, controller}){
     
    // using this css part to manipute visibillity of this container
    let style1 = { display: "none" };
    if(controller === true){        style1 = { display: "block" };       }

    return (
        <div className="col-12 col-md-8" style={style1}>
            <section className="pb-8 pt-md-12 pb-md-10">
                <EMBED content={content} />
            </section>
        </div>
    );

}










    /* component to display completed courses and embed an anchor link for download of document  */
    const WIDGET = ({course_name, certificate_id, component_list_indexer}) => {
        return(
            <div key={component_list_indexer.toString()} className="list-group-item">
                <div className="d-flex align-items-center text-reset text-decoration-none" data-toggle="collapse" href="#helpOne" role="button" aria-expanded="true" 
                aria-controls="helpOne">
                    <span className="mr-4"><a href={configuration.RESOURCE+"index.php/download/certificate/"+certificate_id} target='_blank' rel="noopener noreferrer">
                    Click to download certificate for <i>"{course_name}"</i></a></span>
                </div>
            </div>
        );
    };
    
    
    
    /* component to display message to indicate that user hasn't finished any course  */
    const WIDGET_2 = () => {
        return(
            <div className="d-block shadow-lg lift mb-1">
                <div className="col-12-flex">
                    <div className="card alert alert-light shadow-light-lg">
                        <div className="card-body my-auto">
                        <p className="mb-0 font-weight-bold text-mute">You do not have a completed course, therefore no certificate just yet.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };




    /* HERE WE HAVE THE MAIN CONTAINER THAT DECIDES WHAT APPEARS ON THE SCREEN WHEN USER IS EITHER QUALIFIED FOR THE CERTICATE AND WHEN USER IS NOT QUALIFIED YET.   */
    const EMBED = ({content}) => {
        /* here we test this content array, to try to see if user actually has a completed course*/
        if(content.length !== 0){
            return(
                <div className="card shadow-light-lg accordion mb-5 mb-md-6" id="helpAccordionOne">
                    <div className="list-group">

                        <div className="list-group-item">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto">
                                  <h4 className="font-weight-bold mb-0">Download certificate of Completion</h4>
                                  <p className="font-size-sm text-muted mb-0">Issues related to failed download should be reported <Link to={configuration.CONTACT}>here</Link></p>
                                </div>
                                <span className="badge badge-pill badge-success-soft ml-4"><span className="h6 text-uppercase">{content.length} Certificates</span></span>
                            </div>
                        </div>

                        {content.map((data, index) => {
                            return (
                                <WIDGET course_name={data.course_name} certificate_id={data.certificate_id} component_list_indexer={index} />
                            );
                        })}
                        
                    </div>
                </div>
            );
        }else{
            return (
                <WIDGET_2 />
            );
        }
    };