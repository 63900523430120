
// import react
import React from 'react';
//import { Route, Switch } from 'react-router-dom';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'
// how to import image and custom css in react.
//import logo from './logo.svg';
//import './App.css';






/* WEB APP CONFIG FILE. */
import { configuration } from './Config/Config';




/* IMPORT LIBRARY  */
import { LINK_LAST_DATA, getCookie } from './Library/Library.js';





// PAGES
import Homepage from './Pages/Homepage';
import Consultancy from './Pages/Consultancy';
import About from './Pages/About';
import Dashboard from './Pages/Dashboard';
import Courses from './Pages/Courses';
import Course_details from './Pages/Course_details';
import Login from './Pages/Login';
import Register from './Pages/Register';
import RegisterNewCourse from './Pages/RegisterNewCourse';
import Error_404 from './Pages/Error_404';
import Reset_pass from './Pages/Reset_pass';
import Contact from './Pages/Contact';














function App(){
    
    return(
            
        <ScrollToTop>
            <Switch>
            
                <PrivateRoute path={configuration.DASHBOARD}>
                    <Dashboard />
                </PrivateRoute>
                
                <SpecialRoute1 path={configuration.LOGIN}>
                    <Login />
                </SpecialRoute1>
                
                <SpecialRoute2 path={configuration.REGISTER+"/:courseCodeForEnrolledCourse"}>
                    <Register />
                </SpecialRoute2>
                
                <SpecialRoute3 path={configuration.REG_NEW_COURSE+"/:courseCodeForEnrolledCourse"}>
                    <RegisterNewCourse />
                </SpecialRoute3>
            
                <Route path={configuration.HOME_PAGE} component={Homepage} exact />
                <Route path={configuration.CONSULTANCY} component={Consultancy} />
                <Route path={configuration.ABOUT} component={About} />
                <Route path={configuration.COURSES} component={Courses} />
                <Route path={configuration.COURSE_DETAILS+"/:courseCode"} component={Course_details} />
                <Route path={configuration.FORGET_PASS} component={Reset_pass} />
                <Route path={configuration.CONTACT} component={Contact} />
                <Route component={Error_404} />
            </Switch>
        </ScrollToTop>
    );
    
}

export default App;












// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("member_hash") ? (
                    children
                ):(
                    <Redirect to={{ pathname: configuration.LOGIN, state: { from: location } }} />
                )
            }
        />
    );
}






/* OUR FIRST SPECIAL ROUTE, JUST TO ENSURE THAT THE LOGIN PAGE CAN ONLY BE ACCESSIBLE WHEN USER IS NOT LOGGED IN, IF USER IS LOGGED IN THIS PAGE REDIRECTS TO DASHBOARD. */
function SpecialRoute1({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("member_hash") ? (
                    <Redirect to={{ pathname: configuration.DASHBOARD, state: { from: location } }} />
                ):(
                    children
                )
            }
        />
    );
}





/* OUR SECOND SPECIAL ROUTE, JUST TO ENSURE THAT THE MAIN MEMBER REGISTRATION PAGE CAN ONLY BE ACCESSIBLE WHEN USER IS LOGGED OUT. IF USER IS LOGGED IN, IT REDIRECTS TO THE
 * WEBSITES NEW COURSE REGISTRATION PAGE.  */
function SpecialRoute2({ children, ...rest }) {
    /* create location object. */
    let location = useLocation();
    /* use location object to fetch the route-path that is currently parrallel with this route, and then feed that data to the "LINK_LAST_DATA" function which will in turn 
     * help us fetch the data at the end of the route-url. note that this data is the "course code parameter" embedded on the url. */
    const course_code = LINK_LAST_DATA(location.pathname, "/");
    /* return our new custom route component.  */
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("member_hash") ? (
                    <Redirect to={{ pathname: configuration.REG_NEW_COURSE+"/"+course_code, state: { from: location } }} />
                ):(
                    children
                )
            }
        />
    );
}





/* OUR THIRD SPECIAL ROUTE, JUST TO ENSURE THAT THE NEW COURSE REGISTRATION PAGE CAN ONLY BE ACCESSIBLE WHEN USER IS LOGGED IN. IF USER IS NOT LOGGED IN, IT REDIRECTS TO THE
 * WEBSITES MAIN MEMBER REGISTRATION PAGE.  */
function SpecialRoute3({ children, ...rest }) {
    /* create location object. */
    let location = useLocation();
    /* use location object to fetch the route-path that is currently parrallel with this route, and then feed that data to the "LINK_LAST_DATA" function which will in turn 
     * help us fetch the data at the end of the route-url. note that this data is the "course code parameter" embedded on the url. */
    const course_code = LINK_LAST_DATA(location.pathname, "/");
    /* return our new custom route component.  */
    return (
        <Route
            {...rest}
            render={({ location }) =>
                getCookie("member_hash") ? (
                    children
                ):(
                    <Redirect to={{ pathname: configuration.REGISTER+"/"+course_code, state: { from: location } }} />
                )
            }
        />
    );
}