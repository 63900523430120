
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
//import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import FOOTER from './WEB_PARTS/FOOTER';



// IMPORT COMPONENTS
import COURSES_CARD from '../Components/COURSES_CARD';







export default function Courses() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "Courses | "+configuration.COMPANY_NAME;
    
    
    /* state to be used to store the popular course datas. */
    const [DATAS, setDATAS] = useState([]);
    /* states used to control the form inputes in this page. */
    const [COURSE_NAME, setCOURSE_NAME] = useState("");
    
    
    
    
    /* onchange functions called anytime the form states needs to be altered. */
    const COURSE_NAME_onChange = (e) => {       setCOURSE_NAME(e.target.value);       };
    
    
    
    
    
    
    
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    const LOADER_DATA = () => {
        /* collate data to be send to the server through API.  */
        const data = { calling_page: "COURSE" }
        /* call the api. */
        const url = configuration.HOMEPAGE_COURSE_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "DATAS" state variable using its set method.  */
            setDATAS(data);
        })
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    useEffect(LOADER_DATA, []);
    
    
    
    
    
    
    
    
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        
        /* collect all form inputes from their respective state variables.  */
        const data = { course_names: COURSE_NAME, calling_page: "COURSE" }
        /* call the api function responsible for handling the filtering job. */
        const url = configuration.HOMEPAGE_COURSE_SEARCH_API;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "DATAS" state variable using its set method.  */
            setDATAS(data);
        })
    }
    
    
    
    
    
    
    

    // how to do on script css
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/covers/cover-13.jpg)" };
    const style2 = { marginBottom: "7%" };
    
    return (
        
        <div>        

            <NAVIGATION_BAR />
            
            
            

                
            
            
                
                <section data-jarallax data-speed=".8" className="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover jarallax" style={style1}>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-7 text-center">
                                <h1 className="display-2 font-weight-bold text-white">Our Course Room</h1>
                                <p className="lead mb-0 text-white-75">
                                    Search through our list of Professional courses, start a program today!
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="position-relative">
                    <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
                        <svg viewBox="0 0 2880 48" fill="none" xmlns="">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"/>
                        </svg>
                    </div>
                </div>

                <section className="mt-n6">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={onSubmit} className="rounded shadow mb-4">
                                  <div className="input-group input-group-lg">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text border-0 pr-1"><i className="fe fe-search"></i></span>
                                    </div>
                                    <input onChange={COURSE_NAME_onChange} type="text" className="form-control border-0 px-1" aria-label="Search our blog..." 
                                    placeholder="What do you want to learn?" />
                                    <div className="input-group-append">
                                        <span className="input-group-text border-0 py-0 pl-1 pr-3">
                                            <button type="submit" className="btn btn-sm btn-primary">Search</button>
                                        </span>
                                    </div>
                                  </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            
                
                
                
                
                
                
                <section class="pt-7 pt-md-10" style={style2}>
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div className="row">
                                    {DATAS.map((infos) => {
                                        return (
                                            <COURSES_CARD card_datas={infos} />
                                        )
                                    })} 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            
            


            <FOOTER />
        
        </div>

    );

}