
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';



/* IMPORT LIBRARY  */
import { getCookie, Delete_cookie } from '../../Library/Library.js';




export default function NAVIGATION_BAR() {

    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();    





    const LOGOUT = () => {       
        /* Delet session cookie. */
        Delete_cookie("member_hash");
        /* Redirect user back to homepage. */
        history.push(configuration.HOME_PAGE);
    }



    const LOGIN_BUTTON_TOGGLER = () => {       
        /* create our controller loop to be used to decide whick kind of dropdown should be loaded.  */
        if(!getCookie("member_hash")){
            return (
                <Link className="navbar-btn btn btn-sm btn-primary lift" to={configuration.LOGIN}>Sign in</Link> 
            );
        }
    }
    
    
    
    const LOGGED_IN_DROPDOWN_TOGGLER = () => {       
        /* create our controller loop to be used to decide whick kind of dropdown should be loaded.  */
        if(getCookie("member_hash")){
            return (
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Account</Link>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link className="dropdown-item" to={configuration.DASHBOARD}>Dashboard</Link>
                      <div className="dropdown-divider"></div>
                      <Link onClick={LOGOUT} className="dropdown-item" to="">Logout</Link>
                    </div>
                </li>
            );
        }
    }







    // on-page css hacks
    //const style1 = { backgroundImage: "url(/OUTCAST/assets/img/photos/photo-3.jpg)" };
    const style2 = { marginRight: "3%" };
    
    return (

        <nav id='nav' className="navbar navbar-expand-lg navbar-light fixed-top bg-white border-bottom">
            <div className="container">

                <Link className="navbar-brand" to={configuration.HOME_PAGE}><img src="/OUTCAST/assets/img/brand.png" className="navbar-brand-img" 
                alt="Business Logo" /></Link>
                
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" 
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" 
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-x"></i>
                    </button>

                    <ul className="navbar-nav ml-auto" style={style2}>

                        <li class="nav-item dropdown">
                            <Link class="nav-link" id="navbarAccount" to={configuration.HOME_PAGE}>Home</Link>
                        </li>

                        <li class="nav-item dropdown">
                            <Link class="nav-link" id="navbarAccount" to={configuration.ABOUT}>About</Link>
                        </li>

                        <li class="nav-item dropdown">
                            <Link class="nav-link" id="navbarAccount" to={configuration.CONSULTANCY}>Services</Link>
                        </li>

                        <li class="nav-item dropdown">
                            <Link class="nav-link" id="navbarAccount" to={configuration.CONTACT}>Contact</Link>
                        </li>

                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarAccount" data-toggle="dropdown" to="" aria-haspopup="true" aria-expanded="false">Menu</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarAccount">
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.HOME_PAGE}>Home</Link></li>
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.ABOUT}>About us</Link></li>
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.COURSES}>Courses</Link></li>
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.REGISTER+"/default"}>Register</Link></li>
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.CONSULTANCY}>IT service and consulting</Link></li>
                                <li className="dropdown-item"><Link className="dropdown-link" to={configuration.CONTACT}>Contact us</Link></li>
                            </ul>
                        </li> */}
                        
                        {LOGGED_IN_DROPDOWN_TOGGLER()}
                        
                    </ul>

                    {LOGIN_BUTTON_TOGGLER()}
                    
                </div>
            </div>
        </nav>
        
    );

}