
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';


/* IMPORT LIBRARY  */
import { validateEmail } from '../../Library/Library.js';






export default function FOOTER(){
        
        
        
    /* state to be used to store the email address of the subscriber. */
    const [EMAIL, setEMAIL] = useState("");
    /* THE EVENT LISTENERS (FUNCTIONS) TO HELP US COLLECT DATA FROM EMAIL FORM AND SEND IT TO ITS STATE. */
    const EMAIL_onChange = (event) => {       setEMAIL(event.target.value);       };
         
    /* This function to help us subscribe website visitors to our newsletter.  */
    const SUBSCRIBER = () => {
        /* ensure that user enter email data that is in its correct formate and that this field is not empty.  */
        if(!validateEmail(EMAIL)){
            alert("Please ensure that a proper email address is entered.");
        }else{
            /* collate data to be send to the server through API.  */
            const data = { email: EMAIL }
            /* call the api this component needs to work fine. */
            const url = configuration.SUBSCRIBER;
            /* setting the api property to using the "FORM_POST" method, and also set the headers. */
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => response.json()).then((data) => {
                /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "DATAS" state variable using its set method.  */
                alert(data.message);
            });
        }
    }
        
    
    
    
    
    
    
    
    
    
    
    
    
    
    return (

        <div>
        
        
            <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-dark">
                  <svg viewBox="0 0 2880 48" fill="none" xmlns="">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"/>
                  </svg>
                </div>
            </div>
            

            <section className="pt-6 pt-md-8 bg-dark">
                <div className="container pb-6 pb-md-8 border-bottom border-primary">
                    <div className="row align-items-center">

                        <div className="col-12 col-md">
                        <h3 className="mb-1 font-weight-bold text-white">Get our stories delivered</h3>
                        <p className="font-size-lg text-muted mb-6 mb-md-0">From us to your inbox weekly.</p>
                        </div>

                        <div className="col-12 col-md-5">
                            <div className="row">
                                <div className="col">
                                    <input onChange={EMAIL_onChange} type="email" className="form-control" placeholder="Enter your email"></input>
                                </div>
                                <div className="col-auto ml-n5">
                                    <button onClick={SUBSCRIBER} className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            


            <footer className="py-8 py-md-11 bg-dark">
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-md-3 col-lg-3"></div>


                        <div className="col-6 col-md-3 col-lg-2"></div>

                        <div className="col-6 col-md-3 col-lg-2"></div>
                        
                        <div className="col-6 col-md-5 offset-md-4 col-lg-2 offset-lg-0">
                            <h6 className="font-weight-bold text-uppercase text-gray-700">Quick links</h6>
                            <ul className="list-unstyled text-muted mb-0">
                                <li className="mb-3"><Link to={configuration.HOME_PAGE} className="text-reset">Home</Link></li>
                                <li className="mb-3"><Link to={configuration.ABOUT} className="text-reset">About us</Link></li>
                                <li className="mb-3"><Link to={configuration.COURSES} className="text-reset">Courses</Link></li>
                                <li className="mb-3"><Link to={configuration.REGISTER+"/default"} className="text-reset">Sign up</Link></li>
                                <li className="mb-3"><Link to={configuration.CONSULTANCY} className="text-reset">IT Service</Link></li>
                                <li className="mb-3"><Link to={configuration.CONSULTANCY} className="text-reset">IT Consulting</Link></li>
                                <li className="mb-3"><Link to={configuration.CONTACT} className="text-reset">Contact us</Link></li>
                            </ul>
                        </div>
                        
                        <div className="col-6 col-md-5 col-lg-2">
                            <img src="/OUTCAST/assets/img/brand.png" alt="Background img" className="footer-brand img-fluid mb-2"></img>
                            <p className="text-gray-700 mb-2">A better way to Learn.</p>
                            <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
                              <li className="list-inline-item list-social-item mr-3">
                                  <a href={configuration.INSTAGRAM} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                      <img src="/OUTCAST/assets/img/icons/social/instagram.svg" className="list-social-icon" alt="IG"></img>
                                  </a>
                              </li>
                              <li className="list-inline-item list-social-item mr-3">
                                  <a href={configuration.FACEBOOK} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                      <img src="/OUTCAST/assets/img/icons/social/facebook.svg" className="list-social-icon" alt="FB"></img>
                                  </a>
                              </li>
                              <li className="list-inline-item list-social-item mr-3">
                                  <a href={configuration.TWITTER} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                                      <img src="/OUTCAST/assets/img/icons/social/twitter.svg" className="list-social-icon" alt="TW"></img>
                                  </a>
                              </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            
            
            
        </div>
    );

}