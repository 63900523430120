
// REACT STANDARD LIBRARY CALLS
import React from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';






export default function TEXT_ONLY(props){
        
    /**/
    const text_heading = props.text_heading;
    const description = props.description;
    //const lesson_count = props.lesson_count;
    const module_owner_name = props.module_owner_name;
    const module_owner_icon = props.module_owner_icon;
        
        
    
    // on-page css hacks
    const style1 = { textDecoration: "none", color: "#161C2D" };
    
    return (

        <div className="d-block shadow-lg lift mb-8" style={style1}>
            <div className="col-12-flex">
                <div className="card shadow-light-lg">
                    <div className="card-body my-auto">
                        <h3 className="mt-auto">{text_heading}</h3>
                        <p className="mb-0 text-muted">{description}</p>
                    </div>
                    <div className="card-meta">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2"><img src={configuration.RESOURCE+"OUTCAST/RESOURCE/uploads/"+module_owner_icon} alt="..." 
                        className="avatar-img rounded-circle" /></div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">{module_owner_name}</h6>
                    </div>
                </div>
            </div>
        </div>
                
    );

}