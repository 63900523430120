
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


/* IMPORT LIBRARY  */
import { TRUNCATE_STRING } from '../Library/Library.js';





export default function COURSES_CARD(props){
    
    // Here we declare the "props" that would be used by the parent function topopulate this component with data each time its called.
    const card_datas = props.card_datas;
    // here we construct the route/link to teleport us to the course details page of any selected course
    const info_link = configuration.COURSE_DETAILS+"/"+card_datas.course_code;
    
    /* codecs to control price display. */
    let price = "100% FREE";
    if(card_datas.openess === "OFF"){
        price = card_datas.course_price+" NGN";
    }
    
    
    // on-page css hacks
    const style1 = { marginBottom: "5%" };
    const style2 = { textDecoration: "none", color: "black" };
    
    return (

        <div className="col-12 col-md-6 col-lg-4 d-flex" style={style1}>
            <div className="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg shadow">
                <span className="badge badge-pill badge-light badge-float badge-float-inside">
                    <span className="h6 text-uppercase">Featured course</span>
                </span>

                <Link className="card-img-top" to={info_link}>
                    <img className="lazy card-img-top" src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+card_datas.course_img_link} alt="" />
                    <div className="position-relative">
                      <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                        <svg viewBox="0 0 2880 480" fill="none" xmlns="">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0V480H2880V0H2160Z" fill="currentColor"/>
                        </svg>
                      </div>
                    </div>
                </Link>

                <div className="card-body position-relative">
                    <div className="position-relative text-right mt-n8 mr-n4 mb-3">
                        <span className="badge badge-pill badge-warning">
                            <span className="h5 text-uppercase">{price}</span>
                        </span>
                    </div>
                    <Link className="card-img-top" to={info_link} style={style2}>
                        <h3>{TRUNCATE_STRING(card_datas.course_name, 42)}</h3>
                    </Link>
                    <p className="mb-0 text-muted">{TRUNCATE_STRING(card_datas.course_description, 158)}</p>
                </div>

                <div className="card-meta mt-auto">
                    <hr className="card-meta-divider"></hr>
                    <h6 className="text-uppercase text-muted mr-2 mb-0"><Link to={info_link}>More Info</Link></h6>
                    <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                    <Link to={configuration.REGISTER+"/"+card_datas.course_code}><button className="btn btn-sm btn-primary">Enroll Now</button></Link>
                    </p>
                </div>
            </div>
        </div>
            
    );

}