

// REACT STANDARD LIBRARY CALLS
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useHistory } from "react-router-dom";





export default function ALERT_MODAL(props){
        
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    
    /* Here we declare the "props" that would be used by the parent component to toggle (show or hide) this modal.  */
    const alert_modal_toggler = props.alert_modal_toggler;
    /* Here we declare the "props" that would be used by this modal to send close request to the parent component states, since this MODAL "on and off" controls are
     * inside it parent conponents state.  */
    const alert_modal_closer = props.alert_modal_closer;
    /* Here we declare the "props" that would be used by the parent component print any message on this modal.  */
    const alert_message = props.alert_message;
    /* Props to be used to determine if the parent component needs this modal to redirect to another page once the close button is clicked. if the parent component supplied a data
     * that is not a string => "false", them this system will assume the data inside this props contains the url-route to be redirected to */
    const redirect_request = props.redirect_request;
    /* since this MODALS "on and off" controls are inside it parent conponents state, therefore we create a function that this MODAL would call when it close button is 
     * clicked. So this modal calls this function which will now call our "alert_modal_closer" props and sends the "false" data through it to the parent component who 
     * will now extract this data and update the parent component states.  */
    const handleClose = () => {  
        /* send "false" to the parent components state, telling it to close this modal.  */
        alert_modal_closer(false);
        /* Here we check to affirm if parent component will need a redirect. */
        if(redirect_request !== "false"){
            /* if control gets in here it signify parent component needs a redirect, therefore we must assume the data in the "redirect_request" props holds the location. */
            history.push(redirect_request);
        }
    }
    
    
    
    
    
    
    return (

        <Modal show={alert_modal_toggler} onHide={handleClose} backdrop="static" keyboard={false} >

            <Modal.Body>
                <p>{alert_message}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn btn-primary lift" variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>

        </Modal>
            
    );

}