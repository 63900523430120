

// REACT STANDARD LIBRARY CALLS
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { PaystackButton } from 'react-paystack';



/* WEB APP CONFIG FILE. */
//import { configuration } from '../../Config/Config';




// onSuccess={(tranRef)=>{console.log(tranRef)}}

export default function PAY_MODAL(props){
    
    /* Here we declare the "props" that would be used by the parent component to toggle (show or hide) this modal.  */
    const toggler = props.toggler;
    /* Here we declare the "props" that would be used by this modal "PAY_MODAL" to send close request to the parent component states, since this "PAY_MODAL" on and off controls are
     * inside it parent conponents state.  */
    const closer = props.closer;
    /*  Here we declare the "props" that would be used by the parent component to add summary data's to this modal window.  */
    const datas = props.datas;
    /*  Here we declare the "props" that would be used by the parent component to set this modal window product purchase summary data.  */
    const summary = props.summary;
    /* since this "PAY_MODAL" on and off controls are inside it parent conponents state, therefore we create a function that this "PAY_MODAL" would call when it close button is 
     * clicked. So this modal calls this function which will now call our "close" props and sends the "false" data through it to the parent component who will now extract this data
     * and update the parent component states.  */
    const handleClose = () => {         closer(false);          }
    
    
    
    /* Paystack related Data's.  */
    const componentProps = {
        reference: (new Date()).getTime(),
        email: datas.email,
        amount: datas.amount,
        publicKey: datas.publicKey,
        text: datas.button_text,
        onSuccess: datas.onSuccess,
        onClose: () => null
    };
    
    
    
    
    
    return (

            <Modal show={toggler} onHide={handleClose} backdrop="static" keyboard={false} >
    
                <Modal.Header closeButton>
                    <Modal.Title>{datas.header_title}</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    {summary}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button className="btn btn-primary lift" variant="secondary" onClick={handleClose}>Close</Button>
                    <PaystackButton className="btn btn-primary lift" {...componentProps} />
                </Modal.Footer>
                
            </Modal>
            
    );

}