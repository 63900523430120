
// REACT STANDARD LIBRARY CALLS
import React, { useEffect } from 'react';
//import React from 'react';
import { Link } from "react-router-dom";

/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';


// IMPORT SNOWBALL CARDS COMPONENTS
import IMAGE_TEXT from '../DASHBOARD/LECTURE_CARDS/IMAGE_TEXT';
import TEXT_ONLY from '../DASHBOARD/LECTURE_CARDS/TEXT_ONLY';
import VIMEO_VIDEO_TEXT from '../DASHBOARD/LECTURE_CARDS/VIMEO_VIDEO_TEXT';
import VIDEO_TEXT from '../DASHBOARD/LECTURE_CARDS/VIDEO_TEXT';
import FILE_TEXT from '../DASHBOARD/LECTURE_CARDS/FILE_TEXT';
import AUDIO_TEXT from '../DASHBOARD/LECTURE_CARDS/AUDIO_TEXT';



/* IMPORT LIBRARY  */
import { getCookie, TRUNCATE_STRING } from '../../Library/Library.js';





/* MAIN SNOWBALL COMPONENT FOR CRASH COURSES.  */
export default function SNOW_BALL_CRASH_CONTAINER({switcher_datas, first_content, switched_content, disclaimer, snowballWEBINAR_info, switcher_data_Collector, 
        ACTING_MODULE_OWNERSHIP_DATAS, controller}){
     
    /* create function to be used to collect the new contents from the child component (SWITCHER), through the "course_data_messanger" props of the child component.  */
    const switchedContentCollector_1 = (new_contents) => {       switched_content(new_contents);         }
    /* create function to be used to collect webinar "top part" INFO message from the child component (SWITCHER), through the "snowballWEBINAR_info_messanger" props of the child 
     * component.  */
    const snowballWEBINAR_infoCollector_1 = (info) => {       snowballWEBINAR_info(info);         }
    /* create function to be used to collect "switcher acting course data" updates from the child component (SWITCHER), through the "switcher_data_messanger" props of the child 
     * component.  */
    const switcher_data_Collector_1 = (info) => {       switcher_data_Collector(info);         }


    // how to do on script css
    const style1 = { padding: "5% 5% 5% 5%" };
    let style6 = { display: "none" };
    if(controller === true){        style6 = { display: "block" };       }


    /* HERE WE HAVE A SUB CONTAINER.  */
    const CRASH_TOP_INFO = () =>{
        /* here we use this clause to ensure that the switcher gets to be displayed when there is an active course.  */
        if(switcher_datas){
            return(
                <div className="row">
                    <div className="col-12">
                        <SWITCHER switcher_datas={switcher_datas} course_data_messanger={switchedContentCollector_1} switcher_data_messanger={switcher_data_Collector_1} 
                        snowballWEBINAR_info_messanger={snowballWEBINAR_infoCollector_1} />
                    </div>
                    <div className="col-12 mt-2">
                        <div className="d-block  alert alert-dark shadow-lg lift" style={style1}>
                            <h3><span className="text-light font-weight-bold"><i>course:</i></span> <b>{ disclaimer.first_course_name }</b></h3>
                        </div>
                    </div>
                </div> 
            );
        }
    }


    /* Here is where the CRASH SNOWBALL COMPONENT begins to be created. it is composed and dependent of two other components. */
    return (
        <div className="col-12 col-md-8" style={style6}>
            <section className="pb-8 pt-md-12 pb-md-10">
                {CRASH_TOP_INFO()}
                <SNOW switcher_datas={switcher_datas} content={first_content} ACTING_MODULE_OWNERSHIP_DATAS={ACTING_MODULE_OWNERSHIP_DATAS} />
            </section>
        </div>
    );

}





/* HERE WE CREATE THE "SNOW" COMPONENT, WHICH IS A PART OF THE "CRASH SNOWBALL" WEBINAR PARENT COMPONENT, THIS "SNOW" IS THE COMPONENT TO BE USED TO DISPLAY ALL LECTURE CONTENTS AS
 * REGARDS TO THE ACTING SELECTED CRASH COURSE. THIS COMPONENT HAS THE "switcher_datas" PROPS, OF WHICH CARRIES INFORMATION OF ALL CRASH COURSES FOR THIS USER ACCOUNT AND IT SOLE 
 * PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO CONFIRM IF USER HAVE CRASH COURSES OR NOT, ALSO WE HAVE THE "content" PROPS, WHICH CARRIES ALL THE COURSE DATA FOR THE SELECTED
 * CRASH COURSE, ALSO WE HAVE "ACTING_MODULE_OWNERSHIP_DATAS" PROP, WHICH CARRIES IMPORTANT IDENTITY INFORMATIONS ABOUT THE AUTHOR OF EACH OF THE MODULES THAT WOULD BE COMMED ACCROSS
 * IN THE PROCESS OF VIEWING CRASH CONTENTS. PLEASE NOTE THAT ALL PROPS OF THIS COMPONENT ARE USED TO COLLECT DATAS FROM THE PARENT COMPONENT. */
const SNOW = ({switcher_datas, content, ACTING_MODULE_OWNERSHIP_DATAS}) => {
    /* Here we shall be using the "useEffect" function to ensure that the page is automatically scrolled to the top on routing into this components parent component.  */
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    /* here we check to see if there is no data inside the "switcher_datas" props array, as we know that this prop array carries the datas of users crash courses, so if this props 
     * array is empty this only signifies that user doesn't have any crash course. */
    if(!switcher_datas){
        return (
            <div className="d-block shadow-lg lift mb-8">
                <div className="col-12-flex">
                    <div className="card alert alert-light shadow-light-lg">
                        <div className="card-body my-auto">
                        <p className="mb-0 font-weight-bold text-mute">Hi, at the moment you do not have any completed course, <Link to={configuration.COURSES}>click here</Link> to 
                                choose a new course to undertake.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        /* Control getting in here only signifies that user have crash course, therefore in order to prevent any sort of error we go further to test the "content" props array which is 
         * suppose to be the one carrying the main crash content, we check if this props array is not empty.  */
        if(content){
            /* Control getting in here only signifies that the "content" props array isn't empty, therefore we create the "module_owner_data" array to be used to store the each module 
             * auther name and icon location datas.  */
            const module_owner_data = [];
            /* Here we begin the creation of the contents that would be embedded in this "SNOW" component/container, the creation of the display contents for this componebt starts 
             * here. */
            return (
                /* Since we know for sure at this point we have contents to process and create displays for, we now map through all this content in a bid to purge out its datas and 
                 * process then use them. Therefore we create the loop we going to use to perform this operations.  */
                content.map((data, index) => {
                    /* Each loop cycle represent one lecture data, and since this component is made for the CRASH, its important to note that this loop would run for quite some time
                     * since the whole lecture datas for the selected crash course would be shown all at ones. In the line of code below in a bid to ensure that we trying to get module
                     * owners details through the crash "SNOW" component, we carry the test bellow to be sure. if "ACTING_MODULE_OWNERSHIP_DATAS" is an empty string this signifies 
                     * this, therefore we have to get course author details in a different way from the normal way we get this same info if we were doing it from the main "SNOW" webinar
                     * but since this is the crash webinar, the course authors details are always embedded in each lecture data, so we extract it per this loop cycle below. */
                    if(ACTING_MODULE_OWNERSHIP_DATAS === ""){       module_owner_data[0] = data.author_name;            module_owner_data[1] = data.icon_link;          }else{
                               module_owner_data[0] = "";            module_owner_data[1] = "";          }
                    /* We have different kind of lecture data types ranging from video to file to text etc. Therefore at this junction we run a test to affirm which lecture data type
                     * is our current loop cycle purging out datas for, after detecting the lecture data type, we now load the appropriate component to harness the datas associated with
                     * this lectures data type. */
                    if(data.content_type === "vimeo-video"){
                        return (
                            <VIMEO_VIDEO_TEXT key={index.toString()} video_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "video"){
                        return (
                            <VIDEO_TEXT key={index.toString()} video_image={data.content_img_video_aud_text} video_download_link={data.content_download_link} 
                                    video_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                    module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "file"){
                        return (
                            <FILE_TEXT key={index.toString()} file_front_image={data.content_img_video_aud_text} file_download_link={data.content_download_link} 
                                    file_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                    module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "text"){
                        return (
                            <TEXT_ONLY key={index.toString()} text_heading={data.content_img_video_aud_text} description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "audio"){
                        return (
                            <AUDIO_TEXT key={index.toString()} audio_download_link={data.content_download_link} audio_description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "image"){
                        return (
                            <IMAGE_TEXT key={index.toString()} image_links={data.content_img_video_aud_text} image_description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else{
                        return null;
                    }
                })
            );
        }else{
            /* Control getting in here only signifies that the "content" props array is empty, despite the fact that the "switcher_datas" props array is not empty, this occation is not 
             * predicted to occure but incase it does this signifies a problem in the system, therefore we prepare the appropriate message telling client what next to do.  */
            return (
                <div className="d-block shadow-lg lift mb-8">
                    <div className="col-12-flex">
                        <div className="card alert alert-light shadow-light-lg">
                            <div className="card-body my-auto">
                                <p className="mb-0 font-weight-bold text-mute">Please contact admin if you seeing this..... </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};









/* HERE WE CREATE THE "SWITCHER" COMPONENT, WHICH IS A PART OF THE "CRASH SNOWBALL" WEBINAR PARENT COMPONENT, THIS "SWITCHER" IS THE COMPONENT TO BE USED TO DISPLAY ALL COMPLETED 
 * COURSES ON A UNIQUE WEBINAR DROPDOWN CALLED "THE COURSE SWITCHER". THIS COMPONENT HAS THE "switcher_datas" PROPS, OF WHICH CARRIES INFORMATION OF ALL CRASH COURSES FOR THIS USER 
 * ACCOUNT AND IT SOLE PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO PROCESS DISPLAY SHOWING THROUGH A DROPDOWN ALL CRASH COURSES, ALSO WE HAVE THE "course_data_messanger" 
 * PROPS, WHICH WOULD BE USED AS A VESSEL TO CARRY COURSE CONTENT DATA THAT WAS NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE SWITCHER DROPDOWN, ALSO WE HAVE 
 * "switcher_data_messanger" PROP, WHICH IS ALSO USED AS A VESSEL TO CARRY UPDATED CRASH/COMPLETED COURSE NAME AND COURSE CODE DATA NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE 
 * SWITCHER DROPDOWN, ALSO WE HAVE "snowballWEBINAR_info_messanger" PROPS, WHICH IS ALSO USED AS A VESSEL TO EXPORT NEXT LECTURE DATE DATA AND OTHER DATAS, NEWLY FETCHED AS A RESULT OF
 * INTERACTION WITH THE SWITCHER DROPDOWN, NOTE THAT ANY TIME THE SWITCHER DROPDOWN IS USED THIS COMPONENT WOULD HAVE TO RE-REQUEST FOR CERTAIN DATAS SO THEY CAN BE UPDATED FROM CLIENT
 * FRONT-END VIEW. PLEASE NOTE THAT ONLY THE "switcher_datas" PROPS OF THIS COMPONENT ARE USED TO COLLECT DATAS FROM THE PARENT COMPONENT, OTHER PROPS SUCH AS "course_data_messanger",
 * "switcher_data_messanger" AND "snowballWEBINAR_info_messanger" ARE USED AS CARRIERS, FOR TRANSPORTING DATAS FROM THIS FUNCTION TO ITS PARENT FUNCTION. */
let SWITCHER = ({switcher_datas, course_data_messanger, switcher_data_messanger, snowballWEBINAR_info_messanger}) => {
    /* Here we have a function that will run anytime the switcher dropdown menu item is clicked, this function simply collects the "course code" data of the switcher selected course 
     * and uses this code to fetch new contents and webinar related contents from the api response. this function doesn't just fetch this data it also uses the carrier props of this
     * component to update the super parent states of the new change. */
    const Switch = (courseCode) => {
        /* collate data (which is the course code of the selected course) to be send to the server through API, the logged-in user token and sender info of which in this case is the
         * crash component, this sender info data tells the server how it should fetch data.   */
        const data = { TOKEN: getCookie("member_hash"), COURSE_CODE: courseCode, SENDER: "CRASH" };
        /* In a bid to execute user request we call the API responsible for handling this request.  */
        const url = configuration.COURSE_SWITCHER_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
             * array at this point. we set this array into the "course_data_messanger" carrier props, to help us carry the newly fetched course datas all the way through to the 
             * parent component and then to the super parent component (dashboard).  */
            course_data_messanger(data.contents);
            /* We set the array content inside "data.courses" into the "switcher_data_messanger" carrier props, to help us carry the newly fetched course name and course code data 
             * all the way through to the parent component and then to the super parent component.  */
            switcher_data_messanger(data.courses);
            /* We set the array content inside "data.current_and_next_lecture_date" into the "snowballWEBINAR_info_messanger" carrier props, to help us carry the newly fetched NEXT 
             * LECTURE DATE DATA AND OTHER DATAS all the way through to the parent component and then to the super parent component.  */
            snowballWEBINAR_info_messanger(data.current_and_next_lecture_date);
        });
    };


    /* here we check to see if there is no data inside the "switcher_datas" props array, as we know that this prop array carries the datas of users crash courses, so if this props 
     * array is empty this only signifies that user doesn't have any crash course. */
    if(switcher_datas){
        /* Control getting in here only signifies that the "switcher_datas" props array is not empty, therefore we carry out another test to affirm if we have more than one crash 
         * course or not.  */
        if(switcher_datas.length > 1){
            /* Control getting in here only signifies that we have more than one crash course, therefore we start the process of creating the switcher button dropdown to demonstrate on
             * user UI that there are more than one crash course and that user can use the switcher to navigate between all crash courses.  */
            const style = { zIndex: "9999"};
            return (
                <div className="dropdown mr-1 mb-1">
                    <button className="btn btn-block btn-outline-dark lift dropdown-toggle" type="button" id="dropdownMenuButtonTwo" data-toggle="dropdown" 
                            aria-haspopup="true" aria-expanded="false">Switch course</button>
                    <div className="container-fluid dropdown-menu" aria-labelledby="dropdownMenuButtonTwo" style={style}>
                        {switcher_datas.map((infos, index) => {
                            return (
                                <Link onClick={() => Switch(infos.course_code)} key={index.toString()} className="d-flex justify-content-between dropdown-item active" to="#">
                                    {TRUNCATE_STRING(infos.course_name, "50", "..")}</Link>
                            );
                        })}
                    </div>
                </div>
            );
        }else{
            /* Control getting in here only signifies that we have just one crash course, therefore we start the process of creating a DISABLED switcher button WITHOUT A DROPDOWN 
             * demonstrating to user that there is only one crash course and that user can NOT use the switcher to navigate between courses, since we have just one crash course. */
            return (
                <div className="dropdown mr-1 mb-1">
                    <button className="btn btn-block btn-dark" disabled type="button" id="dropdownMenuButtonTwo">Switch course</button>
                </div>
            );
        }
    }else{
        /* Control getting in here only signifies that the "switcher_datas" props array is empty, so we still go ahead to create a DISABLED NON DROPDOWN SWITCHER BUTTON. */
        return (
            <div className="dropdown mr-1 mb-1">
                <button className="btn btn-block btn-dark" disabled type="button" id="dropdownMenuButtonTwo">Switch course</button>
            </div>
        );
    }
};