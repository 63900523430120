




    // var MEDIA_SERVER_LINK = "http://localhost/SMARTTECH_ACADEMY/smartTechAcademyAPI/";
    var MEDIA_SERVER_LINK = "https://smarttechacademy.org/BACKEND_FILES/";
    var SERVER_DOMAIN = "http://localhost/SMARTTECH_ACADEMY/smartTechAcademyAPI/";
    var API_SERVER_LINK = SERVER_DOMAIN+"index.php/";
    var NUMBER = "8034436977";
    //var API_BASE_URL = "http://localhost/smartTechAcademyAPI/";


    export const configuration = { 
        
        /* BASIC SITE DATAS.  */
        COMPANY_NAME: "SmartTech Academy",
        COMPANY_EMAIL: "info@smarttechacademy.org",
        HOTLINE: "+234"+NUMBER,
        WHATSAPP_LINK: "https://wa.me/0"+NUMBER,
        EXPERIENCE: "10",
        FOUNDED: "2020",
        SERVER_DOMAIN: SERVER_DOMAIN,
        RESOURCE: MEDIA_SERVER_LINK,
        
        /* SITE URL */
        HOME_PAGE: "/",
        CONTACT: "/contact/smarttechschool",
        CONSULTANCY: "/services",
        ABOUT: "/about/us",
        COURSES: "/search/courses",
        COURSE_DETAILS: "/course/details",
        REGISTER: "/signup",
        REG_NEW_COURSE: "/reg/new/course",
        //BLOG: "/vvvv",
        LOGIN: "/login",
        FORGET_PASS: "/forget/pass",
        DASHBOARD: "/dashboard",
        
        /* OPERATIONS API's LIST  */
        COURSE_LOADER: API_SERVER_LINK+"load/all/courses",
        HOMEPAGE_COURSE_API: API_SERVER_LINK+"load/featured/courses",
        HOMEPAGE_COURSE_SEARCH_API: API_SERVER_LINK+"load/course/filter",
        COURSE_DETAILS_API: API_SERVER_LINK+"fetch/course/details",
        REG_AUTH_API: API_SERVER_LINK+"registration/authenticator",
        USER_REG_API: API_SERVER_LINK+"new/signup",
        PASS_RECOVERY_API: API_SERVER_LINK+"reset/password",
        LOGIN_API: API_SERVER_LINK+"member/login/authenticator",
        CONTACT_API: API_SERVER_LINK+"contact/us",
        SUBSCRIBER: API_SERVER_LINK+"visitor/subscriber",
        
        DASHBOARD_LOADER_API: API_SERVER_LINK+"load/dashboard/data",
        COURSE_SWITCHER_API: API_SERVER_LINK+"switch/cousre/data",
        PAUSE_PLAY_API: API_SERVER_LINK+"pause/play/course",
        PREVIOUS_LECTURES_API: API_SERVER_LINK+"reverse/lectures/loader",
        
        //HOMEPAGE_BLOG_API: API_SERVER_LINK+"api/api/statest3",
        
        /* API KEY */
        KEY: "pk_test_46de0aa3c6601c75151d204271488c7c53adcd42",  
        
        /* COMPANY SOCIAL MEDIA HANDLES. */
        FACEBOOK: "https://facebook.com/smarttechschools",
        TWITTER: "https://twitter.com/smarttechschool",
        INSTAGRAM: "https://instagram.com/smarttechschools",
        
        
        /* GET THE CURRENT YEAR*/
        CURRENT_YEAR: new Date().getFullYear()
    };
    // pk_test_46de0aa3c6601c75151d204271488c7c53adcd42 mine not joels
