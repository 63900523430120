
// REACT STANDARD LIBRARY CALLS
import React from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';





export default function FILE_TEXT(props){
        
        
    /**/
    const file_front_image = props.file_front_image;
    const file_download_link = props.file_download_link;
    const file_description = props.file_description;
    //const lesson_count = props.lesson_count;
    const module_owner_name = props.module_owner_name;
    const module_owner_icon = props.module_owner_icon;
    
    
    
    
    /* A FUNCTION TO HELP US ALTERNATE THROUGH THE POSSIBLE CONDITIONS OF THE DESCRIPTION LAYER OF THIS COMPONENT. */
    const DESCRIPTION = (description) => {
        /* Here we quickly check if the content to be displayed has description information attached to it or not. */
        if(description){
            /* If the control gets in here it mean that the content to be displayed has description information attached to it, Therefore we create the description layer. */
            return(
                <div className="card-body"><p className="card-text">{description}</p></div>
            );
        }else{
            /* If the control gets in here it mean that the content to be displayed has no description information attached to it, Therefore we do otherchecks to affirm if the screen
             * requesting for this contents is a mobile screen or desktop. This is so we can better serve view.  */
            if(window.innerWidth < 480){
                /* Gontrol geting in here mean that the screen requesting is a mobile phone screeen. */
                return(     <div></div>    );
            }else{
                /* Gontrol geting in here mean that the screen requesting is a desktop screeen. */
                return(     <div className="card-body"></div>    );
            }
        }
    }
        
        
    
    // on-page css hacks
    const style1 = { textDecoration: "none", color: "#161C2D" };
    const style2 = { padding: "0 0 0 0" };
    const style3 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/"+file_front_image+")" };
    
    return (

        <div className="d-block shadow-lg lift lift-lg mb-8" style={style1}>
            <div className="col-12-flex">
                <div className="card">
                
                    <div className="py-12 py-md-4 bg-cover" style={style3}>
                        <div className="container py-md-13">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a download className="btn btn-pill btn-white text-body shadow lift" target="_blank" rel="noopener noreferrer" 
                                        href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+file_download_link}>
                                        <span className="h6 text-uppercase font-weight-bold"><i className="fe fe-download mr-2"></i>Download File</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                      <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                        <svg viewBox="0 0 2880 480" fill="none" xmlns="">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0V480H2880V0H2160Z" fill="currentColor"/>
                        </svg>
                      </div>
                    </div>
                    
                    {DESCRIPTION(file_description)}
                    
                    <div className="card-meta">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2"><img src={configuration.RESOURCE+"OUTCAST/RESOURCE/uploads/"+module_owner_icon} alt="..." 
                        className="avatar-img rounded-circle" /></div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">{module_owner_name}</h6>
                    </div>
                    
                    <div className="card-meta" style={style2}>
                        <a className="btn btn-block btn-secondary btn-lg shadow-lg" target='_blank' rel="noopener noreferrer" 
                        href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+file_download_link}><i className="fe fe-download mr-2"></i>Download File</a>
                    </div>
                </div>
            </div>
        </div>
                
    );

}

