
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import SLIDER from './WEB_PARTS/SLIDER';
//import INSTRUCTOR_RECRUIT_MESSAGE from './WEB_PARTS/INSTRUCTOR_RECRUIT_MESSAGE';      <INSTRUCTOR_RECRUIT_MESSAGE />
import COURSES from './WEB_PARTS/COURSES';
import LET_US_TEACH_YOUR_TEAM from './WEB_PARTS/LET_US_TEACH_YOUR_TEAM';
//import PARTNERS from './WEB_PARTS/PARTNERS';
import OUR_PROCESS from './WEB_PARTS/OUR_PROCESS';
import CONSULTING from './WEB_PARTS/CONSULTING';
// import HIGHLIGHT_BLOG from './WEB_PARTS/HIGHLIGHT_BLOG';              <HIGHLIGHT_BLOG card_data_prop={BLOG_DATAS} />
import FOOTER from './WEB_PARTS/FOOTER';








export default function Homepage() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "Home | "+configuration.COMPANY_NAME;
    
    
    /* state to be used to store the popular course datas. */
    const [DATAS, setDATAS] = useState([]);
    /* state to be used to store the popular blog datas. */
    //const [BLOG_DATAS, setBLOG_DATAS] = useState([]);
    
    
    /* our call back function. */
    const callbackFunction = (childData) => {
        setDATAS(childData);
    }
    
    
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    const LOADER_DATA = () => {
        /* collate data to be send to the server through API.  */
        const data = { calling_page: "HOME" }
        /* call the api this component needs to work fine. */
        const url = configuration.HOMEPAGE_COURSE_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "DATAS" state variable using its set method.  */
            setDATAS(data);
        })
        
        /* call the second api required by this component. */
        //const url_2 = configuration.HOMEPAGE_BLOG_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        //fetch(url_2, { method: 'GET', headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        //.then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, and we assign this array to our "BLOG_DATAS" state variable using its set method.  */
        //    setBLOG_DATAS(data);
        //})
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    useEffect(LOADER_DATA, []);
    

    // how to do on script css
    //const style1 = { backgroundImage: "url(assets/images/illustration/img-1.png)" };
    
    
    return (
        
        <div>        

            <NAVIGATION_BAR />

            <SLIDER />
            
            <COURSES card_data_prop={DATAS} card_data_setter_prop={callbackFunction} />
            
            <LET_US_TEACH_YOUR_TEAM />
            
            <OUR_PROCESS />
            
            <CONSULTING />

            <FOOTER />
        
        </div>

    );

}