
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import MODALS from './WEB_PARTS/MODALS';
import FOOTER from './WEB_PARTS/FOOTER';



/* IMPORT LIBRARY  */
import { EXPLODE } from '../Library/Library.js';







export default function Course_details(props) {
    
    /* lets set the page title. */
    document.getElementById("title").innerHTML = "Course details | "+configuration.COMPANY_NAME;
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    /* collate data (which is the course code of the selected course) to be send to the server through API and also used by the routers inside this page.  */
    const course_code_data = props.match.params.courseCode;
    
    
    
    /* create the state to be used to store selected course basic details. */
    const [course_name, setCourse_name] = useState("");
    const [course_img_link, setCourse_img_link] = useState("");
    const [course_description, setCourse_description] = useState("");
    const [course_price, setCourse_price] = useState("");
    const [module_no, setModule_no] = useState("");
    const [weeks_count, setWeeks_count] = useState("");
    const [videos_count, setVideos_count] = useState("");
    /* state to be used to store selected course modules names, outlines and outline description, just as fetched [concated data] from the API. */
    const [INFO_DATAS, setINFO_DATAS] = useState([]);
    
    
    
    
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    const LOADER_DATA = () => {
        /* collate data (which is the course code of the selected course) to be send to the server through API.  */
        const data = { DATA: course_code_data }
        /* call the api. */
        const url = configuration.COURSE_DETAILS_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* check at this point if the api operations was a success or not.  */
            if(data.status){
                /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
                 * array at this point. we set this array into the "INFO_DATAS" state.  */
                setINFO_DATAS(data['modules']);
                /* we collect other course basic details data from the API and assign them to their respective states. */
                setCourse_name(data.course_name);
                setCourse_img_link(data.course_img_link);
                setCourse_description(data.course_description);
                setCourse_price(data.course_price);
                setModule_no(data.module_no);
                setWeeks_count(data.weeks_count);
                setVideos_count(data.videos_count);
            }else{
                /* control in here means that api operations for one reason or the other was not a success, therefore we redirect user back to the course room page.  */
                history.push(configuration.COURSES);
            }
        });
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    useEffect(LOADER_DATA, []);
    
    
    /* create a variable we gonna use later inside our map function to store a very important array. */
    let heads_body = "";
    
    /* on-page css hacks  */
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/"+course_img_link+")" };
    const style2 = { marginTop: "1%" };
    const style3 = { marginTop: "10%" };
    return (
        
        <div>        

            <NAVIGATION_BAR />

            <MODALS />
            
                <section className="pt-8 pt-md-11" style={style2}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-row shadow-lg bg-cover overlay overlay-gradient-dark-left overlay-50 text-white mb-0" style={style1}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-md-7">
                                            <div className="card-body">
                                                <h2 className="font-weight-bold">{course_name}</h2>
                                                <p className="mb-0 text-white-80">{course_description}</p>
                                            </div>
                                            <div className="card-meta">
                                                <hr className="card-meta-divider"></hr>
                                                <h3 className="text-uppercase text-white font-weight-bold mr-2 mb-0">{course_price}</h3>
                                                <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                                                    <Link to={configuration.REGISTER+"/"+course_code_data}>
                                                        <button type="submit" className="btn btn-sm btn-primary">Enroll Now</button>
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>  
                
                
                <section className="pt-8 pt-md-5 pb-8 pb-md-14" id="details">
                    <div className="container">
                        <div className="row"><div className="col-12"><hr className="my-6 my-md-8" /></div></div>

                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="row mb-0 mb-md-8">
                                    <div className="col-auto"><div className="icon-circle bg-primary text-white"><i className="fe fe-file"></i></div></div>
                                    <div className="col ml-n4">
                                        <h1 className="font-weight-bold mb-0">COURSE CONTENT</h1>
                                        <p className="font-size-lg text-gray-700 mb-0">Below is a detailed breakdown of what you stand to achieve at the end of this course.</p>
                                    </div>
                                </div>
                                
                                
                                
                                {INFO_DATAS.map((infos, index) => {
                                    heads_body = EXPLODE("#@", infos.module_outline_description);
                                    return (
                                        <div className="mb-4" key={index.toString()}>    
                                            <div className="col ml-n4">
                                            <h2 className="mb-0 font-weight-bold  text-uppercase"><span className="text-primary">{infos.module_name}</span></h2>
                                            <p className="font-size-lg text-gray-700 mb-3">{infos.module_description}</p>
                                            </div>
                                            
                                            {EXPLODE("#@", infos.module_outline_head).map((infos_2, indexer) => {
                                                return (
                                                    <div className="d-flex" key={indexer.toString()+"XXX"}>
                                                        <span className="badge badge-rounded-circle badge-info-soft mt-1 mr-4"><b>{indexer+1}</b></span>
                                                        <p className="text-gray-800">
                                                            <span className="text-primary font-weight-bold">{infos_2}:</span> {heads_body[indexer]}
                                                        </p>
                                                    </div>
                                            )})}
                                        
                                        </div>
                                    );
                                })} 
                                
                                <Link to={configuration.REGISTER+"/"+course_code_data} className="btn btn-block btn-primary lift">Enroll Now</Link>
                                
                            </div>
                            
                            <div className="col-12 col-md-4">
                                <div className="card shadow-light-lg align-items-center shadow lift">
                                    <div className="d-flex" style={style3}>
                                        <div className="pr-5">
                                            <h3 className="text-success mb-0">{module_no}</h3>
                                            <p className="text-gray-700 mb-0">Modules</p>
                                        </div>
                                        <div className="border-left border-gray-300"></div>
                                        <div className="px-5">
                                            <h3 className="text-success mb-0">{weeks_count}</h3>
                                            <p className="text-gray-700 mb-0">Weeks</p>
                                        </div>
                                        <div className="border-left border-gray-300"></div>
                                        <div className="pl-5">
                                            <h3 className="text-success mb-0">{videos_count}</h3>
                                            <p className="text-gray-700 mb-0">Videos</p>
                                        </div>
                                    </div>
                                
                                    <div className="card-body">
                                        <h4 className="font-weight-bold">Connect with us</h4>
                                        <p className="font-size-sm text-gray-800 mb-5">
                                            Not sure exactly what you need, do you need a personal learning recommendation or you just want clarification? 
                                            We’d be happy to talk with you and clear things up for you. Anytime!
                                        </p>
                                        <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">Call anytime</h6>
                                        <p className="font-size-sm mb-5"><a href={"tel:"+configuration.HOTLINE} className="text-reset">{configuration.HOTLINE}</a></p>
                                        <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">Email us</h6>
                                        <p className="font-size-sm mb-0">
                                            <a href={"mailto:"+configuration.COMPANY_EMAIL} className="text-reset">{configuration.COMPANY_EMAIL}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                
            <FOOTER />
        
        </div>

    );

}
                                
          