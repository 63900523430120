

// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";


/* WEB APP CONFIG FILE. */
//import { configuration } from '../../Config/Config';





export default function WELCOME_VIDEO_MODAL(props){
        
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();
    
    /* props to be used to collect user fuoll name from dashboard [parent] component */
    const user_fullName = props.user_fullName;
    /* Here we declare the "props" that would be used by the parent component to toggle (show or hide) this modal.  */
    const welcome_video_modal_toggler = props.welcome_video_modal_toggler;
    /* Here we declare the "props" that would be used by this modal to send close request to the parent component states, since this MODAL "on and off" controls are
     * inside it parent conponents state.  */
    const welcome_video_modal_closer = props.welcome_video_modal_closer;
    /* Props to be used to determine if the parent component needs this modal to redirect to another page once the close button is clicked. if the parent component supplied a data
     * that is not a string => "false", them this system will assume the data inside this props contains the url-route to be redirected to */
    const redirect_request = props.redirect_request;
    /* since this MODALS "on and off" controls are inside it parent conponents state, therefore we create a function that this MODAL would call when it close button is 
     * clicked. So this modal calls this function which will now call our "welcome_video_modal_closer" props and sends the "false" data through it to the parent component who 
     * will now extract this data and update the parent component states.  */
    const handleClose = () => {  
        /* send "false" to the parent components state, telling it to close this modal.  */
        welcome_video_modal_closer(false);
        /* Here we check to affirm if parent component will need a redirect. */
        if(redirect_request !== "false"){
            /* if control gets in here it signify parent component needs a redirect, therefore we must assume the data in the "redirect_request" props holds the location. */
            history.push(redirect_request);
        }
    }
    



    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    /*const LOADER_DATA = () => {
        const script = document.createElement("script");
        script.async = true;
        script.src = configuration.RESOURCE+"OUTCAST/VENDORS/video-js/video.min.js";
        document.head.appendChild(script);
    }*/
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    //useEffect(LOADER_DATA, []);
    
    
    //const video_image = configuration.RESOURCE+"OUTCAST/RESOURCE/welcome_image_video/welcome.png";
    //const video_download_link = configuration.RESOURCE+"OUTCAST/RESOURCE/welcome_image_video/ideywork.mp4";
    
    // on-page css hacks
    const style0 = { padding: "0 0 0 0", marginTop: "5%" };
    const style1 = { textDecoration: "none", color: "#161C2D", margin: "0 0 0 0", padding: "0 0 0 0" };
    //const style2 = { position: "relative", paddingBottom: "56.25%", height: "0" };
    const style3 = { /*position: "absolute"*/ };
    const style4 = { /*backgroundColor: "#eee", padding: "0 0 0 0", margin: "0 0 0 0"*/ };
    //const style5 = { backgroundColor: "transparent" };
    
    return (

        <Modal show={welcome_video_modal_toggler} onHide={handleClose} size="lg" backdrop="static" keyboard={false} style={style0} >
           <div className="cont-welcome-video cont" style={style3}>
                <iframe className="cont" title="Welcome" src="https://player.vimeo.com/video/464155754" frameborder="0" allow="autoplay; fullscreen" allowfullscreen 
                style={style4}></iframe>
            </div>
            <div className="d-block shadow-lg lift lift-lg mb-8" style={style1}>
                
                <div className="col-12-flex">
                    <div className="card cont">


                        <div className="card-meta">
                            <hr className="card-meta-divider" />
                            <h6 className="text-uppercase text-muted mr-2 mb-0">Welcome, <b>{user_fullName}</b></h6>
                            <p className="h6 text-uppercase text-muted mb-0 ml-auto">
                              <Link dateTime="2019-05-02" to="#" onClick={handleClose}>Close</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </Modal>
            
    );

}