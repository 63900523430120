
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";




/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';




// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import FOOTER from './WEB_PARTS/FOOTER';




// IMPORT COMPONENTS
import ALERT_MODAL from '../Components/MODALS/ALERT_MODAL';





/* IMPORT LIBRARY  */
import { setCookie } from '../Library/Library.js';







export default function Login() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "Sign in | "+configuration.COMPANY_NAME;
    /* we create "useHistory" object to be used for redirecting. */
    let history = useHistory();    
    
    
    
    
    /*     A L E R T    M O D A L    S T A T E S       */
    /* create state to be used to control medal window from parent component. */
    const [showAlert, setAlertShowStatus] = useState(false);
    /* create state to be used to set the modal window display message. */
    const [alertMessage, setAlertMessage] = useState("");
    /* create state to be used to control if the modal window should redirect after its close button is clicked or not. */
    const [allow_redirect, setAllow_redirect] = useState("false");
    /* create function to be used to collect "window close request" from the child modal component, through the "closer" props of the child modal component.  */
    const alertCloserFunction = (alert_CloseData) => {       setAlertShowStatus(alert_CloseData);         }
    
    
    
    
    /*     P A G E      F O R M    S T A T E S        A N D       U N C H A N G E D       F U N C T I O N S        */
    /* state to be used to store user email data. */
    const [EMAIL, setEMAIL] = useState("");
    const [PASSWORD, setPASSWORD] = useState("");
    const EMAIL_onChange = (event) => {       setEMAIL(event.target.value);       };  
    const PASSWORD_onChange = (event) => {       setPASSWORD(event.target.value);       };
    
    
    
    
    
    
    
    
    /*       P A G E       U N S U B M I T      F U N C T I O N       */
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        
        /* collect the user supplied email from the state variable, and prepare it for transport. */
        const data = { EMAIL: EMAIL, PASSWORD: PASSWORD }
        /* call the api function responsible for user password reset operation. */
        const url = configuration.LOGIN_API;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((response) => {
            /* Here we check if password operations completed well or maybe there where errors. the outcome of this test would determine how the data returned from the server
             * would be collected. */
            if(response.status === true){
                /* CREATE THE SESSION COOKIE, AND USE THE TOKEN DATA COLLECTED FROM THE SERVER TO SET THE SESSION VALUE. ALSO SET COOKIE EXPIRING TO ONE DAY. */
                setCookie("member_hash", response.TOKEN, 1);
                /* REDIRECT USER TO PROTECTED PAGE, THAT IS THE USER DASHBOARD. */
                history.push(configuration.DASHBOARD);
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT CAN REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE OPERATION WAS A SUCCESS.  */
                ALERT_MODAL_TRIGGER(response.message, true, configuration.LOGIN);
            }else{
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT WILL NOT REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE OPERATION FAILED. */
                ALERT_MODAL_TRIGGER(response.message, true);
            }
        });
    }
    
    
    
    
    
    
    
    
    
    
    /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON CLICKING ITS CLOSE
     * BUTTON OR NOT. THE FIRST PARAMETER IS THE MESSAGE TO BE DISPLAYED, THE SECOND IS THE TOGGLER WHICT TELLS OUR MODAL TO POP UP OR POP DOWN LOL, WHILE THE THIRD PARAMETER IS
     * OPTIONAL, IF ITS NOT DOPPED AND LEFT ALONE THIS MODAL WILL NOT REDIRECT WHEN ITS CLOSE BUTTON IS CLICKED BUT IF ITS DOPPED WITH A ROUTE THEN UPON CLICKING OF THIS MODALS
     * CLOSE BUTTON, IT WILL REDIECT TO THE DESIRED PAGE. NOTE THIS FUNCTION MUST RESIDE INSIDE THIS PAGE BECAUSE OF THE STATE SET FUNCTIONS INSIDE IT WHICH BELONG TO THIS CLASS. */
    const ALERT_MODAL_TRIGGER = (MESSAGE, MODAL_TOGGLER, REDIRECT_DEFAULT = "false") => {
        /* Here we use the "alertMessage" state, SET function to collect the message from the parameter and update the "alertMessage" state, which inturns update our modals body
         * through its "alert_message" props. check the "ALERT_MODAL" component to understand more. */
        setAlertMessage(MESSAGE);
        /* Here we use the "alertShowStatus" state, SET function to collect the toggle data, which can either be "true" or "false" from the parameter and update the "alertShowStatus"
         * state, which inturns update our modals components pop up controller through its "alert_modal_toggler" props. check the "ALERT_MODAL" component to understand more. */
        setAlertShowStatus(MODAL_TOGGLER);
        /* Here we use the "allow_redirect" state, SET function to collect the redirect signal data from the parameter and update the "allow_redirect" state, which inturns update 
         * our modals "redirect controller" through its "redirect_request" props. check the "ALERT_MODAL" component to understand more. */
        setAllow_redirect(REDIRECT_DEFAULT);
    }
    
    
    
    
    
    
    
    
    
    // on-page css hacks
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-1.jpg)" };
    const style2 = { marginTop: "5%", marginBottom: "7%"  };
    
    return (
        
        <div>        

            <NAVIGATION_BAR />

            <ALERT_MODAL alert_modal_toggler={showAlert} alert_modal_closer={alertCloserFunction} alert_message={alertMessage} redirect_request={allow_redirect} />
            
            
                <section className="section-border border-primary" style={style2}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="card card-row">
                                <div className="row no-gutters">
                                    <div className="col-12 col-md-6 bg-cover card-img-left" style={style1}>
                                        <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/photos/photo-1.jpg"} alt="..." className="img-fluid d-md-none invisible" />
                                        <div className="shape shape-right shape-fluid-y svg-shim text-white d-none d-md-block">
                                            <svg viewBox="0 0 112 690" fill="none" xmlns=""><path d="M116 0H51V172C76 384 0 517 0 517V690H116V0Z" fill="currentColor"/></svg>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="card-body">
                                            <h2 className="mb-6 font-weight-bold text-center" id="modalSigninHorizontalTitle">Sign In</h2>
                                            <form onSubmit={onSubmit} className="mb-6">
                                                <div className="form-group">
                                                    <label className="sr-only" for="modalSigninHorizontalEmail">Your email</label>
                                                    <input onChange={EMAIL_onChange} type="text" className="form-control" placeholder="Your email" />
                                                </div>
                                                <div className="form-group mb-5">
                                                    <label className="sr-only" for="modalSigninHorizontalPassword">Create a password</label>
                                                    <input onChange={PASSWORD_onChange} type="password" className="form-control" placeholder="Create a password" />
                                                </div>
                                                <button className="btn btn-block btn-primary" type="submit">Sign in</button>
                                            </form>
                                            <p className="mb-0 font-size-sm text-center text-muted">Don't have an account yet? <Link to={configuration.REGISTER+"/default"}>Sign up</Link>.</p>
                                            <p className="mb-0 font-size-sm text-center text-muted"><Link to={configuration.FORGET_PASS}>Forget password</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            

            <FOOTER />
        
        </div>

    );

}