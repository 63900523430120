
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* IMPORT LIBRARY  */
import { CAPITALIZE } from '../../Library/Library.js';





export default function SIDE_BAR(props){
    
    const component_datas = props.component_datas;
    const crash_action = props.crash_action;
    const webinar_action = props.webinar_action;
    const enable_certs_container = props.enable_certs_container;
    
    
    /**/
    //const CRASH_CLICKED_ACTION = () => {       crash_action("CRASH_CLICKED");         }
    
    
    // how to do on script css
    const style1 = { top: "0" };
    
    /*
                    <Link className="list-group-item list-group-item-action d-flex justify-content-between" 
                    id="list-messages-list" data-toggle="list" 
                    to="" role="tab" aria-controls="messages">Archive
                    <span className="badge badge-primary badge-pill">14</span></Link>
     */
    
    return (

        <div className="col-12 col-md-4 position-md-sticky align-items-center vh-md-100" style={style1}>
            <aside className="py-8 py-md-13">
                <div className="alert alert-light py-md-5 shadow-lg mb-2 lift">
                    <h4 className="display-5 font-weight-bold text-center">{CAPITALIZE(component_datas)}</h4>
                </div>
                <div className="list-group shadow-lg lift" id="list-tab" role="tablist">
                    <Link className="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" onClick={webinar_action} to="" role="tab" 
                    aria-controls="home">Snowball Webinar</Link>
                    <Link className="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" onClick={enable_certs_container} to="" role="tab" 
                    aria-controls="profile">Certificates</Link>
                    <Link className="list-group-item list-group-item-action d-flex justify-content-between" 
                    id="list-messages-list" data-toggle="list" onClick={crash_action} to="" role="tab" aria-controls="messages">Archive</Link>
                </div>
            </aside>
        </div>
            
    );

}