
// REACT STANDARD LIBRARY CALLS
import React from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';



/* IMPORT LIBRARY  */
//import { COUNT, EXPLODE } from '../../../Library/Library.js';








export default function VIMEO_VIDEO_TEXT(props){
        
        
    /**/
    //const video_image = props.video_image; 
    //const video_download_link = props.video_download_link;
    const video_description = props.video_description;
    //const lesson_count = props.lesson_count;
    const module_owner_name = props.module_owner_name;
    const module_owner_icon = props.module_owner_icon;
    
    
    
    /* A FUNCTION TO HELP US ALTERNATE THROUGH THE POSSIBLE CONDITIONS OF THE DESCRIPTION LAYER OF THIS COMPONENT. */
    const DESCRIPTION = (description) => {
        /* Here we quickly check if the content to be displayed has description information attached to it or not. */
        if(description){
            /* If the control gets in here it mean that the content to be displayed has description information attached to it, Therefore we create the description layer. */
            return(
                <div className="card-body pt-md-6"><p className="card-text">{description}</p></div>
            );
        }else{
            /* If the control gets in here it mean that the content to be displayed has no description information attached to it, Therefore we do otherchecks to affirm if the screen
             * requesting for this contents is a mobile screen or desktop. This is so we can better serve view.  */
            if(window.innerWidth < 480){
                /* Gontrol geting in here mean that the screen requesting is a mobile phone screeen. */
                return(     <div></div>    );
            }else{
                /* Gontrol geting in here mean that the screen requesting is a desktop screeen. */
                return(     <div className="card-body"></div>    );
            }
        }
    }
        
    
    // on-page css hacks
    const style1 = { textDecoration: "none", color: "#161C2D" };
    //const style2 = { visibility: visibility2 };
    const style3 = { /*position: "relative"*/ };
    const style4 = { /*position: "absolute", top: "0", left: "0", width: "100%", height: "100%"*/ };
    
    return (

        <div className="d-block shadow-lg lift lift-lg mb-8" style={style1}>
            <div className="cont">
                <div className="container cont-video cont" style={style3}>
                    <iframe className="cont" title="Lecture Video" src="https://player.vimeo.com/video/464155754" frameborder="0" allow="autoplay; fullscreen" allowfullscreen 
                    style={style4} ></iframe>
                </div>
            </div>
            <div className="col-12-flex">
                <div className="card cont">
                    {DESCRIPTION(video_description)}
                    <div className="card-meta">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2"><img src={configuration.RESOURCE+"OUTCAST/RESOURCE/uploads/"+module_owner_icon} alt="..." 
                        className="avatar-img rounded-circle" /></div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">{module_owner_name}</h6>
                    </div>
                </div>
            </div>
        </div>
                
    );

}

