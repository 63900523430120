
// REACT STANDARD LIBRARY CALLS
import React from 'react';
/* import react select package.  */
import Select from 'react-select';




export default function DROPDOWN(props){
    
    // Here we declare the "props" that would be used by the parent function to populate this component with dropdown data each time its called.
    const dropdown_datas = props.dropdown_datas;
    // Here we declare the "props" that would be used by the parent function to decide what happens when something is selected from this dropdown using "unchange event".
    const dropdown_unchange = props.dropdown_unchange;
    // Here we declare the "props" that would be used by the parent function to set the default text that will appear when the dropdown is loaded.
    const dropdown_default_text = props.dropdown_default_text;
    
    
    
    
    return (

        <Select defaultValue={{ label: dropdown_default_text, value: "" }} inputProps={{ autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off', name:"moduleFilter" }} 
            name="course-select" onChange={dropdown_unchange} options={dropdown_datas} />
            
    );

}