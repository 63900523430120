
// REACT STANDARD LIBRARY CALLS
import React from 'react';








export default function PAGE_INFO(props){
    
    // set up the props to populate this component with data
    const image_style = props.image_style;
    const head_text = props.head_text;
    const body_text = props.body_text;
    
    
    
    
    return (

        <section className="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover" style={image_style}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">
                        <h1 className="display-2 font-weight-bold text-white">{head_text}</h1>
                        <p className="lead text-white-75 mb-0">{body_text}</p>
                    </div>
                </div>
            </div>
        </section>
            
    );

}