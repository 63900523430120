
// Load our video.js css library.
import '../../../Css/Third_party_css/video-js.min.css';


// REACT STANDARD LIBRARY CALLS
import React, { useEffect } from 'react';


/* WEB APP CONFIG FILE. */
import { configuration } from '../../../Config/Config';












export default function VIDEO_TEXT(props){
        
        
    /**/
    const audio_download_link = props.audio_download_link;
    const audio_description = props.audio_description;
    //const lesson_count = props.lesson_count;
    const module_owner_name = props.module_owner_name;
    const module_owner_icon = props.module_owner_icon;
    
    
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    const LOADER_DATA = () => {
        const script = document.createElement("script");
        script.async = true;
        script.src = configuration.RESOURCE+"OUTCAST/VENDORS/video-js/video.min.js";
        document.head.appendChild(script);
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    useEffect(LOADER_DATA, []);
    
    
    
    
    /* A FUNCTION TO HELP US ALTERNATE THROUGH THE POSSIBLE CONDITIONS OF THE DESCRIPTION LAYER OF THIS COMPONENT. */
    const DESCRIPTION = (description) => {
        /* Here we quickly check if the content to be displayed has description information attached to it or not. */
        if(description){
            /* If the control gets in here it mean that the content to be displayed has description information attached to it, Therefore we create the description layer. */
            return(
                <div className="card-body pt-md-9"><p className="card-text">{description}</p></div>
            );
        }else{
            /* If the control gets in here it mean that the content to be displayed has no description information attached to it, Therefore we do otherchecks to affirm if the screen
             * requesting for this contents is a mobile screen or desktop. This is so we can better serve view.  */
            if(window.innerWidth < 480){
                /* Gontrol geting in here mean that the screen requesting is a mobile phone screeen. */
                return(     <div></div>    );
            }else{
                /* Gontrol geting in here mean that the screen requesting is a desktop screeen. */
                return(     <div className="card-body"></div>    );
            }
        }
    }
        
        
    
    // on-page css hacks
    const style1 = { textDecoration: "none", color: "#161C2D" };
    const style2 = { padding: "0 0 0 0" };
    const style3 = { position: "relative", paddingBottom: "56.25%", height: "0" };
    const style4 = { position: "absolute", top: "0", left: "0", width: "100%", height: "100%" };
    //const style5 = { opacity: "0.4" };
    
    const DOWNLOAD_BUTTON = () =>{
        if(audio_download_link){
            return(
                <div className="card-meta" style={style2}>
                    <a className="btn btn-block btn-lg btn-outline-primary shadow-lg" target='_blank' rel="noopener noreferrer" 
                    href={configuration.RESOURCE+"OUTCAST/RESOURCE/"+audio_download_link}><i className="fe fe-download mr-2"></i>Download Audio</a>
                </div>
            );
        }else{
            return(<div></div>);
        }
    };
    
    return (

        <div className="d-block shadow-lg lift lift-lg mb-8" style={style1}>
            <div className="col-12-flex">
                <div className="card">
                
                    <div className="bg-cover">
                        <div className="container py-md-13" style={style3}>
                            <video id="MY_VIDEO_1" className="video-js vjs-default-skin vjs-big-play-centered" controls preload="none" 
                                poster={configuration.RESOURCE+"OUTCAST/RESOURCE/gifs/player.gif"} data-setup='{"example_option":true, "fluid": true}' style={style4}> 
                                <source src={configuration.RESOURCE+"OUTCAST/RESOURCE/"+audio_download_link} type="video/mp4" />
                                <p className="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video.</p>
                            </video>
                        </div>
                    </div>
                    
                    {DESCRIPTION(audio_description)}
                    
                    <div className="card-meta">
                        <hr className="card-meta-divider" />
                        <div className="avatar avatar-sm mr-2"><img src={configuration.RESOURCE+"OUTCAST/RESOURCE/uploads/"+module_owner_icon} alt="..." 
                        className="avatar-img rounded-circle" /></div>
                        <h6 className="text-uppercase text-muted mr-2 mb-0">{module_owner_name}</h6>
                    </div>
                    
                    {DOWNLOAD_BUTTON()}
                    
                </div>
            </div>
        </div>
                
    );

}

