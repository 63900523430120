
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect, useCallback } from 'react';
//import React from 'react';
import { Link } from "react-router-dom";

/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';


// IMPORT SNOWBALL CARDS COMPONENTS
import IMAGE_TEXT from '../DASHBOARD/LECTURE_CARDS/IMAGE_TEXT';
import TEXT_ONLY from '../DASHBOARD/LECTURE_CARDS/TEXT_ONLY';
import VIMEO_VIDEO_TEXT from '../DASHBOARD/LECTURE_CARDS/VIMEO_VIDEO_TEXT';
import VIDEO_TEXT from '../DASHBOARD/LECTURE_CARDS/VIDEO_TEXT';
import FILE_TEXT from '../DASHBOARD/LECTURE_CARDS/FILE_TEXT';
import AUDIO_TEXT from '../DASHBOARD/LECTURE_CARDS/AUDIO_TEXT';



/* IMPORT LIBRARY  */
import { getCookie, TRUNCATE_STRING, EXPLODE } from '../../Library/Library.js';





/* MAIN SNOWBALL COMPONENT FOR USER ACTIVE COURSES.  */
export default function SNOW_BALL_CONTAINER({switcher_datas, first_content, switched_content, disclaimer, snowballWEBINAR_info, pauseActionSender, previousContentSender, 
    reverserSessionRegistra, reverserSessionData, switcher_data_Collector, ACTING_MODULE_OWNERSHIP_DATAS, certificate_info_exporter, controller, interractive_session_link, 
    interractive_session_info_exporter}){
     
    /* create function to be used to collect the new contents from the child component (PAUSE & SWITCHER), through the "course_data_messanger" props of the child component.  */
    const switchedContentCollector_1 = (new_contents) => {       switched_content(new_contents);         }
    /* create function to be used to collect webinar "top part" message from the child component (PAUSE & SWITCHER), through the "snowballWEBINAR_info_messanger" props of the child 
     * component.  */
    const snowballWEBINAR_infoCollector_1 = (info) => {       snowballWEBINAR_info(info);         }
    /* create function to be used to collect user completed course "certificate" related datas from the child modal component, through the "certificate_data_carrier" props of the 
     * child component.  */
    const certificate_data_carrier_1 = (info) => {       certificate_info_exporter(info);         }
    /* create function to be used to collect interractive session link datas from the child modal component, through the "interractive_session_info_exporter" props of the 
     * child component.  */
    const interractive_session_data_carrier_1 = (info) => {       interractive_session_info_exporter(info);         }
    /* create function to be used to collect snowball container top messages updates from the child component (PAUSE), through the "info_text" props of the child component.  */
    const info_textCollector_1 = (info) => {       setTEXT(info);         }
    /* create function to be used to collect pause action updates from the child component (PAUSE), through the "pause_action" props of the child component.  */
    const pauseActionSender_1 = (info) => {       pauseActionSender(info);         }
    /* create function to be used to collect previouse course lectures from the child component (NAVIGATOR), through the "course_data_messanger" props of the child component.  */
    const previousContentSender_1 = (info) => {       previousContentSender(info);         }
    /* create function to be used to collect previouse REVERSE SESSION DATA from the child component (NAVIGATOR), through the "reverser_session" props of the child component.  */
    const reverserSessionRegistra_1 = (info) => {       reverserSessionRegistra(info);         }
    /* create function to be used to collect snowball container top messages updates from the child component (PAUSE), through the "info_text" props of the child component.  */
    const messageToggle_1 = (info) => {       setmessageToggler(info);         }
    /* create function to be used to collect switcher data updates from the child component (SWITCHER), through the "switcher_data_messanger" props of the child component.  */
    const switcher_data_Collector_1 = (info) => {       switcher_data_Collector(info);         }
    
    
    
    /* states used to control the information text displayed untop of the webinar. */
    const [TEXT, setTEXT] = useState("");
    /* states used to control the visibility of the whole information text container untop of the webinar. */
    const [SNOWBALL_MESSAGE_BOARD_VISIBILITY, setSNOWBALL_MESSAGE_BOARD_VISIBILITY] = useState("block");
    /* states used to control the visibility of the first part of the information text container untop of the webinar. */
    const [messageToggler, setmessageToggler] = useState("block");
    
    
    
    /* A function wrapped inside the "useCallback" to ensure that this operation inside the function happens only once and that it may happen again only if the states it depends on
     * change.  */
    const snowballContainer_info = useCallback(() => {
        /* checking this "first_content" props, we affirm if there is lecture data to be displayed or not. */
        if(first_content){
            /* control in here means there is lecture data to be displayed, therefore we do another check on the "disclaimer.pause_indicator" to affirm if the currently viewing course 
             * is paused or not.  */
            if(disclaimer.pause_indicator === "off"){
                /* control in here means the currently viewing course is not paused, therefore we do another check on the "disclaimer.next_lecture_date" to affirm if there is a next 
                 * date for lecture or not.  */
                if(disclaimer.next_lecture_date !== false){
                    /* control in here means there is a next lecture date, only in this condition do we now go ahead to construct the "next lecture date" information string. */
                    return ", you have "+disclaimer.next_lecture_date+" before your next lecture.";
                }else{
                    /* control in here means there is no next lecture date, therefore we dont construct any string instead we return a "dot" just to end any string that would have 
                     * been created before now. */
                    return ".";
                }
            }else{
                /* control in here means the currently viewing course is paused, therefore we dont construct any string instead we return a "dot" just to end any string that would 
                 * have been created before now. */
                return ".";
            }
        }else{
            /* control in here means there is no lecture data to be displayed, therefore we ensure that the whole webinar information container does not show.  */
            setSNOWBALL_MESSAGE_BOARD_VISIBILITY("none");
        }
    }, [disclaimer.pause_indicator, disclaimer.next_lecture_date, first_content]);
    
    
    /* Here we run our "snowballContainer_info" function through the "useCallback" on the first load of the "SNOW_BALL_CONTAINER" component.  */
    useEffect(() => {
        setTEXT(snowballContainer_info());
    }, [snowballContainer_info]);
    
    
    
    // get the interractive class session link information from parent component state and process. 
    const interractive_session_datas_INARRAY = EXPLODE("#@$", interractive_session_link);
    // how to do on script css
    const style1 = { paddingRight: "1%" };
    const style2 = { paddingLeft: "1%" };
    const style3 = { color: "#fff" };
    let style4 = { display: messageToggler };
    let style5 = { display: SNOWBALL_MESSAGE_BOARD_VISIBILITY };
    let style6 = { display: "block" };
    if(controller !== true){        style6 = { display: "none" };         }
    
    return (

        <div className="col-12 col-md-8" style={style6}>
            <section className="pb-8 pt-md-12 pb-md-10">
            
                <div className="row">
                    <div className="col-6" style={style1}>  <PAUSE disclaimer={disclaimer} info_text={info_textCollector_1} pause_action={pauseActionSender_1}
                    course_data_messanger={switchedContentCollector_1} switcher_data_messanger={switcher_data_Collector_1} 
                    snowballWEBINAR_info_messanger={snowballWEBINAR_infoCollector_1} certificate_data_carrier={certificate_data_carrier_1} /></div>
                    <div className="col-6" style={style2}>
                        <SWITCHER switcher_datas={switcher_datas} course_data_messanger={switchedContentCollector_1} switcher_data_messanger={switcher_data_Collector_1} 
                        snowballWEBINAR_info_messanger={snowballWEBINAR_infoCollector_1} certificate_data_carrier={certificate_data_carrier_1} 
                        interractive_session_info_exporter={interractive_session_data_carrier_1} />
                    </div>
                    <div className="col-12 mt-2" style={style5}>
                        <div className="d-block alert alert-info shadow-lg lift">
                            <p className="font-weight-bold text-mute" style={style4}>You are currently viewing lectures scheduled for {disclaimer.current_lecture_date}{TEXT} To add another 
                            course <Link to={configuration.COURSES} style={style3}><i>click here</i></Link>. Join the interactive session for this 
                            course, {interractive_session_datas_INARRAY[0]} via <a href={interractive_session_datas_INARRAY[1]} target='_blank' rel="noopener noreferrer" style={style3}>{interractive_session_datas_INARRAY[1]}</a></p>
                            <h3><span className="text-light font-weight-bold"><i>course:</i></span> <b>{ disclaimer.first_course_name }</b></h3>
                        </div>
                    </div>
                </div>
                
                <SNOW switcher_datas={switcher_datas} content={first_content} disclaimer={disclaimer} ACTING_MODULE_OWNERSHIP_DATAS={ACTING_MODULE_OWNERSHIP_DATAS} />
                
                <NAVIGATOR disclaimer={disclaimer} course_data_messanger={previousContentSender_1} reverser_session={reverserSessionRegistra_1} offMessage={messageToggle_1} 
                getReverserSessionData={reverserSessionData} content={first_content} />
                
            </section>
        </div>
            
    );

}



    
    

/* HERE WE CREATE THE "NAVIGATOR" COMPONENT, WHICH IS A PART OF THE "SNOWBALL" WEBINAR PARENT COMPONENT, THIS "NAVIGATOR" IS THE COMPONENT TO BE USED TO MOVE BACK AND FORTH THROUGH
 * LECTURES. THIS COMPONENT HAS THE "disclaimer" PROPS, OF WHICH CARRIES SOME BASIC INFORMATION SUCH AS CURRENTLY VIEWING COURSE CODE AND COURSE NAME, NEXT AND CURRENT LECTURE 
 * DATE, PAUSE INDICATORS ETC.., AND IT SOLE PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO GET THE CURRENT VIEWING COURSE CODE. ALSO WE HAVE THE "course_data_messanger" 
 * PROPS, WHICH WOULD BE USED AS A VESSEL TO CARRY COURSE CONTENT DATA THAT WAS NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE FORWARD OR BACKWARDS NAVIGATION BUTTONS. ALSO WE 
 * HAVE "reverser_session" PROP, WHICH IS ALSO USED AS A VESSEL TO CARRY NEWLY FETCHED "REVERSAL DATA OBJECTS" DATAS, THIS DATA ARE HIGHLY NEEDED WHEN EVER A FORWARD OR BACKWARD 
 * ACTION NEEDS TO BE PERFORMED SOME OF THIS DATA ARE CURRENTLY VIEWED MODULE CODE, DAY NUMBER, WHEN FORWARD OR BACKWARD ACTION SHOULD STOP, YOU CAN TRACE THIS CARRIER PROPS TO 
 * ITS SUPER PARENT COMPONENT "DASHBOARD" TO SEE FOR YOURSELF. ALSO WE HAVE "getReverserSessionData" PROP, WHICH HELPS US BRING/CARRY "REVERSAL DATA OBJECTS" DATAS INTO THIS 
 * COMPONENTS. ALSO WE HAVE "offMessage" PROP, WHICH IS ALSO USED AS A VESSEL TO CARRY VISIBILITY COMMAND OF THE "WEBINAR UPPER REGION INFO MESSAGE". LASTLY WE HAVE THE "content" 
 * PROPS, WHICH IS USED TO IMPORT WEBINAR (THAT DAY) CURRENTLY VIEWING LECTURE CONTENT, SO WE CAN USE IT INSIDE THIS COMPONENT TO KNOW IF USER HAVE FINISHED THE CURRENTLY VIEWING
 * COURSE OR NOT. NOTE THAT ALL PROPS OF THIS COMPONENT ARE USED TO IMPORT DATAS FROM THE PARENT COMPONENT INTO THIS COMPONENT AND EXPORT NEWLY FETCHED DATAS FROM THIS COMPONENT 
 * TO THE PARENT COMPONENT. */
let NAVIGATOR = ({disclaimer, course_data_messanger, reverser_session, getReverserSessionData, offMessage, content}) => {

    /* Here we create the function to help us run the operation of fetching all datas required for a successful backward action in any currently viewing course inside our webinar */
    let BACKWARDS_ACTION = () => {
        /* collate data (which is the course code of the selected course) to be send to the server through API, the logged-in user token, the sender info of which in this case is 
         * the "previous lecture button action", this sender info data tells the server how it should fetch data, the module code of the webinar currently viewed course and the
         * number of the currently viewed lecture day. all this collected information are very vital to the successful execution of a proper back dating of lecture contents. */
        let data = { TOKEN: getCookie("member_hash"), COURSE_CODE: disclaimer.course_code, CURRENT_REVERSE_MODULE: getReverserSessionData.module, 
            CURRENT_REVERSE_DAY_NO: getReverserSessionData.day_no, SENDER: "PREVIOUS" };
        /* Here we carry out a test on the "backwards_stopper" object to affirm if we should continue back dating lectures or not, this will show user when they have reached the
         * edge of the whole currently viewing course whereby further lectures back dating wouldn't be possible. */
        if(getReverserSessionData.backwards_stopper !== true){
            /* Control in here signifies that, user haven't reached the end of lectures for the back dating operation so far, therefore in a bid to execute new user request for 
             * another lecture back dating, we call the API responsible for handling this request.  */
            const url = configuration.PREVIOUS_LECTURES_API;
            /* setting the api property to using the "FORM_POST" method, and also set the headers. */
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => response.json()).then((data) => {
                /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
                 * array at this point. We set the newly fetched back dated lecture content array into the "course_data_messanger" carrier/exporting props, to help us carry the 
                 * newly fetched course datas all the way through to the parent component and then to the super parent component.  */
                course_data_messanger(data.contents);
                /* Now that we have successfully backdated the course lecture and we have new backdated lectures its only wise and very important that we update the backward action 
                 * section of the "reversal" registra object with updated information about our new backdating action, not doing this would prevent any further backdating 
                 * operation, therefore we set the "reverser_session" exporting props, with this datas to help the client dashboard mechanism keep track of last lectures backward 
                 * operation statistics. so we get this data from the "data.content_reversal_session" into the "reverser_session" carrier/exporter props, to help us carry this data 
                 * all the way through to the parent component and then to the super parent component (client dashboard mechanism).  */
                reverser_session(data.content_reversal_session);
                /* Here we change the visibility command (to null visibility) of the "WEBINAR UPPER REGION INFO MESSAGE", exporting this new command from this component all the way
                 * through to the parent component and then to the super parent component.  */
                offMessage("none");
            });
        }else{
            /* Control in here signifies that, user have reached the end of lectures for the back dating operation so far, therefore we push that notification to user upon there 
             * next clicking on this backwards action button.  */
            alert("Hi, you have reached the first lecture page for this course.");
        }
    };


    /* Here we create the function to help us run the operation of fetching all datas required for a successful "current lecture fetching" action in any currently viewing course 
     * inside our webinar. */
    let FORWARD_ACTION = () => {
        /* collate data (which is the course code of the selected course) to be send to the server through API, the logged-in user token, the sender info of which in this case is 
         * the "TODAY lecture button action", this sender info data tells the server how it should fetch data. */
        let data = { TOKEN: getCookie("member_hash"), COURSE_CODE: disclaimer.course_code, SENDER: "TODAY" };
        /* Here we carry out a test on the "forwards_stopper" object to affirm if we the one time today lectures fetching operation has already been performed once hereby making any
         * other effort to repeat this operation a total was of processing time, this will show user when they have reached the latest lectures date of the currently viewed course 
         * whereby further hitting of the forward action button wouldn't be needed. */
        if(getReverserSessionData.forwards_stopper !== true){
            /* Control in here signifies that, user haven't requested to view todays lectures of the currently viewed course so far, therefore in a bid to execute new user request 
             * for todays lectures, we call the API responsible for handling this request.  */
            const url = configuration.PREVIOUS_LECTURES_API;
            /* setting the api property to using the "FORM_POST" method, and also set the headers. */
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => response.json()).then((data) => {
                /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
                 * array at this point. We set the newly fetched todays lecture content array into the "course_data_messanger" carrier/exporting props, to help us carry the newly 
                 * fetched course datas all the way through to the parent component and then to the super parent component.  */
                course_data_messanger(data.contents);
                /* Now that we have successfully to todays lecture and we have new todays lectures its only wise and very important that we update the forward action section of the 
                 * "reversal" registra with updated information about our new forwarding action, therefore we set the "reverser_session" exporting props, with this datas to help 
                 * the client dashboard mechanism keep track of last lectures forward operation statistics. so we get this data from the "data.content_reversal_session" into the 
                 * "reverser_session" carrier/exporter props, to help us carry this data all the way through to the parent component and then to the super parent component 
                 * (client dashboard mechanism).  */
                reverser_session(data.content_reversal_session);
                /* Here we change the visibility command (to active visibility) of the "WEBINAR UPPER REGION INFO MESSAGE", exporting this new command from this component all the 
                 * way through to the parent component and then to the super parent component.  */
                offMessage("block");
            });
        }else{
            /* Control in here signifies that, user have already/previously requested to view todays lectures of the currently viewed course, therefore we push that notification to 
             * user upon there next clicking on this forwards action button.  */
            alert("Hi, you have reached the lecture page for today.");
        }
    };


    /* Here we create a state to be used to manage the visibility of the backwards and forward navigation buttons from inside this navigation component, and we dope this that with
     * an initial "active or positivie" visibility command. */
    const [NAVIGATOR_BUTTONS_VISIBILITY, setNAVIGATOR_BUTTONS_VISIBILITY] = useState("block");


    /* Here using the "useEffect" function to do some operations immediately this navigation component is loaded the first time, this "useEffect" function is having "content" 
     * importer props as it dependency, meaning after the first time running of the operations inside this "useEffect" function, only when the value of the "content" importer 
     * props changes would the operations inside this function be allowed to re-run.  */
    useEffect(() => {
        /* Here we just check to see if there are lecture contents to show on the webinar, only if this check is positive do we allow our backwards and forward navigation buttons
         * to be visible, else we make it invisible. */
        if(!content){
            /* control in here means that contents importer props doesn't have data to show as lectures on the webina, in this kind of situations we ensure that the navigation 
             * buttons are not visible, because this further means that there are no data to perform backwards or forward lectures operations on, so we hid our buutons.  */
            setNAVIGATOR_BUTTONS_VISIBILITY("none");
        }
    }, [content]);


    // how to do on script css
    const style1 = { paddingRight: "1%", display: NAVIGATOR_BUTTONS_VISIBILITY };
    return (
        <div className="row mb-3">
            <div className="col-6" style={style1}>  
                <button type="button" onClick={() => BACKWARDS_ACTION()} className="btn btn-block btn-outline-danger shadow btn-lg mb-1 lift">
                <span><i class="fe fe-arrow-left"></i></span>Previous Lectures</button>
            </div>
            <div className="col-6" style={style1}>  
                <button type="button" onClick={() => FORWARD_ACTION()} className="btn btn-block btn-outline-success shadow btn-lg mb-1 lift">
                        <span><i class="fe fe-arrow-right"></i></span>Today Lectures</button>
            </div>
        </div>
    );

};





/* HERE WE CREATE THE "PAUSE" COMPONENT (BUTTON), WHICH IS A PART OF THE "SNOWBALL" WEBINAR PARENT COMPONENT, THIS "PAUSE" IS THE COMPONENT TO BE USED TO HALT THE POSTING OF
 * LECTURES FOR ANY SELECTED COURSE. THIS COMPONENT HAS THE "disclaimer" PROPS, OF WHICH IMPORTS SOME BASIC INFORMATION SUCH AS CURRENTLY VIEWING COURSE CODE AND COURSE NAME, 
 * NEXT AND CURRENT LECTURE  DATE, PAUSE INDICATORS ETC.., AND IT SOLE PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO GET THE CURRENT VIEWING ACTING PAUSE SIGNAL ALONG SIDE
 * OTHER INFORMATION THAT CAN BE COLLECTED FROM THIS PROPS TO MAKE THE OPERATIONS OF THIS COMPONENT A SUCCESS. ALSO WE HAVE THE "course_data_messanger" PROPS, WHICH WOULD BE USED 
 * AS A VESSEL TO EXPORT COURSE CONTENT DATA THAT WAS NEWLY FETCHED AS A RESULT OF PLAYING BACK A PAUSED COURSE. ALSO WE HAVE THE "info_text" PROPS, WHICH WOULD BE USED AS A 
 * VESSEL TO EXPORT THE CONCLUDING PHRASE OF THE MESSAGE IN THE UPPER REGION OF THE WEBINAR INFO CONTAINER. ALSO WE HAVE THE "pause_action" PROPS, WHICH WOULD BE USED AS A VESSEL 
 * TO EXPORT THE "PAUSE OR PLAY COMMAND RESPECTIVELY" WHEN USER CLICKS ON THIS BUTTON. ALSO WE HAVE THE "switcher_data_messanger" PROPS, WHICH IS ALSO USED AS A VESSEL TO CARRY 
 * UPDATED ACTIVE OR UNCOMPLETED COURSE NAMES AND COURSE CODES DATA NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE "PAUSE BUTTON". ALSO WE HAVE "snowballWEBINAR_info_messanger" 
 * PROPS, WHICH IS ALSO USED AS A VESSEL TO EXPORT NEXT LECTURE DATE DATA AND OTHER DATAS, NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE "PAUSE BUTTON". NOTE THAT ALL PROPS OF 
 * THIS COMPONENT ARE USED TO IMPORT DATAS FROM THE PARENT COMPONENT INTO THIS COMPONENT AND EXPORT NEWLY FETCHED DATAS FROM THIS COMPONENT TO THE PARENT COMPONENT.  */
let PAUSE = ({disclaimer, info_text, pause_action, course_data_messanger, switcher_data_messanger, snowballWEBINAR_info_messanger, certificate_data_carrier}) => {

    /* As we know that the pause button is a multi-functional button which controls playing and pausing of lectures, therefore in a bid to be able to keep track of this button 
     * dynamic function (to know what action to carry out when the button is clicked wether play action or pause action), we create this state to be used to keep tabes with the 
     * last function of this button (either pause or play), so we can create codes to decide the next operation of this button when next its clicked (either pause or play) for 
     * the same course. Note that this state has the data inside the "disclaimer.pause_indicator" object of the "disclaimer" importer props as it defaults value, meaning that the
     * current state of our pause button (which can either be "pause indator" or "play indicator") is used to dope this state.  */
    const [PAUSE_CONTROLLER, setPAUSE_CONTROLLER] = useState(disclaimer.pause_indicator);

    /* Right here we use the "useEffect" function to do some operations immediately this pause component is loaded the first time, this "useEffect" function is having 
     * "disclaimer.pause_indicator" data of the "disclaimer" importer props as it dependency, meaning after the first time running of the operations inside this "useEffect" 
     * function, only when the value of the "disclaimer.pause_indicator" data of the "disclaimer" importer props changes would the operations inside this function be allowed 
     * to re-run.  */
    useEffect(() => {
        /* As we know by now that the "disclaimer.pause_indicator" data of the "disclaimer" importer props, holds information which can be used to understand the previous "pause"
         * or "play" state of our button, therefore we run this test to affirm if we have "previous pause button (pause indicator or play indicator) state", if we do then we 
         * repeat the same thing we did when initializing the "PAUSE_CONTROLLER" state with a default value. NOTE THAT THE MAIN REASON FOR THIS OPERATIONS INSIDE THE "useEffect" 
         * FUNCTION IS SO THAT WE CAN ENSURE AND DOUBLE ENSURE THAT ON THIS COMPONENT LOADING THIS PAUSE BUTTON MENTAIN ITS LAST "PAUSE OR PLAY" STATE FOR THE ACTING COURSE.  */
        if (disclaimer.pause_indicator){
            setPAUSE_CONTROLLER(disclaimer.pause_indicator);
        }
    }, [disclaimer.pause_indicator]);


    /* Here we create the function to be used to construct the "concluding phrase of the upper region of message of the webinar info container", using its "indicator" parameter
     * which represent the current pause or play state of the pause button to decide how this "concluding phrase of the upper region of message of the webinar info container"
     * should be.  */
    const snowballContainer_info = (indicator) => {
        /* Here we do another check on the "indicator" to affirm if the currently viewing course is paused or not.  */
        if(indicator === "off"){
            /* control in here means the currently viewing course is not paused, therefore we do another check on the "disclaimer.next_lecture_date" to affirm if there is a next 
             * date for lecture or not.  */
            if(disclaimer.next_lecture_date !== false){
                /* control in here means there is a next lecture date, only in this condition do we now go ahead to construct the "next lecture date" information string. */
                return ", you have "+disclaimer.next_lecture_date+" before your next lecture.";
            }else{
                /* control in here means there is no next lecture date, therefore we dont construct any string instead we return a "dot" just to end any string that would have 
                 * been created before now. */
                return ".";
            }
        }else{
            /* control in here means the currently viewing course is paused, therefore we dont construct any string instead we return a "dot" just to end any string that would 
             * have been created before now. */
            return ".";
        }
    };


    /* Here we create the function to perform the actual pausing and playing of lectures. this function takes in the "TOGGLE" as parameter, which would be "STARTED" string 
     * indicating that user is playing a paused coures or "PAUSED" string indicating that user is pausing a playing course. the data in this "TOGGLE" parameter would be sent 
     * along with other datas to the server so that the server will perform the desired operations based on the string data inside the "TOGGLE" parameter. */
    let PAUSE_TOGGLER = (TOGGLE) => {
        /* collate data (which is the course code of the selected course) to be send to the server through API, the logged-in user token, the "TOGGLE" data which would tell the 
         * server how it should work. */
        const data = { TOKEN: getCookie("member_hash"), COURSE_CODE: disclaimer.course_code, TOGGLE: TOGGLE };
        /* In a bid to execute user request we call the API responsible for handling this request.  */
        const url = configuration.PAUSE_PLAY_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
             * array at this point. There at this point we check to see if we can get a comfirmation from the server response if this operation was a success.  */
            if(data.status === true){
                /* control getting in here signifies that (pausing or playing) operations was a success, therefore we update this components "PAUSE_CONTROLLER" state with the new
                 * development, that is, if before the user clicked the pause button the cause was paused this implies that the "PAUSE_CONTROLLER" state will indicate a paused at
                 * this moment, but after the button click, and success of the operation right inside this clause, there is need to update this state with the new changes, so that
                 * this component can see the changes in state and changes this button components looks. */
                setPAUSE_CONTROLLER(data.indicator);
                /* After updating the "PAUSE_CONTROLLER" state with the latest development with this button change action, we also use this "pause_action" exporter props to export 
                 * this changes to parent component and hence to super parent component. */
                pause_action(data.indicator);
                /* the data inside "data.indicator" which carries this changes on our component button, we pass this data to the "" function so that it helps us use the data to
                 * determine wether to construct some kind of phrase (that will be concated with the messages in the upper region of the webinar) or not, and this text constructed
                 * would now be passed into the "" exporter props to help us export the created string to parent component and hence to super parent component.  */
                info_text(snowballContainer_info(data.indicator));
                /* try to check if user have restarted a "paused" course, because if he has then this indicates that front-end system should get more datas from api.  */
                if(TOGGLE === "STARTED"){
                    /* control getting in here signifies that user have restarted a "paused" course therefore we proceed to getting more contents datas from the supposed api 
                     * response data. Firstly we set the newly fetched todays lecture content array into the "course_data_messanger" exporter props, to help us carry the newly 
                     * fetched course datas all the way through to the parent component and then to the super parent component.  */
                    course_data_messanger(data.contents);
                    /* We set the array content inside "data.courses" into the "switcher_data_messanger" exporter props, to help us carry the newly fetched course names and course 
                     * codes data of all active or uncompleted courses, all the way through to the parent component and then to the super parent component.  */
                    switcher_data_messanger(data.courses);
                    /* We set the array content inside "data.current_and_next_lecture_date" into the "snowballWEBINAR_info_messanger" exporter props, to help us carry the newly 
                     * fetched NEXT LECTURE DATE DATA AND OTHER DATAS all the way through to the parent component and then to the super parent component.  */
                    snowballWEBINAR_info_messanger(data.current_and_next_lecture_date);
                    /* Here we extract fom the response data, an update of all the completed course, course name and certificate id, and then we use the "certificate_data_carrier" 
                     * exporter props to move this data to parent component.   */
                    certificate_data_carrier(data.completed);
                }
            }else{
                /* control getting in here signifies that (pausing or playing) operations was not a success, therefore we update this components "PAUSE_CONTROLLER" state with the 
                 * a "null" string. */
                setPAUSE_CONTROLLER("null");
            }
        });
    };


    /* Now after all operations above its now time to decide which design and kind of button user would see on their webinar for a particular course. we run a test on the
     * "PAUSE_CONTROLLER" state, to affirm if we should show a button specially designed for courses that have been paused already or played.  */
    if(PAUSE_CONTROLLER === "off"){
        /* control getting in here signifies that the currently viewed course on the webinar is not paused, therefore we load up a specilly designed butten to depict to user that 
         * the currently viewed course is not paused and can be paused if this button component is clicked, in other words the words that will be on this button would be 
         * "Pause Lectures".  */
        return (
            <button onClick={() => PAUSE_TOGGLER("PAUSED")} type="button" className="btn btn-block btn-primary-soft shadow btn-lg mb-1 lift"><span><i class="fe fe-pause"></i></span>Pause Lectures</button>   
        );
    }else if(PAUSE_CONTROLLER === "on"){
        /* control getting in here signifies that the currently viewed course on the webinar is paused, therefore we load up a specilly designed butten to depict to user that 
         * the currently viewed course is paused and can be played if this button component is clicked, in other words the words that will be on this button would be 
         * "Play Lectures".  */
        return (
            <button onClick={() => PAUSE_TOGGLER("STARTED")} type="button" className="btn btn-block btn-outline-warning shadow btn-lg mb-1 lift"><span><i class="fe fe-play"></i></span>Play Lectures</button> 
        );
    }else{
        /* control getting in here signifies that their is no indicator for the currently viewed cause, technically this is a problem for the system adminto look into incase it 
         * arises, but in order to keep things safe and calm if this moment ever happens is to display a disabled button showing "Pause Lectures".  */
        return (
            <button disabled type="button" className="btn btn-block btn-primary-soft shadow btn-lg mb-1 lift"><span><i class="fe fe-pause"></i></span>Pause Lectures</button>
        );    
    }
};














/* HERE WE CREATE THE "SNOW" COMPONENT, WHICH IS A PART OF THE "SNOWBALL" WEBINAR PARENT COMPONENT, THIS "SNOW" IS THE COMPONENT TO BE USED TO DISPLAY ALL LECTURE CONTENTS AS
 * REGARDS TO THE ACTING SELECTED COURSE. THIS COMPONENT HAS THE "switcher_datas" PROPS, OF WHICH IMPORT INFORMATION OF ALL COURSES FOR THIS USER ACCOUNT AND IT SOLE PURPOSE IS 
 * SO WE CAN USE IT INSIDE THIS COMPONENT TO CONFIRM IF USER HAVE ACTIVE COURSES OR NOT, ALSO WE HAVE THE "content" IMPORTER PROPS, WHICH CARRIES ALL THE COURSE DATA FOR THE 
 * CURRENTLY VIEWED COURSE, ALSO WE HAVE "ACTING_MODULE_OWNERSHIP_DATAS" IMPORTER PROP, WHICH CARRIES IMPORTANT IDENTITY INFORMATIONS ABOUT THE AUTHOR OF THE ACTING MODULE. ALSO 
 * WE HAVE THE "disclaimer" PROPS, OF WHICH IMPORTS SOME BASIC INFORMATION SUCH AS CURRENTLY VIEWING COURSE CODE AND COURSE NAME, NEXT AND CURRENT LECTURE  DATE, PAUSE INDICATORS 
 * ETC.., AND IT SOLE PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO GET THE NAME OF THE CURRENTLY VIEWED COURSE THATS IN A CASE WHEREBY THE COURSE HAS BEEN COMPLETED AND
 * WE NEED THE NAME OF THE CURRENTLY VIEWED COURSE TO CONSTRUCT A "COURSE SUCCESSFUL COMPLETION" MESSAGE. PLEASE NOTE THAT ALL PROPS OF THIS COMPONENT ARE USED TO COLLECT DATAS 
 * FROM THE PARENT COMPONENT. */
const SNOW = ({switcher_datas, content, disclaimer, ACTING_MODULE_OWNERSHIP_DATAS}) => {
    /* Here we shall be using the "useEffect" function to ensure that the page is automatically scrolled to the top on routing into this components parent component.  */
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    /* here we check to see if there is no data inside the "switcher_datas" props array, as we know that this prop array carries the datas of users active courses, so if this props 
     * array is empty this only signifies that user doesn't have any active course. */
    if(!switcher_datas){
        return (
            <div className="d-block shadow-lg lift mb-8">
                <div className="col-12-flex">
                    <div className="card alert alert-light shadow-light-lg">
                        <div className="card-body my-auto">
                        <p className="mb-0 font-weight-bold text-mute">Hi, at the moment you do not have an active course, <Link to={configuration.COURSES}>click here</Link> to 
                                choose a new course to undertake.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        /* Control getting in here only signifies that user have active courses, therefore in order to prevent any sort of error we go further to test the "content" props array 
         * which is suppose to be the one carrying the main active course content, we check if this props array is not empty.  */
        if(content){
            /* Control getting in here only signifies that the "content" props array isn't empty, Note that at a particular time on user dashboard currently viewing a course on 
             * their webinar and a course that is not a crash course, note that such course will only show a module at a particular time. in other words an active course can only 
             * show content of a module at any lecture time, therefore we will extract the auther name and icon location datas from the "ACTING_MODULE_OWNERSHIP_DATAS" importer 
             * props, blasting it data's into array, of which it first index will carry module owner name and second index will carry icon location link.  */
            const module_owner_data = EXPLODE("##", ACTING_MODULE_OWNERSHIP_DATAS);
            /* Here we begin the creation of the contents that would be embedded in this "SNOW" component/container, the creation of the display contents for this componebt starts 
             * here. */
            return (
                /* Since we know for sure at this point we have contents to process and create displays for, we now map through all this content in a bid to purge out its datas and 
                 * process then use them. Therefore we create the loop we going to use to perform this operations.  */
                content.map((data, index) => {
                    /* Each loop cycle represent one lecture data, and since this component is made to display all course contents scheduled to be seen by user in the current date,
                     * its important to note that this loop would run for quite some time. Since we have different kind of lecture data types ranging from video to file to text 
                     * etc. Therefore at this junction we run a test to affirm which lecture data type is our current loop cycle purging out datas for, after detecting the lecture 
                     * data type, we now load the appropriate component to harness the datas associated with this lectures data type.*/
                    if(data.content_type === "vimeo-video"){
                        return (
                            <VIMEO_VIDEO_TEXT key={index.toString()} video_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "text"){
                        return (
                            <TEXT_ONLY key={index.toString()} text_heading={data.content_img_video_aud_text} description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "file"){
                        return (
                            <FILE_TEXT key={index.toString()} file_front_image={data.content_img_video_aud_text} file_download_link={data.content_download_link} 
                                    file_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                    module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "audio"){
                        return (
                            <AUDIO_TEXT key={index.toString()} audio_download_link={data.content_download_link} audio_description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "image"){
                        return (
                            <IMAGE_TEXT key={index.toString()} image_links={data.content_img_video_aud_text} image_description={data.content_img_aud_video_description} 
                                    module_owner_name={module_owner_data[0]} module_owner_icon={module_owner_data[1]} />
                        );
                    }else if(data.content_type === "video"){
                        return (
                            <VIDEO_TEXT key={index.toString()} video_image={data.content_img_video_aud_text} video_download_link={data.content_download_link} 
                                    video_description={data.content_img_aud_video_description} module_owner_name={module_owner_data[0]} 
                                    module_owner_icon={module_owner_data[1]} />
                        );
                    }else{
                        return null;
                    }
                })
            );
        }else{
            /* Control getting in here only signifies that the "content" importer props array is empty, despite the fact that the "switcher_datas" props array is not empty, this 
             * occation is not predicted to occure but incase it does this signifies a problem in the system, therefore we prepare the appropriate message telling client what 
             * next to do.  */
            return (
                <div className="d-block shadow-lg lift mb-8">
                    <div className="col-12-flex">
                        <div className="card alert alert-light shadow-light-lg">
                            <div className="card-body my-auto">
                                <p className="mb-0 font-weight-bold text-mute">Congratulation you have completed "{disclaimer.first_course_name}" course, your certificate is 
                                ready for download. <Link to={configuration.COURSES}>Click here</Link> to choose a new course to undertake. </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};










/* HERE WE CREATE THE "SWITCHER" COMPONENT, WHICH IS A PART OF THE "SNOWBALL" WEBINAR PARENT COMPONENT, THIS "SWITCHER" IS THE COMPONENT TO BE USED TO DISPLAY ALL USER ACTIVE OR 
 * UNCOMPLETED COURSES ON A UNIQUE WEBINAR DROPDOWN CALLED "THE COURSE SWITCHER". THIS COMPONENT HAS THE "switcher_datas" PROPS, OF WHICH IMPORTS INFORMATION OF ALL ACTIVE COURSES 
 * FOR THIS USER ACCOUNT AND IT SOLE PURPOSE IS SO WE CAN USE IT INSIDE THIS COMPONENT TO PROCESS DISPLAY SHOWING THROUGH A DROPDOWN ALL OF ACTIVE COURSES, ALSO WE HAVE THE 
 * "course_data_messanger" EXPORTER PROPS, WHICH WOULD BE USED AS A VESSEL TO CARRY COURSE CONTENT DATA THAT WAS NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE SWITCHER DROPDOWN, 
 * ALSO WE HAVE "switcher_data_messanger" EXPORTER PROP, WHICH IS ALSO USED AS A VESSEL TO CARRY UPDATED ACTIVE COURSE NAME AND COURSE CODE DATA NEWLY FETCHED AS A RESULT OF 
 * INTERACTION WITH THE SWITCHER DROPDOWN, ALSO WE HAVE "snowballWEBINAR_info_messanger" EXPORTER PROPS, WHICH IS ALSO USED AS A VESSEL TO TRANSPORT NEXT LECTURE DATE DATA AND OTHER 
 * DATAS, NEWLY FETCHED AS A RESULT OF INTERACTION WITH THE SWITCHER DROPDOWN, NOTE THAT ANY TIME THE SWITCHER DROPDOWN IS USED THIS COMPONENT WOULD HAVE TO RE-REQUEST FOR CERTAIN 
 * DATAS SO THEY CAN BE UPDATED ON CLIENT FRONT-END VIEW. PLEASE NOTE THAT ONLY THE "switcher_datas" PROPS OF THIS COMPONENT ARE USED TO IMPORT DATAS FROM THE PARENT COMPONENT, OTHER 
 * PROPS SUCH AS "course_data_messanger", "switcher_data_messanger" AND "snowballWEBINAR_info_messanger" ARE USED AS EXPORTERS, FOR TRANSPORTING DATAS FROM THIS FUNCTION TO ITS 
 * PARENT COMPONENT, AND THEN MAYBE TO ITS SUPER PARENT COMPONENT (DASHBOARD.JS).  */
let SWITCHER = ({switcher_datas, course_data_messanger, switcher_data_messanger, snowballWEBINAR_info_messanger, certificate_data_carrier, interractive_session_info_exporter}) => {
    /* Here we have a function that will run anytime the switcher dropdown menu item is clicked, this function simply collects the "course code" data of the switcher selected course 
     * and uses this code to fetch new contents and webinar related contents from the api response. this function doesn't just fetch this data it also uses the carrier props of this
     * component to update the super parent states of the new change. */
    const Switch = (courseCode) => {
        /* collate data (which is the course code of the selected course) to be send to the server through API, the logged-in user token and sender info of which in this case is the
         * live course viewer component, this sender info data tells the server how it should fetch data.   */
        const data = { TOKEN: getCookie("member_hash"), COURSE_CODE: courseCode, SENDER: "LIVE_COURSE" };
        /* In a bid to execute user request we call the API responsible for handling this request.  */
        const url = configuration.COURSE_SWITCHER_API;
        /* setting the api property to using the "FORM_POST" method, and also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* Now we collect our "JSON" data that has already been converted into an array, data of which the data is a nested json file that will eventually be converted to
             * array at this point. we set this array into the "course_data_messanger" carrier props, to help us carry the newly fetched course datas all the way through to the 
             * parent component and then to the super parent component (dashboard).  */
            course_data_messanger(data.contents);
            /* We set the array content inside "data.courses" into the "switcher_data_messanger" carrier props, to help us carry the newly fetched course name and course code data 
             * all the way through to the parent component and then to the super parent component.  */
            switcher_data_messanger(data.courses);
            /* We set the array content inside "data.current_and_next_lecture_date" into the "snowballWEBINAR_info_messanger" carrier props, to help us carry the newly fetched NEXT 
             * LECTURE DATE DATA AND OTHER DATAS all the way through to the parent component and then to the super parent component.  */
            snowballWEBINAR_info_messanger(data.current_and_next_lecture_date);
            /* Here we extract fom the response data, an update of all the completed course, course name and certificate id, and then we use the "certificate_data_carrier" 
             * exporter props to move this data to parent component.   */
            certificate_data_carrier(data.completed);
            /* Here we extract fom the response data, the currently viewing course interractive session informations here, and we export it, in a bid to update super parent component state.  */
            interractive_session_info_exporter(data.interactive_session_info);
        });
    };


    /* here we check to see if there is no data inside the "switcher_datas" props array, as we know that this prop array carries the datas of users active courses, so if this props 
     * array is empty this only signifies that user doesn't have any active course. */
    if(switcher_datas){
        /* Control getting in here only signifies that the "switcher_datas" props array is not empty, therefore we carry out another test to affirm if we have more than one active 
         * courses or not.  */
        if(switcher_datas.length > 1){
            /* Control getting in here only signifies that we have more than one active courses, therefore we start the process of creating the switcher button dropdown to demonstrate 
             * on user UI that there are more than one crash course and that user can use the switcher to navigate between all active courses.  */
            const style = { zIndex: "9999"};
            return (
                <div className="dropdown mr-1 mb-1">
                    <button className="btn btn-block btn-outline-success lift dropdown-toggle" type="button" id="dropdownMenuButtonTwo" data-toggle="dropdown" 
                            aria-haspopup="true" aria-expanded="false">Switch course</button>
                    <div className="container-fluid dropdown-menu" aria-labelledby="dropdownMenuButtonTwo" style={style}>
                        {switcher_datas.map((infos, index) => {
                            return (
                                <Link onClick={() => Switch(infos.course_code)} key={index.toString()} className="d-flex justify-content-between dropdown-item active" to="#">
                                    {TRUNCATE_STRING(infos.course_name, "30", "..")}</Link>
                            );
                        })}
                    </div>
                </div>
            );
        }else{
            /* Control getting in here only signifies that we have just one active course, therefore we start the process of creating a DISABLED switcher button WITHOUT A DROPDOWN 
             * demonstrating to user that there is only one active course and that user can NOT use the switcher to navigate between courses, since we have just one active course. */
            return (
                <div className="dropdown mr-1 mb-1">
                    <button className="btn btn-block btn-primary" disabled type="button" id="dropdownMenuButtonTwo">Switch course</button>
                </div>
            );
        }
    }else{
        /* Control getting in here only signifies that the "switcher_datas" props array is empty, so we still go ahead to create a DISABLED NON DROPDOWN SWITCHER BUTTON. */
        return (
            <div className="dropdown mr-1 mb-1">
                <button className="btn btn-block btn-primary" disabled type="button" id="dropdownMenuButtonTwo">Switch course</button>
            </div>
        );
    }
};