
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';








export default function SLIDER(){
    
    
    // on-page css hacks
    const style1 = { marginTop: "1%" };
    const style2 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/other_images/cover.jpg)" };
    
    return (

        <div style={style1}>
        
        
            <section data-jarallax data-speed=".8" className="pt-10 pt-md-14 pb-12 pb-md-15 overlay overlay-primary overlay-60 jarallax" style={style2}>
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                        <h1 className="display-2 font-weight-bold text-white">Education made easy and affordable</h1>
                        <p className="lead text-white mb-8">
                        Bring your dreams to life, learn from industry experts and build your career, learn on your schedule and at your pace. Let us begin your skill acquisition 
                        process with you, all you have to do is select a course to undertake and our professionals will meet with you at the other end, Start a program now!
                        </p>
                        <Link to={configuration.COURSES} className="btn btn-pill btn-white shadow lift"><i className="fe fe-eye mr-2"></i>Start a Course</Link>
                    </div>
                  </div>
                </div>
                <div className="position-absolute right-0 bottom-0 left-0">
                    <div className="position-relative shape shape-bottom shape-fluid-x svg-shim text-white">
                        <svg viewBox="0 0 2880 250" fill="none" xmlns=""><path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"/></svg>
                    </div>
                    <div className="position-absolute center">
                        <Link className="btn btn-light btn-rounded-circle lift" data-toggle="smooth-scroll" to={configuration.COURSES}><i className="fe fe-arrow-down"></i></Link>
                    </div>
                </div>
            </section>
            
            
            
        </div>
    );

}