
// REACT STANDARD LIBRARY CALLS
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
    



// IMPORT COMPONENTS
import DROPDOWN from '../Components/DROPDOWN';
import PAY_MODAL from '../Components/MODALS/PAY_MODAL';
import ALERT_MODAL from '../Components/MODALS/ALERT_MODAL';



/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';



/* IMPORT LIBRARY  */
import { OBJECT_TO_ARRAY, COUNT } from '../Library/Library.js';
import { REGISTRATION_DATA_AUTHENTICATOR } from '../Library/REG_LIBRARY.js';



    








export default function Register() {
    
    /* we set the page title.  */
    document.getElementById("title").innerHTML = "Register | "+configuration.COMPANY_NAME;
    /* collate data (which is the course code of the selected course) to be send to the server through API.  */
    const {courseCodeForEnrolledCourse} = useParams();
    
    
    
    
    /*     P A Y S T A C K     P A Y M E N T     M O D A L    S T A T E S         */
    /* create state to be used to control medal window from parent component. */
    const [show, setShow] = useState(false);
    /* create state to be used to set the payment modal display datas and payment gateway related datas. */
    const [paystack_Modal_Data, setPaystack_Modal_Data] = useState([]);
    /* create state to be used to set the product purchased summary datas to be displayed by modal window. */
    const [productSummary_Data, setProductSummary_Data] = useState("");
    /* create function to be used to collect "window close request" from the "PAY_MODAL" component, through the "closer" props of the "PAY_MODAL" component.  */
    const closerFunction = (close_data) => {       setShow(close_data);         }
    
    
    
    
    /*     A L E R T    M O D A L    S T A T E S       */
    /* create state to be used to control medal window from parent component. */
    const [showAlert, setAlertShowStatus] = useState(false);
    /* create state to be used to set the modal window display message. */
    const [alertMessage, setAlertMessage] = useState("");
    /* create state to be used to control if the modal window should redirect after its close button is clicked or not. */
    const [allow_redirect, setAllow_redirect] = useState("false");
    /* create function to be used to collect "window close request" from the child modal component, through the "closer" props of the child modal component.  */
    const alertCloserFunction = (alert_CloseData) => {       setAlertShowStatus(alert_CloseData);         }
    
    
    
    /*     P A G E      F O R M    S T A T E S        A N D       U N C H A N G E D       F U N C T I O N S        */
    /* state to be used to store all courses and their respective course codes as fetched from the database. */
    const [COURSES, setCOURSES] = useState("");
    /* BELOW WE CREATE ALL OUR FORM DATA COLLECTOR STATES. */
    const [SELECTED_COURSES, setSELECTED_COURSES] = useState(courseCodeForEnrolledCourse);
    const [FIRST_NAME, setFIRST_NAME] = useState("");
    const [LAST_NAME, setLAST_NAME] = useState("");
    const [EMAIL, setEMAIL] = useState("");
    const [PHONE, setPHONE] = useState("");
    const [SEX, setSEX] = useState("");
    const [AGE_RANGE, setAGE_RANGE] = useState("");
    const [PASSWORD, setPASSWORD] = useState("");
    const [RETYPE_PASSWORD, setRETYPE_PASSWORD] = useState("");
    /* BELOW WE CREATE ALL THE EVENT LISTENERS (FUNCTIONS) TO HELP US COLLECT DATA FROM OUR FORMS AND SEND THIS DATAS TO THEIR RESPECTIVE STATE. */
    const FIRST_NAME_onChange = (event) => {       setFIRST_NAME(event.target.value);       };
    const LAST_NAME_onChange = (event) => {       setLAST_NAME(event.target.value);       };
    const EMAIL_onChange = (event) => {       setEMAIL(event.target.value);       };
    const PHONE_onChange = (event) => {       setPHONE(event.target.value);       };
    const SEX_onChange = (event) => {       setSEX(event.target.value);       };
    const AGE_RANGE_onChange = (event) => {       setAGE_RANGE(event.target.value);       };
    const PASSWORD_onChange = (event) => {       setPASSWORD(event.target.value);       };
    const RETYPE_PASSWORD_onChange = (event) => {       setRETYPE_PASSWORD(event.target.value);       };
    /* EVENT LISTENER FUNCTION TO HELP US DETECT WHEN ANYTHING HAS BEEN SELECTED FROM THE COURSE DROPDOWN, AND TO UPDATE THAT CHANGE ON OUR "SELECTED_COURSES" STATE.  */
    const SELECTED_COURSES_onChange = (event) => {       setSELECTED_COURSES(event.value);       };
    
    
    
    
    
    
    /*        P A G E      O N L O A D     F U N C T I O N        */
    /* This function defins what should be done when this components finished loading, as this function would be called directly from this components "ONLOAD" FUNCTION.  */
    const LOADER_DATA = () => {
        /* collect the course code from the URL. */
        const data = { COURSE_CODE: courseCodeForEnrolledCourse }
        /* call the api function responsible for fetching courses. */
        const url = configuration.COURSE_LOADER;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((data) => {
            /* here we create a new empty array to be used to store all course as they are fetched from the API per this "foresch" loop cycle. */
            let options = [];
            /* here we use the "response" object collected from the API to control a "foresch" loop, which will in turn be used to help us create our course list html. */
            data.forEach(function(doc){
                /* each list html is created here and assigned to our "options" array.  */
                options.push({
                    value: doc.course_code,
                    label: doc.course_name
                });
            });
            /* Here we check to see if API result is empty or not. if it is empty this only signifies that the selected course is not yet published. */
            if(COUNT(options) === 0){ 
                /* We use the modal to display the error message upon the loading of this page, and then redirecting user to the courses page to pick other from course.   */
                ALERT_MODAL_TRIGGER("Apologies, the selected course is currently under review and pending approval. This course would be available soon, you can check back "
                        +"or browse through our list of courses.", true, configuration.COURSES);
            }
            /* at this junction that we are certain to have all our course list hmtl inside the "options" array, we now ship this array data into our "COURSES" state.  */
            setCOURSES(options);
        })
    }
    
    /* HERE IS THE ONLOAD FUNCTION OF THIS COMPONENT, AND ACCORDING TO REACT HOOK SYSTEM ITS NORMALLY CALLED "useEffect" FUNCTION, THEY ARE ALWAYS CALLED WHEN THE COMPONENTS FINISH 
     * RENDERING THE FIRST TIME. SO WE ENTER THE "LOADER_DATA" FUNCTION AS IT FIRST PARAMETER MEANING THIS "LOADER_DATA" FUNCTION WOULD BE CALLED BY THE "useEffect" FUNCTION, ONCE 
     * THE COMPONENTS LOADS. */
    useEffect(LOADER_DATA, []);
    
    
    
    
    
    
    
    /*       P A G E       U N S U B M I T      F U N C T I O N       */
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        /* Here we launch the "REGISTRATION_DATA_AUTHENTICATOR" function to help us validate the authenticity of all the datas user has provided. */
        const AUTHENTICATOR_DATA = REGISTRATION_DATA_AUTHENTICATOR({ SELECTED_COURSES: SELECTED_COURSES, FIRST_NAME: FIRST_NAME, LAST_NAME: LAST_NAME, EMAIL: EMAIL, PHONE: PHONE, 
            SEX: SEX, AGE_RANGE: AGE_RANGE, PASSWORD: PASSWORD, RETYPE_PASSWORD: RETYPE_PASSWORD });
        /* Before any major registration operations can be permitted to run, firstly the "REGISTRATION_DATA_AUTHENTICATOR" function must first give clear indications that the data
         * provided by user is good. So at this junction we test to see if we have clearance to register this user.  */
        if(AUTHENTICATOR_DATA === true){
            /* IF CONTROLE GETS IN HERE IT ONLY SIGNIFIES THAT WE HAVE CLEARANCE FROM THE "REGISTRATION_DATA_AUTHENTICATOR" FUNCTION TO INSTALL OR REGISTER THIS NEW USER. */
            /* collect the course code from the "selected course" form inputes. Also collect user email for duplicate email verification. */
            const data = { SELECTED_COURSES: SELECTED_COURSES, EMAIL: EMAIL, PHONE_NUMBER: PHONE, OPERATION_TYPE: "NEW USER" }
            /* call the api function responsible for handling DUPLICATE EMAIL CHECKS, AND COURSE RELATED DATA FETCHING. THIS API IS MULTI-FUNCTIONAL. CHECK IT DOCUMENTATION 
             * TO UNDERSTAND MORE. */
            const url = configuration.REG_AUTH_API;
            /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
            fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
            .then(response => response.json()).then((data) => {
                    /* Here we run a quick check to affirm if we have the final clearance to register this new user, that is if this new user's email and phone number is unique to 
                     * our database, and if the course related datas were fetched. the outcome of this test would determine how the data returned from the server would be collected. */
                    if(data.status === true){
                            /* control inside here means we have full clearance to register this new user, now the only thing in the way is checking if the course applied for is paid or free
                             * therefore, from the course data return from the API used in checking for duplicate email and phone number, we test to see if the selected course has been marked
                             * as free or paid. */
                            if(data.free_course === "OFF"){
                                /* if control enter inside here it means that the selected course is not free rather its a paid course, and since we have selected course related datas 
                                 * required to formaly begin the payment process, we therefore proceed to creating the payment summary data, that we will in turn display in a modal body. */
                                let summaryData = () => {
                                    return (
                                        <div>
                                            <div className="row"><div className="col-12 col-md-4">Course:</div><div className="col-12 col-md-8">{data.course_name}</div></div>
                                            <div className="row"><div className="col-12 col-md-4">Modules:</div><div className="col-12 col-md-8">{data.module_no}</div></div>
                                            <div className="row"><div className="col-12 col-md-4">Videos:</div><div className="col-12 col-md-8">{data.videos_count}</div></div>
                                            <div className="row"><div className="col-12 col-md-4">Weeks:</div><div className="col-12 col-md-8">{data.weeks_count}</div></div>
                                            <div className="row"><div className="col-12 col-md-4">Total price:</div><div className="col-12 col-md-8"><b>NGN {data.course_price}</b></div></div>
                                        </div>    
                                    );
                                }
                                /* after preparing the payment summary datas, we now collect payment modal windows datas along side with vital payment datas, required for the successful 
                                 * operation of our payment modal component. */
                                let payment_info = { header_title: 'Course Summary', email: EMAIL, amount: data.modulated_course_price, publicKey: configuration.KEY, 
                                    button_text: 'Make Payment', onSuccess: (tranRef) => {REGISTER_MEMBER_handler(tranRef)} };
                                /* load the function to pass all payment datas to our payment modal and also trigger it on, so course payment can be done by registering member. */
                                PAYMENT_MODAL_TRIGGER(summaryData, payment_info, true);
                            }else{
                                /* function to fetch all form datas from state variables and perform the actual member registering. */
                                REGISTER_MEMBER();
                            }
                    }else{
                        /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                         * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT WILL NOT REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                         * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. WE USE THE MODAL TO DISPLAY SERVER ERROR MESSAGE, THATS IF EITHER PHONE OR EMAIL IS NOT 
                         * UNIQUE */
                        ALERT_MODAL_TRIGGER(data.message, true);
                    }
            });
        }else{
            /* IF CONTROLE GETS IN HERE IT ONLY SIGNIFIES THAT WE HAVE CLEARANCE FROM THE "REGISTRATION_DATA_AUTHENTICATOR" FUNCTION TO INSTALL OR REGISTER THIS NEW USER. THEREFORE
             * WE CALL THIS MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
             * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT WILL NOT REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON IS CLICKED. 
             * CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. WE USE THE MODAL TO DISPLAY ERROR MESSAGE, INCASE THERE IS PROBLEM WITH SUPPLIED FORM DATAS. */
            ALERT_MODAL_TRIGGER(AUTHENTICATOR_DATA, true);
        }
    }
    
    
    
    
    
    
    
    /* FUNCTION TO HELP US COLLECT ALL NECESSARY FORM DATAS, TRIM THEM AND CALL THE API AND SEND DATAS TO OUR SERVER THROUGH THE API. BEFORE THIS FUNCTION WOULD BE INVOKED ALL
     * FORM DATA VALIDATION PROCESSES WOULD HAVE COMPLETED AND PASSED THIS FORM DATA FIT TO BE TRANSMITTED TO THE SERVER.  */
    const REGISTER_MEMBER = (transaction_reference = "") => {
        /* collect all form inputes from their respective state variables.  */
        const data = { SELECTED_COURSES: SELECTED_COURSES, FIRST_NAME: FIRST_NAME, LAST_NAME: LAST_NAME, EMAIL: EMAIL, PHONE: PHONE, 
            SEX: SEX, AGE_RANGE: AGE_RANGE, PASSWORD: PASSWORD, RETYPE_PASSWORD: RETYPE_PASSWORD, OPERATION_TYPE: "REGISTER USER", TRANSACTION_REFERENCE: transaction_reference  }
        /* call the api function responsible for handling the Registering job. */
        const url = configuration.USER_REG_API;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((response) => {
            /* Here we check if registration transaction completed well or maybe there where errors. the outcome of this test would determine how the data returned from the server
             * would be collected. */
            if(response.status === true){
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT CAN REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE REGISTRATION WAS A SUCCESS.  */
                ALERT_MODAL_TRIGGER(response.message, true, configuration.LOGIN);
            }else{
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT WILL NOT REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE REGISTRATION FAILED. */
                ALERT_MODAL_TRIGGER(response.message, true);
            }
        });
    }
    
    
    
    
    
    /* function to ensure that the payment modal is down before registration of new member comences. */
    const REGISTER_MEMBER_handler = (tranRef) => {
        /*  alert(tranRef.reference);       console.log(Object.values(tranRef)[0]);   */
        /* take down payment modal. */
        setShow(false);
        /* register new member. */
        REGISTER_MEMBER(tranRef.reference);
    }
    
    
    
    
    
    
    /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON CLICKING ITS CLOSE
     * BUTTON OR NOT. THE FIRST PARAMETER IS THE MESSAGE TO BE DISPLAYED, THE SECOND IS THE TOGGLER WHICT TELLS OUR MODAL TO POP UP OR POP DOWN LOL, WHILE THE THIRD PARAMETER IS
     * OPTIONAL, IF ITS NOT DOPPED AND LEFT ALONE THIS MODAL WILL NOT REDIRECT WHEN ITS CLOSE BUTTON IS CLICKED BUT IF ITS DOPPED WITH A ROUTE THEN UPON CLICKING OF THIS MODALS
     * CLOSE BUTTON, IT WILL REDIECT TO THE DESIRED PAGE. NOTE THIS FUNCTION MUST RESIDE INSIDE THIS PAGE BECAUSE OF THE STATE SET FUNCTIONS INSIDE IT WHICH BELONG TO THIS CLASS. */
    const ALERT_MODAL_TRIGGER = (MESSAGE, MODAL_TOGGLER, REDIRECT_DEFAULT = "false") => {
        /* Here we use the "alertMessage" state, SET function to collect the message from the parameter and update the "alertMessage" state, which inturns update our modals body
         * through its "alert_message" props. check the "ALERT_MODAL" component to understand more. */
        setAlertMessage(MESSAGE);
        /* Here we use the "alertShowStatus" state, SET function to collect the toggle data, which can either be "true" or "false" from the parameter and update the "alertShowStatus"
         * state, which inturns update our modals components pop up controller through its "alert_modal_toggler" props. check the "ALERT_MODAL" component to understand more. */
        setAlertShowStatus(MODAL_TOGGLER);
        /* Here we use the "allow_redirect" state, SET function to collect the redirect signal data from the parameter and update the "allow_redirect" state, which inturns update 
         * our modals "redirect controller" through its "redirect_request" props. check the "ALERT_MODAL" component to understand more. */
        setAllow_redirect(REDIRECT_DEFAULT);
    }
    
        
        

    /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR PAYMENT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT. THIS MODAL DOES NOT REDIRECT. THE FIRST PARAMETER IS THE COURSE 
     * SUMMARY MESSAGE TO BE DISPLAYED, THE SECOND IS THE PAYMENT MODAL DISPLAY DATAS AND THE PAYMENT GATEWAY RELATED DATAS, THE THIRD PARAMETER IS THE TOGGLER WHICT TELLS OUR 
     * MODAL TO POP UP OR POP DOWN LOL. NOTE THIS FUNCTION MUST RESIDE INSIDE THIS PAGE BECAUSE OF THE STATE SET FUNCTIONS INSIDE IT WHICH BELONG TO THIS CLASS. */
    const PAYMENT_MODAL_TRIGGER = (MESSAGE, PAYMENT_AND_MODAL_DATAS, MODAL_TOGGLER) => {
        /* Here we use the "productSummary_Data" state, SET function to collect the message from the parameter and update the "productSummary_Data" state, which inturns update 
         * our modals body through its "summary" props. check the "PAY_MODAL" component to understand more. */
        setProductSummary_Data(MESSAGE);
        /* Here we use the "paystack_Modal_Data" state, SET function to collect modal and payment gateway related datas from the parameter and update the "paystack_Modal_Data" 
         * state, which inturns update our modals component through its "datas" props. check the "PAY_MODAL" component to understand more. */
        setPaystack_Modal_Data(PAYMENT_AND_MODAL_DATAS);
        /* Here we use the "show" state, SET function to collect the toggle data, which can either be "true" or "false" from the parameter and update the "show" state, which 
         * inturns update our modals components pop up controller through its "toggler" props. check the "PAY_MODAL" component to understand more. */
        setShow(MODAL_TOGGLER);
    }
    
    
    
    
    
    
    /* HERE WE CREATE A FUNCTION TO BE USED TO DECIDE THE KIND OF COURSE SELECTION DROPDOWN THAT SHOULD BE DISPLAYED DEPENDING ON THE COURSE CODE DATA COLLECTED FROM URL. IF COURSE
     * CODE DATA IS "default" THEN THIS MEANS USER DID NOT NAVIGATE INTO THIS PAGE BY CLICKING A COURSE, THEREFORE THIS FUNCTION WOULD DISPLAY THE DROPDOWN WITH ALL CORSES LOADED IN
     * IT, BUT IF THE COURSE CODE DATA IS ANY COURSE CODE IN THE ACADEMY, THIS FUNCTION DISPLAYS A DISABLED DROPEDOWN DISPLAYING THE COURSE NAME EQUIVALENT OF THE COURSE CODE DATA. */
    const COURSE_FORM = () => {
        /* convert the object data assumed to be inside our "COURSES" state, into array data.  */
        const data = OBJECT_TO_ARRAY(COURSES);
        /* in a bid to extract the course name equavalent of the selected course code, we create a variable to be used to house this course name. */
        let selected_course = "";
        /* use a for loop to extract the exact data we need from the array. */
        data.forEach(function(doc){
            selected_course = doc.label;
        });
        
        /* create our controller loop to be used to decide whick kind of dropdown should be loaded.  */
        if(courseCodeForEnrolledCourse === "default"){
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <DROPDOWN dropdown_datas={COURSES} dropdown_unchange={SELECTED_COURSES_onChange} dropdown_default_text={"Select course"} />
                        </div>
                    </div>
                </div>   
            );
        }else{
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="form-label-group">
                            <select className="custom-select" disabled>
                                <option defaultValue>{selected_course}</option>
                            </select>
                        </div>
                    </div>
                </div>   
            );
        }
    }


    
    
    

    
    
    
    
    
    
    
    
    // on-page css hacks
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/covers/cover-17.jpg)" };
    
    return (
        
                
        <div>
            
            <PAY_MODAL toggler={show} closer={closerFunction} datas={paystack_Modal_Data} summary={productSummary_Data} />
            
            <ALERT_MODAL alert_modal_toggler={showAlert} alert_modal_closer={alertCloserFunction} alert_message={alertMessage} redirect_request={allow_redirect} />

            <section>
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                        <div className="col-12 col-md-7 col-lg-5 py-8 py-md-3">
                            <h2 className="mb-0 font-weight-bold text-center"><img src="/OUTCAST/assets/img/brand.png" className="mb-6" alt="Academy Logo"></img></h2>
                            <h2 className="mb-0 font-weight-bold text-center">Sign up</h2>
                            <p className="mb-6 text-muted text-center">Join our Academy in minutes.</p>
                            <form onSubmit={onSubmit} className="mb-6" autoComplete="off">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-label-group">
                                            <input onChange={FIRST_NAME_onChange} type="text" className="form-control form-control-flush" id="First_name" placeholder="First name" />
                                            <label htmlFor="registrationFirstNameModal">First name</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-label-group">
                                            <input onChange={LAST_NAME_onChange} type="text" className="form-control form-control-flush" id="Last_name" placeholder="Last name" />
                                            <label htmlFor="registrationLastNameModal">Last name</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-label-group">
                                            <input onChange={EMAIL_onChange} type="email" className="form-control form-control-flush" id="Active_email_address" placeholder="your-active-email@address.com" />
                                            <label htmlFor="Active_email_address">myemail@address.com</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-label-group">
                                            <input onChange={PHONE_onChange} type="phone" className="form-control form-control-flush" id="Phone_number" placeholder="Phone number" />
                                            <label htmlFor="Phone_number">Phone number</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-5">
                                        <div className="form-label-group">
                                            <select onChange={SEX_onChange} id="applyRoles" className="custom-select">
                                                <option defaultValue>Gender</option>
                                                <option>Male</option>
                                                <option>Femal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <div className="form-label-group">
                                            <select onChange={AGE_RANGE_onChange} id="applyRoles" className="custom-select">
                                                <option defaultValue>Age-range</option>
                                                <option>Less than 18</option>
                                                <option>18-21</option>
                                                <option>22-25</option>
                                                <option>26-30</option>
                                                <option>31-35</option>
                                                <option>36-40</option>
                                                <option>41-45</option>
                                                <option>46-50</option>
                                                <option>51-55</option>
                                                <option>56-60</option>
                                                <option>61+</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                
                                {COURSE_FORM()}

                                <div className="row mb-5">
                                    <div className="col-12 col-md-6">
                                        <div className="form-label-group">
                                            <input onChange={PASSWORD_onChange} type="password" className="form-control form-control-flush" id="Password" placeholder="Password" />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-label-group">
                                            <input onChange={RETYPE_PASSWORD_onChange} type="password" className="form-control form-control-flush" id="Re_type_Password" placeholder="Re-type Password" />
                                            <label htmlFor="password">Re-type Password</label>
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-block btn-primary" type="submit">Sign up</button>
                            </form>
                            <p className="mb-0 font-size-sm text-muted">
                                Already have an account? <Link to={configuration.LOGIN}>Log in</Link>, or return back to <Link to={configuration.HOME_PAGE}>Homepage</Link>
                            </p>
                        </div>
                        <div className="col-lg-6 offset-lg-1 align-self-stretch d-none d-lg-block">
                            <div className="h-100 w-cover bg-cover" style={style1}></div>
                            <div className="shape shape-left shape-fluid-y svg-shim text-white">
                                <svg viewBox="0 0 100 1544" fill="none" xmlns="">
                                    <path d="M0 0H100V386L50 1158V1544H0V0Z" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
            
    );

}