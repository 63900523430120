
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS








export default function Error_404() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "404 Page not found | "+configuration.COMPANY_NAME;
    
    
    
    
    
    
    // on-page css hacks
    const style1 = { backgroundImage: "url(/OUTCAST/assets/img/covers/cover-17.jpg)" };
    
    return (
        
        <section>
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                    <div className="col-12 col-md-6 col-lg-4 py-8 py-md-11">
                        <h1 className="display-3 font-weight-bold">Oooooops!</h1>
                        <p className="mb-5 text-muted">We're sorry, but the page you were looking for doesn't exist.</p>
                        <Link className="btn btn-primary" to={configuration.HOME_PAGE}>Back to safety</Link>
                    </div>
                    <div className="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">
                        <div className="h-100 w-cover bg-cover" style={style1}></div>
                        <div className="shape shape-left shape-fluid-y svg-shim text-white">
                            <svg viewBox="0 0 100 1544" fill="none" xmlns="">
                                <path d="M0 0H100V386L50 1158V1544H0V0Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
    );

}