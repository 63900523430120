
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';







export default function LET_US_TEACH_YOUR_TEAM(){
    
    
    // on-page css hacks
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/other_images/team.jpg)" };
    
    return (

        <section className="pt-8">
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="card card-row shadow bg-cover overlay overlay-gradient-dark-right overlay-60 text-white mb-4" style={style1}>
                            <div className="row no-gutters justify-content-end">
                                <div className="col-12 col-md-6">

                                    <Link className="card-body" to="">
                                        <h2 className="font-weight-bold">
                                            Plan a course for your team
                                        </h2>
                                        <p className="mb-0 text-white-80">
                                            Sign up with us for a special session with your team of engineers, get unlimited access to our top Tech courses for your team. 
                                            Which ever mode of learning you desire, be it online or physical we shall always be ready to meet all your demands.
                                        </p>
                                    </Link>

                                    <Link className="card-meta" to="">
                                        <hr className="card-meta-divider border-white-20"></hr>
                                        <p className="h6 text-uppercase text-white-80 mb-0 ml-auto">
                                        <Link to={configuration.CONTACT} ><button type="button" className="btn btn-sm btn-primary shadow lift">Write us</button></Link>
                                        </p>
                                    </Link>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        
    );

}