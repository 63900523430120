
// REACT STANDARD LIBRARY CALLS
import React, { useState } from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
import FOOTER from './WEB_PARTS/FOOTER';


// IMPORT COMPONENTS
import ALERT_MODAL from '../Components/MODALS/ALERT_MODAL';
import PAGE_INFO from '../Components/PAGE_INFO';






export default function Contact() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "Contact us | "+configuration.COMPANY_NAME;
    
    
    
    
    
    /*     A L E R T    M O D A L    S T A T E S       */
    /* create state to be used to control medal window from parent component. */
    const [showAlert, setAlertShowStatus] = useState(false);
    /* create state to be used to set the modal window display message. */
    const [alertMessage, setAlertMessage] = useState("");
    /* create state to be used to control if the modal window should redirect after its close button is clicked or not. */
    const [allow_redirect, setAllow_redirect] = useState("false");
    /* create function to be used to collect "window close request" from the child modal component, through the "closer" props of the child modal component.  */
    const alertCloserFunction = (alert_CloseData) => {       setAlertShowStatus(alert_CloseData);         }    
    
    
    
    
    
    
    
    /*     P A G E      F O R M    S T A T E S        A N D       U N C H A N G E D       F U N C T I O N S        */
    /* state to be used to store user email data. */
    const [NAME, setNAME] = useState("");
    const [EMAIL, setEMAIL] = useState("");
    const [MESSAGE, setMESSAGE] = useState("");
    const NAME_onChange = (event) => {       setNAME(event.target.value);       };
    const EMAIL_onChange = (event) => {       setEMAIL(event.target.value);       };
    const MESSAGE_onChange = (event) => {       setMESSAGE(event.target.value);       };
    
    
    
    
    
    
    
    
    /*       P A G E       U N S U B M I T      F U N C T I O N       */
    /* function to be called when our form inputes are ready to be submitted. */
    const onSubmit = (e) => {
        /* line of code to ensure that html form default submit process is disabled, so that form submission can be handled by javascript in the REACT way.  */
        e.preventDefault();
        
        /* collect the user supplied email from the state variable, and prepare it for transport. */
        const data = { NAME: NAME, EMAIL: EMAIL, MESSAGE: MESSAGE }
        /* call the api function responsible for user password reset operation. */
        const url = configuration.CONTACT_API;
        /* Pass the form datas to that api through "FORM_POST" method, and If the JSON object returned does not yet have a stringify method, give it one. Also set the headers. */
        fetch(url, { method: 'POST', body: JSON.stringify(data), headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } })
        .then(response => response.json()).then((response) => {
            /* Here we check if password operations completed well or maybe there where errors. the outcome of this test would determine how the data returned from the server
             * would be collected. */
            if(response.status === true){
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT CAN REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE OPERATION WAS A SUCCESS.  */
                ALERT_MODAL_TRIGGER(response.message, true, configuration.HOME_PAGE);
            }else{
                /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON 
                 * CLICKING ITS CLOSE BUTTON OR NOT. HERE WE ARE DOPPING THIS FUNCTION IN A WAY SO THAT IT WILL NOT REDIRECT TO ANOTHER PAGE WHEN THE MODAL WINDOW CLOSE BUTTON 
                 * IS CLICKED. CHECK THIS FUNCTIONS DOCUMENTATIONS TO UNDERSTAND MORE. IN HERE OPERATION FAILED. */
                ALERT_MODAL_TRIGGER(response.message, true);
            }
        });
    }    
    
    
    
    
    
    
    
    
    /* MULTIPURPOSE FUNCTION DESIGNED TO BE USED TO POP UP OUR ALERT MODAL WINDOW COMPONENT, PUT MESSAGE IN IT AND DECIDE IF IT REDIRECTS TO ANOTHER PAGE UPON CLICKING ITS CLOSE
     * BUTTON OR NOT. THE FIRST PARAMETER IS THE MESSAGE TO BE DISPLAYED, THE SECOND IS THE TOGGLER WHICT TELLS OUR MODAL TO POP UP OR POP DOWN LOL, WHILE THE THIRD PARAMETER IS
     * OPTIONAL, IF ITS NOT DOPPED AND LEFT ALONE THIS MODAL WILL NOT REDIRECT WHEN ITS CLOSE BUTTON IS CLICKED BUT IF ITS DOPPED WITH A ROUTE THEN UPON CLICKING OF THIS MODALS
     * CLOSE BUTTON, IT WILL REDIECT TO THE DESIRED PAGE. NOTE THIS FUNCTION MUST RESIDE INSIDE THIS PAGE BECAUSE OF THE STATE SET FUNCTIONS INSIDE IT WHICH BELONG TO THIS CLASS. */
    const ALERT_MODAL_TRIGGER = (MESSAGE, MODAL_TOGGLER, REDIRECT_DEFAULT = "false") => {
        /* Here we use the "alertMessage" state, SET function to collect the message from the parameter and update the "alertMessage" state, which inturns update our modals body
         * through its "alert_message" props. check the "ALERT_MODAL" component to understand more. */
        setAlertMessage(MESSAGE);
        /* Here we use the "alertShowStatus" state, SET function to collect the toggle data, which can either be "true" or "false" from the parameter and update the "alertShowStatus"
         * state, which inturns update our modals components pop up controller through its "alert_modal_toggler" props. check the "ALERT_MODAL" component to understand more. */
        setAlertShowStatus(MODAL_TOGGLER);
        /* Here we use the "allow_redirect" state, SET function to collect the redirect signal data from the parameter and update the "allow_redirect" state, which inturns update 
         * our modals "redirect controller" through its "redirect_request" props. check the "ALERT_MODAL" component to understand more. */
        setAllow_redirect(REDIRECT_DEFAULT);
    }
    
        
    
    
    
    
    
    
        
    
    // how to do on script css
    const smooth_scroll_id = "#info";
    const style1 = { backgroundImage: "url("+configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/covers/cover-5.jpg)" };
    
    
    return (
        
        <div>        

            <ALERT_MODAL alert_modal_toggler={showAlert} alert_modal_closer={alertCloserFunction} alert_message={alertMessage} redirect_request={allow_redirect} />

            <NAVIGATION_BAR />
            
            
            
                <PAGE_INFO image_style={style1} body_text={"We always want to hear from you! Let us know how we can best help you and we'll do our very best."} 
                head_text={"We’re Here to Help."} />
                  
                  


                <div className="position-relative">
                  <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"/>
                    </svg>
                  </div>
                </div>
                
                
                

              
                <section className="py-7 py-md-9 border-bottom border-gray-300" id="info">
                    <div className="container">
                    
                        <div className="row">
                            <div className="col-12 text-center">
                                <Link to={smooth_scroll_id} className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13" data-toggle="smooth-scroll">
                                    <i className="fe fe-arrow-down"></i>
                                </Link>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12 col-md-4 text-center border-right border-gray-300">
                                <h6 className="text-uppercase text-gray-700 mb-1">Message us</h6>
                                <div className="mb-5 mb-md-0">
                                    <a href={configuration.WHATSAPP_LINK} target="_blank" rel="noopener noreferrer" className="h4 text-primary">Start a chat!</a>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-4 text-center border-right border-gray-300">
                                <h6 className="text-uppercase text-gray-700 mb-1">Call anytime</h6>
                                <div className="mb-5 mb-md-0">
                                    <a href={"tel:"+configuration.HOTLINE} className="h4">{configuration.HOTLINE}</a>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-4 text-center">
                                <h6 className="text-uppercase text-gray-700 mb-1">Email us</h6>
                                <a href={"mailto:"+configuration.COMPANY_EMAIL} className="h4">{configuration.COMPANY_EMAIL}</a>
                            </div>
                        </div>
                        
                    </div>
                </section>





                 
                <section className="pt-8 pt-md-11 pb-8 pb-md-14">
                    <div className="container">
                    
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8 text-center">
                                <h2 className="font-weight-bold">Let us hear from you directly!</h2>
                                <p className="font-size-lg text-muted mb-7 mb-md-9">
                                    We always want to hear from you! Let us know how we can best help you and we'll do our very best.
                                </p>
                            </div>
                        </div>
                        
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-10">

                                <form onSubmit={onSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-5">
                                                <label for="contactName">Full name</label>
                                                <input onChange={NAME_onChange} type="text" className="form-control" id="contactName" placeholder="Full name" required />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-5">
                                                <label for="contactEmail">Email</label>
                                                <input onChange={EMAIL_onChange} type="email" className="form-control" id="contactEmail" placeholder="hello@domain.com" required />
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group mb-7 mb-md-9">
                                                <label for="contactMessage">What can we help you with?</label>
                                                <textarea onChange={MESSAGE_onChange} className="form-control" id="contactMessage" rows="5"
                                                    placeholder="Tell us what we can help you with!" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <button type="submit" className="btn btn-primary lift">Send message</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                        
                    </div>
                </section>
            
            

            <FOOTER />
        
        </div>

    );

}