
// REACT STANDARD LIBRARY CALLS
import React from 'react';
//import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
//import { configuration } from '../../Config/Config';








export default function MODALS() {


    return (
            
        <div className="modal fade" id="modal-signin" tabindex="-1">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content border-0">
              <div className="cs-view show" id="modal-signin-view">
                <div className="modal-header border-0 bg-dark px-4">
                  <h4 className="modal-title text-light">Sign in</h4>
                  <button className="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body px-4">
                  <p className="font-size-ms text-muted">Sign in to your account using email and password provided during registration.</p>
                  <form className="needs-validation" novalidate>
                    <div className="input-group-overlay form-group">
                      <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="fe-mail"></i></span></div>
                      <input className="form-control prepended-form-control" type="email" placeholder="Email" required />
                    </div>
                    <div className="input-group-overlay cs-password-toggle form-group">
                      <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="fe-lock"></i></span></div>
                      <input className="form-control prepended-form-control" type="password" placeholder="Password" required />
                      <label className="cs-password-toggle-btn">
                        <input className="custom-control-input" type="checkbox" /><i className="fe-eye cs-password-toggle-indicator"></i><span className="sr-only">Show password</span>
                      </label>
                    </div>
                    <div className="d-flex justify-content-between align-items-center form-group">
                      <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="keep-signed" />
                        <label className="custom-control-label" for="keep-signed">Keep me signed in</label>
                      </div><a className="nav-link-style font-size-ms" href="password-recovery.html">Forgot password?</a>
                    </div>
                    <button className="btn btn-primary btn-block" type="submit">Sign in</button>
                    <p className="font-size-sm pt-3 mb-0">Don't have an account? <a href='l.html' className='font-weight-medium' data-view='#modal-signup-view'>Sign up</a></p>
                  </form>
                </div>
              </div>
              <div className="cs-view" id="modal-signup-view">
                <div className="modal-header border-0 bg-dark px-4">
                  <h4 className="modal-title text-light">Sign up</h4>
                  <button className="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body px-4">
                  <p className="font-size-ms text-muted">Registration takes less than a minute but gives you full control over your orders.</p>
                  <form className="needs-validation" novalidate>
                    <div className="form-group">
                      <input className="form-control" type="text" placeholder="Full name" required />
                    </div>
                    <div className="form-group">
                      <input className="form-control" type="text" placeholder="Email" required />
                    </div>
                    <div className="cs-password-toggle form-group">
                      <input className="form-control" type="password" placeholder="Password" required />
                      <label className="cs-password-toggle-btn">
                        <input className="custom-control-input" type="checkbox" /><i className="fe-eye cs-password-toggle-indicator"></i><span className="sr-only">Show password</span>
                      </label>
                    </div>
                    <div className="cs-password-toggle form-group">
                      <input className="form-control" type="password" placeholder="Confirm password" required />
                      <label className="cs-password-toggle-btn">
                        <input className="custom-control-input" type="checkbox" /><i className="fe-eye cs-password-toggle-indicator"></i><span className="sr-only">Show password</span>
                      </label>
                    </div>
                    <button className="btn btn-primary btn-block" type="submit">Sign up</button>
                    <p className="font-size-sm pt-3 mb-0">Already have an account? <a href='l.html' className='font-weight-medium' data-view='#modal-signin-view'>Sign in</a></p>
                  </form>
                </div>
              </div>
              <div className="modal-body text-center px-4 pt-2 pb-4">
                <hr />
                <p className="font-size-sm font-weight-medium text-heading pt-4">Or sign in with</p><a className="social-btn sb-facebook sb-lg mx-1 mb-2" href="l.html">
                <i className="fe-facebook"></i></a><a className="social-btn sb-twitter sb-lg mx-1 mb-2" href="l.html"><i className="fe-twitter"></i></a>
                <a className="social-btn sb-instagram sb-lg mx-1 mb-2" href="l.html"><i className="fe-instagram"></i></a>
                <a className="social-btn sb-google sb-lg mx-1 mb-2" href="l.html"><i className="fe-google"></i></a>
              </div>
            </div>
          </div>
        </div>
            
    );

}