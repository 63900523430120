
// REACT STANDARD LIBRARY CALLS
import React from 'react';
//import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../Config/Config';


// PAGES PARTS
import NAVIGATION_BAR from './WEB_PARTS/NAVIGATION_BAR';
// import CONSULTING from './WEB_PARTS/CONSULTING';     <CONSULTING />
//import INSTRUCTOR_RECRUIT_MESSAGE from './WEB_PARTS/INSTRUCTOR_RECRUIT_MESSAGE';      <INSTRUCTOR_RECRUIT_MESSAGE />
import FOOTER from './WEB_PARTS/FOOTER';








export default function About() {
    
    // lets set the page title. 
    document.getElementById("title").innerHTML = "About us | "+configuration.COMPANY_NAME;
    

    // how to do on script css
    const style1 = { marginTop: "7%" };
    
    return (
        
        <div>        

            <NAVIGATION_BAR />
            
            
                <section className="pt-5 pt-md-7" style={style1}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <h2 className="font-weight-bold"><span className="text-primary">What is our vission?</span></h2>
                                <h1 className="font-weight-bold">To Provide Advanced Education at Lower Cost.</h1>
                                <p className="font-size-lg text-muted mb-5">
                                    {configuration.COMPANY_NAME} offers advanced education without limitations. Whether you are a full-time professional or a part-time worker, 
                                    someone starting your own business or looking for a career upgrade, it’s never too late to learn. We make it easy to acquire real qualifications 
                                    which lead to real success. Our system of education makes it possible for our student to learn at their schedule as they can decide to pause or 
                                    restart lectures. We are empowering hundreds of new students each month all over the world.
                                </p>
                            </div>
                            
                            <div className="col-12 col-md-6">
                                <div className="w-md-130">
                                    <div className="device-combo device-combo-iphonex-macbook">
                                        <div className="device device-iphonex">
                                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/screenshots/mobile/dashkit-alt.jpg"} 
                                            className="device-screen" alt="..." />
                                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/devices/iphonex.svg"} className="img-fluid" alt="..." />
                                        </div>
                                        <div className="device device-macbook">
                                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/screenshots/desktop/dashkit-alt.jpg"} 
                                            className="device-screen" alt="..." />
                                            <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/assets/img/devices/macbook.svg"} className="img-fluid" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            
            <section className="pb-md-10 mb-10"></section>

            <FOOTER />
        
        </div>

    );

}



/*
 <section className="py-8 pt-md-11 pb-md-12">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-4 text-center">
            <h1 className="display-2 font-weight-bold text-primary-desat">
            <span data-toggle="countup" data-from="00" data-to="20" data-aos data-aos-id="countup:in">00.0</span>+ <br /> 
            <h2 className="text-muted mb-6 mb-md-0 font-weight-bold">courses</h2>
            </h1>
        </div>
        <div className="col-12 col-md-4 text-center">
            <h1 className="display-2 font-weight-bold text-primary-desat">
                <span data-toggle="countup" data-from="00" data-to="100" data-aos data-aos-id="countup:in">00.0</span>+ <br /> 
                <h2 className="text-muted mb-6 mb-md-0 font-weight-bold">hours of content</h2>
            </h1>
        </div>
        <div className="col-12 col-md-4 text-center">
            <h1 className="display-2 font-weight-bold text-primary-desat">
                <span data-toggle="countup" data-from="00" data-to="4" data-aos data-aos-id="countup:in">00.0</span>+ <br /> 
                <h2 className="text-muted mb-6 mb-md-0 font-weight-bold">years of teaching</h2>
            </h1>
        </div>
      </div>
    </div>
</section>
*/