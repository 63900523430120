
// REACT STANDARD LIBRARY CALLS
import React from 'react';
import { Link } from "react-router-dom";


/* WEB APP CONFIG FILE. */
import { configuration } from '../../Config/Config';







export default function OUR_PROCESS(){
        
    
    const style1 = { marginTop: "2%" };
    const style2 = { marginBottom: "0", paddingBottom: "0" };
    
    return (
        
        <div>

            <section className="pt-8 pt-md-11" style={style1}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">

                        <div className="col-12 col-md-6">
                            <h3 className="text-uppercase text-primary font-weight-bold">
                                Process
                            </h3>
                            <h2>
                                Our process from the point of finding your course to starting the lessons is fast and less stressful.
                            </h2>
                            <p className="lead text-muted mb-6 mb-md-0">
                                We keep everything as simple as possible.
                            </p>
                        </div>
                        
                        <div className="col-12 col-md-6 col-xl-5">
                            <div className="row no-gutters">
                                <div className="col-4">
                                  <div className="w-150 mt-9 p-1 bg-white shadow-lg" data-aos="fade-up" data-aos-delay="100">
                                        <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/other_images/process_1.jpg"} className="img-fluid" alt="..."></img>
                                  </div>
                                </div>
                                <div className="col-4">
                                    <div className="w-150 p-1 bg-white shadow-lg" data-aos="fade-up">
                                        <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/other_images/process_2.jpg"} className="img-fluid" alt="..."></img>
                                    </div>
                                </div>
                                <div className="col-4 position-relative">
                                    <div className="w-150 mt-11 float-right p-1 bg-white shadow-lg" data-aos="fade-up" data-aos-delay="150">
                                        <img src={configuration.RESOURCE+"OUTCAST/RESOURCE/WEBSITE_ASSETS/other_images/process_3.jpg"} className="img-fluid" alt="..."></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>











            <section className="pt-8 pb-9 pt-md-11 pb-md-13" style={style2}>
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row no-gutters align-items-center mb-md-5">
                                        <div className="col-auto">
                                          <Link to="" className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                            <span>1</span>
                                          </Link>
                                        </div>
                                        <div className="col">
                                        <hr className="d-none d-md-block w-130"></hr>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12 ml-n5 ml-md-0">
                                    <h3>
                                        Choose a course.
                                    </h3>
                                    <p className="text-muted mb-6 mb-md-0">
                                        Pick up a {configuration.COMPANY_NAME} course of your choice
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row no-gutters align-items-center mb-md-5">
                                        <div className="col-auto">
                                          <Link to="" className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                            <span>2</span>
                                          </Link>
                                        </div>
                                        <div className="col">
                                        <hr className="d-none d-md-block w-130"></hr>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12 ml-n5 ml-md-0">
                                    <h3>
                                        Register and start class.
                                    </h3>
                                    <p className="text-muted mb-6 mb-md-0">
                                        Quickly sign up and start attending online classes, you can easily pause lectures if things get overwhelming. Classes are fully interactive. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                  <div className="row no-gutters align-items-center mb-md-5">
                                    <div className="col-auto">
                                        <Link to="" className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                            <span>3</span>
                                        </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="col col-md-12 ml-n5 ml-md-0">
                                    <h3>
                                        Get your certificate.
                                    </h3>
                                    <p className="text-muted mb-0">
                                        Upon completion of the program, you get to show your growth and level up your career with a {configuration.COMPANY_NAME} certification.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            
        </div>
        
    );

}